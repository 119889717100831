<template>
  <div id="patrocinadores">
    <div class="text-center">
      <span class="text-2xl font-bold">Patrocinadores participantes</span>
    </div>
    <div class="text-center">
      <div class="grid sm:grid-cols-5 grid-cols-2 grid-flow-row-dense">
        <template v-for="patrocinador in patrocinadores">
          <div v-if="patrocinador.acomodo === 4" class="col-span-2 row-span-2 p-3 aspect-square flex justify-center items-center">
            <img class="rounded-md shadow-xl max-w-full max-h-full object-scale-down" :src="patrocinador.logo_url" alt="">
          </div>
          <div v-else-if="patrocinador.acomodo === 2" class="col-span-2 p-3 aspect-2/1 flex justify-center items-center">
            <img class="rounded-md shadow-xl max-w-full max-h-full object-scale-down" :src="patrocinador.logo_url" alt="">
          </div>
          <div v-else class="p-3 aspect-square flex justify-center items-center">
            <img class="rounded-md shadow-xl max-w-full max-h-full object-scale-down" :src="patrocinador.logo_url" alt="">
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "patrocinadores",
  props: {
    patrocinadores: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>

</style>