<template>
  <!-- Fondo y Hero Section -->
  <div class="relative bg-linear-to-r from-purple-500 via-blue-400 to-purple-500 pt-16 pb-20 overflow-hidden">
    <div class=" max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8 relative z-10">
      <h1 class="text-5xl font-extrabold text-white sm:text-6xl drop-shadow-md animate-fade-in">
        ¡Conviértete Hoy Mismo En Un Cliente con Beneficios!
      </h1>
      <p class="mt-6 mb-6 text-justify text-lg text-white max-w-3xl mx-auto animate__animated animate__fadeInTopLeft">
        Haz que tus gastos trabajen para ti: desde recargas móviles y pagos de servicios hasta compras en el
        Marketplace y en negocios afiliados.
        Tú y tus amigos pueden transformar cada gasto en una oportunidad para generar ingresos de forma fácil e
        inteligente.
        <span class=" text-purple-800 font-extrabold">¡Gana mientras consumes!</span> en Virtual Shopping Club
      </p>
      <router-link :to="registroRoute" class="mt-4 inline-block px-3 py-2 bg-white text-purple-800 font-bold rounded-lg shadow-lg
                            hover:bg-green-500 hover:text-white hover:shadow-xl transition-all duration-300">
        Regístrate gratis
      </router-link>
    </div>
  </div>

  <div class="mt-8 mb-12">
    <h2 class="text-3xl font-extrabold text-center animated-gradient">
      "Recompensas desde Pesitos hasta $500,000 en bono especial"
    </h2>
  </div>

  <!-- Video de YouTube -->
  <div class="mx-auto max-w-4xl px-6 lg:px-8">
    <div class="aspect-w-16 aspect-h-9">
      <iframe class="absolute rounded-lg  top-0 left-0 w-full h-full" src="https://www.youtube.com/embed/Jqf82di40iI"
        frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
  </div>

  <!-- Sección de Beneficios -->
  <div class="mt-10 py-16 bg-white">
    <div class="max-w-7xl mx-auto px-6 lg:px-8">
      <!-- Nuevo texto agregado -->
      <h2 class="text-3xl font-extrabold text-center text-purple-800 mb-8">
        Recibe Puntos y Tecnología por tus compras, que te da beneficios por:
      </h2>

      <section class="grid grid-cols-1 md:grid-cols-2 gap-8 items-center mb-16 mt-8">
        <!-- Lugares de produccion -->
        <div
          class="bg-purple-100 shadow-lg rounded-lg p-6 flex flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
          <img src="https://img.icons8.com/color/96/shopping-cart--v1.png" alt="Beneficio 1"
            class="w-20 h-20 rounded-full object-cover">
          <div>
            <h2 class="text-2xl font-bold text-purple-600 mb-2">Las compras de la comunidad</h2>
            <p class="text-gray-700 text-justify">
              ¡Deja que nuestros <span class="font-bold">"lugares de producción"</span> hagan el trabajo por ti! Según
              los productos que compres 🛒,
              podrás recibir beneficios de las futuras compras de la comunidad. 🤝✨
            </p>
          </div>
        </div>

        <!-- Nodos solidaridad -->
        <div
          class="bg-purple-100 shadow-lg rounded-lg p-6 flex flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
          <img src="https://img.icons8.com/color/96/coins.png" alt="Beneficio 2"
            class="w-20 h-20 rounded-full object-cover">
          <div>
            <h2 class="text-2xl font-bold text-purple-600 mb-2">Tus compras acumuladas</h2>
            <p class="text-gray-700 text-justify">
              ¡Los <span class="font-bold">"nodos solidaridad"</span> son tecnología gratuita que obtienes con tus
              compras! Te ayudan a recibir
              beneficios basados en el volumen de compras de los productos. 🚀🌟
            </p>
          </div>
        </div>
      </section>
    </div>

    <!-- Tarjeta VSC -->
    <div
      class="tarjetavsc max-w-4xl mx-auto text-center mb-14 bg-purple-300 shadow-lg rounded-lg p-6 flex flex-col sm:flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
      <!-- Imagen -->
      <img src="@/assets/img/tarjetavsc.png" alt="Tarjeta vsc"
        class="tarjetavsc-img max-h-40 object-cover mx-auto sm:mx-0">

      <!-- Contenido -->
      <div class="text-center sm:text-left">
        <h2 class="text-2xl font-extrabold text-purple-900 mb-4 sm:mb-2">Tarjeta Alianza VSC</h2>

        <p class="text-gray-700 text-justify">
          Multiplica tus ganancias y obtén 3 beneficios adicionales con las insignias:<br>
          <span class="font-bold">V:</span> Bono por la venta de la Tarjeta Alianza<br>
          <span class="font-bold">S:</span> Bono por compras en Negocios Gestionados<br>
          <span class="font-bold">C:</span> Bono por ventas de Negocios Patrocinadores
        </p>
      </div>
    </div>

    <div class="bg-linear-to-r from-purple-500 via-blue-400 to-purple-300  mb-14 pt-6 pb-6">
      <h1 class="text-white text-3xl font-serif font-extrabold text-center">
        Bono de referencias efectivas de $1.82 a más de $16,000,000
      </h1>
    </div>

    <!-- Llamada a la acción -->
    <div class="bg-purple-600 py-16 relative z-20">
      <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
        <h2 class="text-4xl font-extrabold text-white animate-bounce">
          ¡No Esperes Más para Ser Parte de Virtual Shopping Club!
        </h2>
        <img src="@/assets/img/vsc.png" alt="publicidad" class="w-40 h-30 mx-auto mb-4">
        <p class="mt-4 text-lg text-purple-100 max-w-2xl mx-auto">
          Regístrate ahora y comienza a disfrutar de todos los beneficios exclusivos que tenemos para ti.
        </p>
        <router-link :to="registroRoute" class="mt-8 inline-block px-6 py-3 bg-white text-purple-800 font-bold rounded-lg shadow-lg
                              custom-shadow hover:bg-green-500 hover:text-white hover:shadow-xl transition-all
                              duration-300">
          Regístrate Ahora
        </router-link>
      </div>
    </div>

    <!-- Virtual Shopping Club -->
    <div class="vsc bg-linear-to-r from-blue-400 via-purple-400 to-blue-400 mt-8 mb-4 pt-6 pb-6">
      <h1 class="text-white text-3xl font-serif font-extrabold text-center">
        VSC es una aplicacion que convierte los gastos comunes propios o de conocidos en formas de generar ingresos.
      </h1>
    </div>
    <div class="vscimg px-6 mb-4">
      <img src="@/assets/img/servicios-vsc.png" alt="publicidad" class="mx-auto">
    </div>

    <div class="vsc flex flex-col items-center">
      <h1 class="text-3xl font-extrabold text-center text-gray-500">
        ¡Tu momento es ahora! Únete a Virtual Shopping Club y empieza a <br> vivir la experiencia que todos están
        hablando.
      </h1>
      <router-link :to="registroRoute"
                   class="text-center mt-8 inline-block px-6 py-3 bg-white text-purple-800 font-bold rounded-lg
                          shadow-lg custom-shadow hover:bg-green-500 hover:text-white hover:shadow-xl transition-all
                          duration-300">
        Saber Más
      </router-link>
    </div>
  </div>

  <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>
</template>

<script>
import patrocinadoresComp from "@/components/campanias/patrocinadores"

export default {
  name: 'Clientes',
  components: {
    patrocinadoresComp
  },
  props: {
    patrocinadores: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    fbq('init', '1351295932570313')
    fbq('track', 'PageView')
  },
  computed: {
    registroRoute() {
      return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
        '/registro'
    }
  },
}

</script>


<!-- Estilos CSS -->
<style scoped>
/*Animacion Titulo*/
.animate-fade-in {
  animation: fade-in 1.2s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated-gradient {
  animation: pulse 2s infinite;
  font-size: 2rem;
  /* Ajusta el tamaño */
  font-weight: 800;
  /* Fuente extrabold */
  text-align: center;
  /* Centrar texto */
  background: linear-gradient(90deg, #ff7eb3, #6a5acd, #4facfe);
  /* Gradiente de 3 colores */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  /* El gradiente aparecerá en lugar del color sólido */
  animation: gradient-animation 3s infinite;
  /* Añade la animación */
}

@keyframes gradient-animation {

  0%,
  100% {
    background-position: 0% 50%;
    transform: scale(0.8);
  }

  50% {
    background-position: 100% 50%;
    transform: scale(0.9);
  }
}

/*Animacion Boton registro */
button:hover,
a:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

@media (min-width: 1024px) {
  .tarjetavsc {
    justify-content: center;
    /* Centra horizontalmente en pantallas grandes */
  }
}


/* Estilo adicional para pantallas pequeñas */
@media (max-width: 568px) {

  .tarjetavsc {
    flex-direction: column;
    /* Cambiar el diseño a columna */
  }

  .tarjetavsc-img {
    width: 10rem;
    /* Reducir tamaño de la imagen */
    height: 6rem;
    margin-bottom: 0.1rem;
    /* Añadir espacio inferior entre la imagen y el contenido */
  }

  .tarjetavsc h2 {
    margin-bottom: 0.5rem;
    /* Reducir margen inferior del título */
  }

  .vsc {
    padding: 0.5rem;
  }

  .vsc h1 {
    font-size: 1rem;
  }
}
</style>