<template>
    <div class="bg-gradient-to-r from-blue-500 to-purple-600 text-white min-h-screen">
        <!-- Hero Section -->
        <header class="text-center py-20">
            <h1 class="text-5xl font-bold font-serif animate__animated animate__fadeInTopLeft">Publicista o Gestor Desarrollador de Red</h1>
            <p class="mt-4 text-lg  px-6 text-justify animate__animated animate__fadeInBottomRight">
                🌟 ¿Te apasionan las ventas y el desarrollo de redes? ¡Únete a nuestro equipo de Miembros Expansión! 💼✨
                Accede a 6 formas de generar ingresos, desde pequeñas ganancias hasta ingresos ilimitados. 🚀 Construye
                tu red, amplía tus habilidades y alcanza tus metas financieras. ¡El límite lo pones tú!
            </p>
            <router-link :to="registroRoute"
                class="mt-12 font-serif  inline-block px-9 py-3 text-gray-800 font-bold rounded-lg transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.8)]">
                ¡Postúlate ahora!
            </router-link>
        </header>

        <div class="bg-gradient-to-r from-blue-700 via-purple-400 to-purple-700 pt-1 pb-1">
        </div>


        <!-- Sección de roles -->
        <h1 class="text-3xl text-center font-bold font-serif mt-16"> ¿Que Prefires Ser?</h1>
        <section class="px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center mt-4 mb-16">
            <!-- Publicista -->
            <div
                class="bg-white shadow-lg rounded-lg p-6 flex flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
                <img src="@/assets/img/members/publicista.png" alt="Publicista"
                    class="w-20 h-20 rounded-full object-cover">
                <div>
                    <h2 class="text-2xl font-bold font-serif text-purple-800 mb-2">Publicista</h2>
                    <p class="text-purple-700 text-justify">
                        Los publicistas son el motor detrás de la promoción de nuestras marcas y servicios. Conéctate
                        con un
                        público más amplio y gana recompensas exclusivas.
                    </p>
                </div>
            </div>

            <!-- Desarrollador de Red -->
            <div
                class="bg-white shadow-lg rounded-lg p-6 flex flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
                <img src="@/assets/img/members/desarrolladorRed.png" alt="Desarrollador de Red"
                    class="w-20 h-20 rounded-full object-cover">
                <div>
                    <h2 class="text-2xl font-bold font-serif text-purple-800 mb-2">Desarrollador de Red</h2>
                    <p class="text-purple-700 text-justify">
                        Construye y lidera tu propio equipo de trabajo. Como desarrollador de red, llevarás el control
                        de tu
                        gestoría y alcanzarás metas más rápido.
                    </p>
                </div>
            </div>
        </section>

        <!-- Miembro Expansion -->
        <section class="py-16 bg-white px-8">
            <div class="mt-8 mb-14">
                <h2 class="text-3xl font-serif font-extrabold text-center animated-gradient">
                    "Recompensas desde pesos hasta $1,000,000 en bono especial"
                </h2>
            </div>

            <div
                class="insignia-gestor bg-white shadow-2xl rounded-lg p-6 flex flex-col md:flex-row items-center gap-6">

                <!-- Imagen -->
                <img src="@/assets/img/members/InsigniaGestor.png" alt="Insignia Gestor"
                    class="insignia-img w-40 h-40 rounded-full object-cover">

                <!-- Contenedor del texto -->
                <div class="text-center md:text-left">
                    <h2 class="text-2xl font-bold font-serif text-purple-800 mb-2">Miembro Expansión</h2>
                    <p class="text-purple-700 text-justify">
                        ¡Sé el líder de tu propia red de emprendedores! Conviértete en el gerente de tu célula de
                        marketing y activa tu insignia de Gestor. Genera ingresos increíbles
                        con la venta de paquetes como la <span class="font-bold">Tarjeta Alianza VSC</span>, el <span
                            class="font-bold">Paquete Expansión</span> y la <span class="font-bold">Membresía de
                            Negocios</span>.
                        ¿Lo mejor? Puedes ganar desde pequeñas recompensas hasta miles de pesos al expandir tu red.
                        <span class="font-bold">¡Conecta, lidera y multiplica tus ingresos hoy mismo!</span>
                    </p>
                </div>
            </div>
        </section>

        <!-- Comparación de Planes -->
        <section class="py-16 px-8">
            <!-- Beneficios del Programa Especial R -->

            <h2 class="text-3xl font-bold font-serif text-white mb-8 text-center">Beneficios del Programa Especial R
            </h2>
            <h2 class="text-2xl font-bold font-serif text-white mb-8 text-center">Obtén bonos del fondo de las
                utilidades de
                VSC, de un 10%-30%</h2>

            <div class="testimonials">
                <div class="carousel">
                    <div class="slide bg-white rounded-lg p-6 text-center shadow-md">
                        <img src="@/assets/img/members/r-1.png" alt="Beneficio R1" class="w-16 h-16 mx-auto mb-4">
                        <p class="text-purple-700 text-justify">Aplica $1,000 o más en compras de recargas y marketplace
                            al mes para recibir tu bono en esta categorias.</p>
                    </div>

                    <div class="slide bg-white rounded-lg p-8 text-center shadow-md">
                        <img src="@/assets/img/members/r-2.png" alt="Beneficio R2" class="w-16 h-16 mx-auto mb-4">
                        <p class="text-purple-700 text-justify"> Aplica $1,000 o más en compras en establecimientos o
                            4 pagos de servicios digitales al mes y obtén tu bono enestas categorías.</p>
                    </div>
                    <div class="slide bg-white rounded-lg p-6 text-center shadow-md">
                        <img src="@/assets/img/members/r-3.png" alt="Beneficio R3" class="w-16 h-16 mx-auto mb-4">
                        <p class="text-purple-700 text-justify">Para recibir tu bono invita a tus amigos o conocidosa
                            que
                            multipliquen sus ganancias con uno de nuestros
                            paquetes o herrameintas..</p>
                    </div>
                </div>
            </div>
        </section>



        <section class="py-16 bg-white text-gray-900 px-8">
            <div
                class="flex flex-col md:flex-row gap-6 justify-center items-stretch mt-8 mb-8 px-4 md:px-0 max-w-4xl mx-auto">
                <!-- Card 1 -->
                <div class="bg-white text-white p-6 md:w-1/2 h-full flex flex-col justify-between">
                    <h2 class="text-green-500 text-2xl font-bold mb-4 text-center md:text-left">
                        Quien puede ser desarrollador de red:
                    </h2>
                    <ul class="space-y-2 flex-1 text-gray-800">
                        <li class="flex items-center"><span class="text-green-500 text-xl">✅</span> <span
                                class="ml-2">Aquellos que quieren desarrollar red.</span></li>
                        <li class="flex items-center"><span class="text-green-500 text-xl">✅</span> <span
                                class="ml-2">Interesados en mejorar su economía.</span></li>
                        <li class="flex items-center"><span class="text-green-500 text-xl">✅</span> <span
                                class="ml-2">Aquellos que quieren ganar dinero.</span></li>
                        <li class="flex items-center"><span class="text-green-500 text-xl">✅</span> <span
                                class="ml-2">Los que saben que no hay dinero fácil.</span></li>
                    </ul>
                </div>

                <!-- Card 2 -->
                <div class="bg-white text-white p-6  md:w-1/2 h-full flex flex-col justify-between">
                    <h2 class="text-red-500 text-2xl font-bold mb-4 text-center md:text-left">
                        Quien no puede ser desarrollador de red:
                    </h2>
                    <ul class="space-y-2 flex-1 text-gray-800">
                        <li class="flex items-center"><span class="text-red-500 text-xl">❌</span> <span class="ml-2">
                                Los que no les gusta desarrollar red.</span></li>
                        <li class="flex items-center"><span class="text-red-500 text-xl">❌</span> <span class="ml-2">
                                Aquellos creyentes en dinero fácil.</span></li>
                        <li class="flex items-center"><span class="text-red-500 text-xl">❌</span> <span class="ml-2">
                                Los que quieren poco esfuerzo.</span></li>
                        <li class="flex items-center"><span class="text-red-500 text-xl">❌</span> <span class="ml-2">
                                Los que no tiene compromiso.</span></li>
                    </ul>
                </div>
            </div>
        </section>


        <section class="py-16 px-8 text-center text-gray-900">
            
          <h2 class="text-3xl font-bold text-white mb-4 animate-bounce pt-10">¡Forma Parte de Nuestra Comunidad
              de Miembros Expansión Hoy!</h2>
          <img src="@/assets/img/vsc.png" alt="publicidad" class="w-40 h-30 mx-auto mb-4">
          <p class="text-white mb-6">Registrate y lleva tus habilidades de liderazgo al siguiente nivel.</p>
          <router-link :to="registroRoute"
                       class="mt-12 font-serif inline-block px-9 py-3 text-gray-800 font-bold rounded-lg transition-all
                                duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.8)]">
            ¡Saber Más!
          </router-link>
        </section>

      <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>
    </div>
</template>

<script>
import patrocinadoresComp from "@/components/campanias/patrocinadores"

export default {
  name: 'miembroExpansion3',
  components: {
    patrocinadoresComp
  },
  props: {
    patrocinadores: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    fbq('init', '1351295932570313')
    fbq('track', 'PageView')
  },
  computed: {
    registroRoute() {
      return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
          '/registro'
    }
  }
}
</script>

<style scoped>
@keyframes gradientChange {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.gradient-bg {
    background: linear-gradient(45deg, #ac30d5, #d9d9d9, #ac30d5, #ffffff);
    background-size: 400% 400%;
    animation: gradientChange 4s ease infinite;
}

.carousel {
    display: flex;
    overflow: auto;
    gap: 10px;
    padding: 10px;
}

.slide {
    min-width: 300px;
    background: #ffffff;
    padding: 20px;
    border-radius: 5px;
}

.animated-gradient {
    animation: pulse 2s infinite;
    font-size: 2rem;
    /* Ajusta el tamaño */
    font-weight: 800;
    /* Fuente extrabold */
    text-align: center;
    /* Centrar texto */
    background: linear-gradient(90deg, #ca1cff, #580971, #ca1cff);
    /* Gradiente de 3 colores */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    /* El gradiente aparecerá en lugar del color sólido */
    animation: gradient-animation 3s infinite;
    /* Añade la animación */
}

@keyframes gradient-animation {

    0%,
    100% {
        background-position: 0% 50%;
        transform: scale(0.8);
    }

    50% {
        background-position: 100% 50%;
        transform: scale(0.9);
    }
}
</style>