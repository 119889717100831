<template>
    <div class="bg-black min-h-screen text-white">
        <!-- Encabezado -->
        <div class="relative py-16 px-6 text-center    bg-cover bg-left md:bg-center pt-32 pb-32"
            :style="{ backgroundImage: `url(${require('@/assets/img/members/bannerPatrocinador.png')})` }">
            <div class="relative z-10">
                <h1
                    class="text-5xl text-green-500 font-extrabold neon-text animate__animated animate__zoomInDown animate__slow">
                    PATROCINADORES DE TECNOLOGIA
                </h1>
                <p class="text-3xl mt-4 font-bold animate__animated animate__zoomInLeft">
                    Virtual Shopping Club </p>

                <router-link :to="registroRoute"
                    class="mt-12 inline-block px-9 py-3 text-white font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(0,255,0,0.7)]">
                    ¡Únete Ahora!
                </router-link>
            </div>
        </div>

        <div class="bg-gradient-to-r from-blue-800 via-blue-400 to-green-500 pt-2 pb-2">
        </div>

        <!-- Por que ser patrocinador -->
        <section class="max-w-7xl mx-auto px-4 py-10">
            <h2 class="text-4xl font-bold text-teal-300 text-center">¿Por qué ser patrocinador?</h2>
            <p class="mt-4 text-center text-justify text-lg text-teal-200">
                Ser patrocinador de nuestra tecnología significa formar parte del crecimiento del Virtual Shopping Club
                y acceder a herramientas avanzadas que te permitirán generar ingresos mientras maximizas tu impacto en
                la
                economía digital. Además, contribuirás a revolucionar la forma en que las personas compran y se conectan
                en el mundo virtual.
            </p>
        </section>

        <!-- Video de YouTube -->
        <div class="mx-auto max-w-4xl px-6 lg:px-8">
            <div class="aspect-w-16 aspect-h-9">
                <iframe class="absolute rounded-lg  top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/f1SxM7npTNY" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>

        <!-- Beneficios para patrocinadores -->
        <section class="bg-gradient-to-r from-teal-600 via-green-500 to-blue-700 mt-12 py-16">
            <div class="max-w-6xl mx-auto text-center px-4">
                <h2 class="text-4xl font-extrabold text-white mb-6">Lo que recibirás como Patrocinador</h2>
                <p class="text-lg text-teal-100 mb-10">Al convertirte en patrocinador, recibes tecnología que te estará
                    generando beneficios que puedes utilizar para:</p>
                <ul class="grid grid-cols-2 md:grid-cols-2 gap-8">
                    <li
                        class="bg-teal-700 p-6 rounded-2xl  hover:bg-teal-500 shadow-lg hover:scale-105 transform transition-all duration-300 ease-in-out">
                        <div class="flex items-center justify-start space-x-4">
                            <span class="text-lg text-white">📲 Recargas moviles</span>
                        </div>
                    </li>
                    <li
                        class="bg-teal-700 p-6 rounded-2xl shadow-lg  hover:bg-teal-500 hover:scale-105 transform transition-all duration-300 ease-in-out">
                        <div class="flex items-center justify-start space-x-4">
                            <span class="text-lg text-white">📈 Marketing</span>
                        </div>
                    </li>
                    <li
                        class="bg-teal-700 p-6 rounded-2xl shadow-lg  hover:bg-teal-500 hover:scale-105 transform transition-all duration-300 ease-in-out">
                        <div class="flex items-center justify-start space-x-4">
                            <span class="text-lg text-white">🛍️ Pagos de servicios</span>
                        </div>
                    </li>

                    <li
                        class="bg-teal-700 p-6 rounded-2xl shadow-lg  hover:bg-teal-500 hover:scale-105 transform transition-all duration-300 ease-in-out">
                        <div class="flex items-center justify-start space-x-4">
                            <span class="text-lg text-white">📢 Publicidad</span>
                        </div>
                    </li>
                    <li
                        class="bg-teal-700 p-6 rounded-2xl shadow-lg  hover:bg-teal-500 hover:scale-105 transform transition-all duration-300 ease-in-out">
                        <div class="flex items-center justify-start space-x-4">
                            <span class="text-lg text-white">🛒 Compras en el marketplace</span>
                        </div>
                    </li>
                    <li
                        class="bg-teal-700 p-6 rounded-2xl shadow-lg  hover:bg-teal-500 hover:scale-105 transform transition-all duration-300 ease-in-out">
                        <div class="flex items-center justify-start space-x-4">
                            <span class="text-lg text-white">🤝 Patrocinios</span>
                        </div>
                    </li>
                    <li
                        class="bg-teal-700 p-6 rounded-2xl shadow-lg  hover:bg-teal-500 hover:scale-105 transform transition-all duration-300 ease-in-out">
                        <div class="flex items-center justify-start space-x-4">
                            <span class="text-lg text-white">💰 Generación de ingresos recurrentes</span>
                        </div>
                    </li>
                    <li
                        class="bg-teal-700 p-6 rounded-2xl shadow-lg  hover:bg-teal-500 hover:scale-105 transform transition-all duration-300 ease-in-out">
                        <div class="flex items-center justify-start space-x-4">
                            <span class="text-lg text-white">💳 Pagos de membresias</span>
                        </div>
                    </li>


                </ul>
            </div>
        </section>

        <div class="text-center">
            <h2 class="text-2xl sm:text-3xl md:text-4xl font-bold text-white mt-10 sm:mt-14">
                Recompensas desde Pesos a Bonos Ilimitados
            </h2>

            <router-link :to="registroRoute"
                class="mt-6 sm:mt-8 mb-6 sm:mb-10 inline-block px-12 sm:px-16 md:px-20 py-2 sm:py-3 text-sm sm:text-base md:text-lg text-white font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(0,255,0,0.7)]">
                QUIERO SER UN PATROCINADOR
            </router-link>


        </div>

        <section class="mt-16">
            <h2 class="text-4xl font-bold text-green-500 text-center">
                Formas de Ser Patrocinador
            </h2>
        </section>

        <!-- Tres formas de patrocinio -->
        <section class="max-w-7xl mx-auto px-4 py-10 grid grid-cols-1 md:grid-cols-3 gap-8">

            <!-- Gemas Publicitarias -->
            <div
                class="bg-gradient-to-r from-green-500 via-teal-400 to-mint-500 rounded-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
                <div class="p-6">
                    <h3 class="text-center text-2xl font-bold text-white">Gemas Publicitarias</h3>
                    <img src="@/assets/img/members/Insignia_Gestor1.png" alt="publicidad"
                        class="w-24 h-24 mx-auto mb-4">
                    <p class="text-white text-justify">
                        Con tu gema publicitaria, al asignarla a una de las 100 categorías de tu elección, ganarás
                        automáticamente
                        un porcentaje de las ventas generadas por todos los productos de esa categoría. Puedes elegir
                        entre categorías
                        de alta demanda como tecnología, moda, deportes, hogar y más.
                    </p>
                </div>
            </div>

            <!-- Paquete Vitalicio -->
            <div
                class="bg-gradient-to-r from-green-500 via-teal-400 to-mint-500 rounded-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
                <div class="p-6">
                    <h3 class="text-center text-2xl font-bold text-white">Paquete Vitalicio</h3>
                    <img src="@/assets/img/members/Insignia_Gestor.png" alt="publicidad" class="w-20 h-20 mx-auto mb-4">
                    <p class="mt-4 text-white text-justify">
                        Convertirse en un Miembro Vitalicio te da acceso a una amplia gama de beneficios diseñados para
                        maximizar
                        tus ganancias y tu impacto en nuestra comunidad tecnológica. Al ser parte de este selecto grupo,
                        disfrutarás
                        de: 1 Gema Génesis: Participa en el bono anual de miembros Génesis, que otorga el 5% de las
                        utilidades de la
                        empresa entre sus portadores. 1 Gema de Proyecto: Gana desde $500,000 al año con las ventas de
                        una de las 100
                        categorías disponibles y disfruta de ganancias ilimitadas.
                    </p>
                </div>
            </div>

            <!-- Programa Especial F3 -->
            <div
                class="bg-gradient-to-r from-green-500 via-teal-400 to-mint-500 rounded-lg shadow-xl hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
                <div class="p-6">
                    <h3 class="text-center text-2xl font-bold text-white">Programa Especial F3</h3>
                    <img src="@/assets/img/F3.png" alt="publicidad" class="w-16 h-16 mx-auto mt-4 mb-4">
                    <p class="mt-4 text-white text-justify">
                        Con el paquete de Patrocinio F3, te conviertes en patrocinador de tecnología y obtienes 2
                        participaciones
                        permanentes. Estas te permiten recibir un bono mensual basado en el número de transacciones de
                        ventas en
                        establecimientos por zona. ¡Apoya nuestra aplicación y disfruta de beneficios cada mes sin
                        complicaciones!
                    </p>
                </div>
            </div>

        </section>


        <!-- Card 1 -->
        <div class="flex flex-col md:flex-row justify-center items-center mb-8 mx-auto">
            <div class="bg-black text-white p-6 rounded-lg shadow-lg md:w-1/2">
                <h2 class="text-teal-300 text-2xl font-bold mb-4 text-center">
                    ¿Esto es para mi?
                </h2>
                <p class="mb-4 text-center text-justify text-lg text-teal-300">
                    Si te identificas con alguno de los siguientes puntos, ¡felicidades! Encontraste
                    <span class="font-bold">la oportunidad de oro que buscabas</span>
                </p>
                <ul class="space-y-2">

                    <li class="flex items-center text-teal-200"><span class="text-xl">✅</span> <span class="ml-2">Te
                            interesa innovar y aprovechar la tecnología para generar ingresos.</span></li>
                    <li class="flex items-center text-teal-200"><span class="text-xl">✅</span> <span class="ml-2">Te
                            apasiona el crecimiento personal y el desarrollo de negocios.</span></li>
                    <li class="flex items-center text-teal-200"><span class="text-xl">✅</span> <span
                            class="ml-2">Quieres generar ingresos adicionales sin descuidar tu actividad
                            principal.</span></li>
                    <li class="flex items-center text-teal-200"><span class="text-xl">✅</span> <span class="ml-2">Buscas
                            más visibilidad y clientes sin gastar en publicidad tradicional.</span>
                    </li>
                    <li class="flex items-center text-teal-200"><span class="text-xl">✅</span> <span class="ml-2">Buscas
                            una comunidad que impulse tu negocio y te ayude a crecer.</span></li>
                </ul>
            </div>
        </div>

        <div class="bg-gradient-to-r from-blue-800 via-blue-400 to-green-500 pt-2 pb-2">
        </div>

        <!-- Llamada a la acción -->
        <section class="bg-gray-900 text-white py-16">
            <div class="max-w-7xl mx-auto text-center">
                <h2 class="text-5xl font-bold">¡Conviértete en Patrocinador de Tecnología Hoy!</h2>
                <p class="mt-4 text-lg text-teal-200">No solo apoyarás la innovación tecnológica, sino que también
                    obtendrás beneficios exclusivos que te ayudarán a destacar en el mercado.</p>

                <router-link :to="registroRoute"
                    class="mt-8 inline-block px-6 py-3 bg-white text-teal-800 font-bold text-lg rounded-lg hover:bg-teal-400 hover:text-white  transition duration-300">
                    Comienza Ahora
                </router-link>
            </div>
        </section>

        <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>

    </div>
</template>


<script>

import patrocinadoresComp from "@/components/campanias/patrocinadores";

export default {
    name: 'patrocinadorTecnologia3',
    components: {
        patrocinadoresComp
    },
    props: {
        patrocinadores: {
            type: Array,
            default: () => []
        }
    },
    mounted() {
      fbq('init', '1351295932570313')
      fbq('track', 'PageView')
    },
    computed: {
        registroRoute() {
            return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
                '/registro'
        }
    }
}

</script>




<style scoped>
.neon-text {
    text-shadow: 0 0 5px #f7f9f9, 0 0 10px #f7f9f9, 0 0 20px #f7f9f9;
}

.hover\:bg-teal-500:hover {
    background-color: #38b2ac;
}

.transition {
    transition: all 0.3s ease;
}

.text-green-500 {
    color: #00ed67;
    /* Azul oscuro para texto destacado */
}

/* Estilo adicional para pantallas pequeñas */
@media (max-width: 568px) {

    .banner h1 {
        font-size: 2rem;
    }
}

@keyframes gradientChange {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.gradient-bg {
    background: linear-gradient(45deg, #16f734, #086ce7, #10ef4b, #2575fc);
    background-size: 400% 400%;
    animation: gradientChange 4s ease infinite;
}
</style>