<template>
  <template v-if="$route.name === 'home'">
    <Disclosure as="nav" class="fixed top-0 z-50 w-full bg-white shadow-md" v-slot="{ open }">
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-auto items-center justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton
            class="relative inline-flex items-center justify-center rounded-md p-2 text-black-300 hover:bg-gray-500
            hover:text-white focus:outline-hidden focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="absolute -inset-0.5" />
            <span class="sr-only"></span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex shrink-0 items-center">
            <img class="max-h-24 w-auto" src="./assets/img/vsc.png" alt="Virtual Shopping Club" />
          </div>
          <div class="hidden sm:ml-6 sm:block sm:items-center sm:flex">
            <!-- Contenedor principal oculto en pantallas pequeñas y mostrado con margen izquierdo en pantallas medianas y grandes -->
            <div class="flex space-x-4">
              <!-- Contenedor flex para los enlaces con espacio entre ellos -->
              <a v-for="item in navigation" :key="item.name" :href="item.href"
                class="text-black-300 hover:bg-gray-500 hover:text-white rounded-md px-3 py-2 text-sm font-medium">
                {{ item.name }}
              </a>
            </div>
          </div>
        </div>

          <div class="hidden md:flex">
            <router-link :to="{ name: 'registro', params: { noPatrocinador: store.noPatrocinador } }"
                         class="flex items-center justify-center h-full rounded-md bg-green-500 px-3 py-1.5 text-sm font-semibold
            leading-6 text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2
            focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Suscríbete
            </router-link>
          </div>

          <!-- Icono de registro en pantallas pequeñas -->
          <div class="md:hidden">
            <router-link :to="{ name: 'registro', params: { noPatrocinador: store.noPatrocinador } }"
                         class="flex items-center justify-center h-full rounded-md bg-green-500 px-3 py-1.5 text-sm font-semibold
            leading-6 text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2
            focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6">
                </path>
              </svg>
            </router-link>
          </div>
        <!-- Enlace de registro -->

        <!-- Enlace de prueba de IA -->
<!--        <div class="hidden md:flex mx-2">
          <router-link :to="{ name: 'IA' }"
            class="flex items-center justify-center h-full rounded-md bg-orange-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-xs hover:bg-orange-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
            Prueba nuestra IA
          </router-link>
        </div>-->

        <!-- Icono de prueba de IA en pantallas pequeñas -->
<!--        <div class="md:hidden mx-2">
          <router-link :to="{ name: 'IA' }"
            class="flex items-center justify-center h-full rounded-md bg-orange-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-xs hover:bg-orange-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
            IA
          </router-link>
        </div>-->
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pb-3 pt-2">
        <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href"
          :class="['text-black-300 hover:bg-gray-500 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium']"
          :aria-current="item.current ? 'page' : undefined">{{ item.name }}</DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
  </template>
  <div>
    <router-view />
  </div>
  <footer class="flex min-h-full flex-1 flex-col justify-center bg-black">
    <div class="sm:mx-auto sm:w-full text-center ">
      <p class="my-10  text-gray-100 leading-10">Copyright © 2025 - Virtual Shopping Club</p>
    </div>
  </footer>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { RouterLink } from 'vue-router';
import { useVirtualShoppingClubStore } from '@/store/index'

const store = useVirtualShoppingClubStore()
const navigation = [
  { name: 'Tu futuro', href: '#TuFuturo' },
  { name: 'VSC', href: '#VSC' },
  { name: 'Tipos de miembros', href: '#Miembros' },
  { name: 'Paquetes', href: '#Paquetes' },
  { name: 'Programas nuevos', href: '#LoNuevo' }
]
</script>

<style scoped>
/* Estilos adicionales según sea necesario */
</style>
