<template>
  <div class="bg-pink-100 min-h-screen pt-8 pb-12 px-6">
    <!-- Título principal -->
    <header class="bg-pink-200 text-center rounded-lg ">
      <div class="pt-16 pb-16 mx-auto px-4">
        <!-- Contenedor para el título y el párrafo -->
        <div class="text-center ">
          <h1 class="text-4xl pb-16 font-serif md:text-left font-extrabold animate__animated animate__zoomInDown">
            Conviértete en un <span class="text-5xl text-orange-900">¡Miembro Expansión!</span>
          </h1>
          <p
            class="text-lg text-justify text-pink-700 leading-relaxed animate__animated animate__zoomInDown animate__slow">
            ¿Te apasionan las ventas y el desarrollo de redes? 🌟 ¡Esta es tu oportunidad! Únete a nuestro exclusivo
            equipo de
            <span class="font-bold">Miembros Expansión,</span> donde tendrás acceso a <span class="font-bold">7 formas
              diferentes de generar ingresos.</span>
            Desde pequeñas ganancias que suman, hasta ingresos ilimitados que pueden transformar tu vida. 💼✨Forma parte
            de una comunidad que te impulsa al éxito,
            mientras construyes tu red, amplías tus habilidades y alcanzas tus metas financieras. 🚀 ¡El límite lo pones
            tú! ¿Estás listo para dar el siguiente paso?
          </p>
          <router-link :to="registroRoute"
                       class="mt-6 inline-block px-4 py-3 bg-pink-400 text-pink-900 font-bold rounded-lg
                                hover:bg-pink-600 hover:text-white transition-all duration-300 animate__animated
                                animate__zoomInDown animate__slower">
            ¡Regístrate Gratis!
          </router-link>
        </div>
      </div>
    </header>

    <!-- Sección de roles -->
    <section class="grid grid-cols-1 md:grid-cols-2 gap-8 items-center mb-16 mt-32">
      <!-- Publicista -->
      <div
        class="bg-white shadow-lg rounded-lg p-6 flex flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
        <img src="@/assets/img/members/publicista.png" alt="Publicista" class="w-20 h-20 rounded-full object-cover">
        <div>
          <h2 class="text-2xl font-bold text-pink-800 mb-2">Publicista</h2>
          <p class="text-pink-700 text-justify">
            Los publicistas son el motor detrás de la promoción de nuestras marcas y servicios. Conéctate con un
            público más amplio y gana recompensas exclusivas.
          </p>
        </div>
      </div>

      <!-- Desarrollador de Red -->
      <div
        class="bg-white shadow-lg rounded-lg p-6 flex flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
        <img src="@/assets/img/members/desarrolladorRed.png" alt="Desarrollador de Red"
          class="w-20 h-20 rounded-full object-cover">
        <div>
          <h2 class="text-2xl font-bold text-pink-800 mb-2">Desarrollador de Red</h2>
          <p class="text-pink-700 text-justify">
            Construye y lidera tu propio equipo de trabajo. Como desarrollador de red, llevarás el control de tu
            gestoría y alcanzarás metas más rápido.
          </p>
        </div>
      </div>
    </section>

    <!-- Insignia Gestor -->
    <div
      class="insignia-gestor bg-pink-200 shadow-lg rounded-lg p-6 flex flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
      <img src="@/assets/img/members/InsigniaGestor.png" alt="Insignia Gestor"
        class="insignia-img w-40 h-40 rounded-full object-cover">
      <div>
        <h2 class="text-2xl font-bold text-pink-800 mb-2">Insignia Gestor</h2>
        <p class="text-pink-700 text-justify">
          ¡Sé el líder de tu propia red de emprendedores! Conviértete en el gerente de tu célula de marketing y activa
          tu insignia de Gestor. Genera ingresos increíbles
          con la venta de paquetes como la <span class="font-bold">Tarjeta Alianza VSC</span>, el <span
            class="font-bold">Paquete Expansión</span> y la <span class="font-bold">Membresía de Negocios</span>.
          ¿Lo mejor? Puedes ganar desde pequeñas recompensas hasta miles de pesos al expandir tu red. <span
            class="font-bold">¡Conecta, lidera y multiplica tus ingresos hoy mismo!</span>
        </p>
      </div>
    </div>

    <!-- Beneficios del Programa Especial R -->
    <section class="bg-pink-200 rounded-lg p-8 shadow-lg mt-14 mb-16">
      <h2 class="text-3xl font-bold text-pink-800 mb-8 text-center">Beneficios del Programa Especial R</h2>
      <h2 class="text-2xl font-bold text-pink-700 mb-8 text-center">Obtén bonos del fondo de las utilidades de VSC, de
        un 10%-30%</h2>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
        <!-- R1 -->
        <div
          class="bg-white rounded-lg p-6 text-center shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300">
          <img src="@/assets/img/members/r-1.png" alt="Beneficio R1" class="w-16 h-16 mx-auto mb-4">
          <p class="text-pink-700 text-justify">Aplica $1,000 o más en compras de recargas y marketplace
            al mes para recibir tu bono en esta categorias.</p>
        </div>
        <!-- R2 -->
        <div
          class="bg-white rounded-lg p-6 text-center shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300">
          <img src="@/assets/img/members/r-2.png" alt="Beneficio R2" class="w-16 h-16 mx-auto mb-4">
          <p class="text-pink-700 text-justify"> Aplica $1,000 o más en compras en establecimientos o
            4 pagos de servicios digitales al mes y obtén tu bono enestas categorías.</p>
        </div>
        <!-- R3 -->
        <div
          class="bg-white rounded-lg p-6 text-center shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300">
          <img src="@/assets/img/members/r-3.png" alt="Beneficio R3" class="w-16 h-16 mx-auto mb-4">
          <p class="text-pink-700 text-justify">Para recibir tu bono invita a tus amigos o conocidosa que multipliquen
            sus ganancias con uno de nuestros
            paquetes o herrameintas.</p>
        </div>
      </div>
    </section>

    <!-- Llamado a la acción -->
    <section class="text-center">
      <h2 class="text-3xl font-bold text-pink-800 mb-4 animate-bounce pt-10">¡Forma Parte de Nuestra Comunidad de
        Miembros Expansión Hoy!</h2>
      <img src="@/assets/img/vsc.png" alt="publicidad" class="w-40 h-30 mx-auto mb-4">
      <p class="text-pink-700 mb-6">Registrate y lleva tus habilidades de liderazgo al siguiente nivel.</p>
      <router-link :to="registroRoute"
                   class="mt-6 inline-block px-6 py-3 bg-pink-600 text-white font-bold rounded-full hover:bg-pink-700
                            transition-all duration-300">
        ¡Empieza hoy mismo!
      </router-link>
    </section>

    <!-- Virtual Shopping Club -->
    <div class="vsc rounded-lg bg-linear-to-r from-blue-400 via-purple-400 to-pink-300 mt-12 mb-4 pt-6 pb-6">
      <h1 class="text-gray-800 text-3xl font-extrabold text-center">
        VSC es una aplicacion que convierte los gastos comunes propios o de conocidos en formas de generar
        ingresos.
      </h1>
    </div>
    <div class="px-6 mb-4">
      <img src="@/assets/img/servicios-vsc.png" alt="publicidad" class="mx-auto">
    </div>

    <div class="vsc flex flex-col items-center mb-4">
      <h1 class="text-3xl font-extrabold text-center  text-gray-500">
        ¡No esperes más! Hoy puedes dar el primer click hacia un futuro lleno de posibilidades.
      </h1>
      <router-link :to="registroRoute"
                   class="mt-6 inline-block px-6 py-3 bg-pink-600 text-white font-bold rounded-lg hover:bg-pink-700
                            transition-all duration-300">
        ¡Regístrate Ahora!
      </router-link>
    </div>

    <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>
  </div>
</template>

<script>
import patrocinadoresComp from "@/components/campanias/patrocinadores"

export default {
  name: 'miembroExpansion',
  components: {
    patrocinadoresComp
  },
  props: {
    patrocinadores: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    fbq('init', '1351295932570313')
    fbq('track', 'PageView')
  },
  computed: {
    registroRoute() {
      return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
          '/registro'
    }
  }
}
</script>

<style scoped>
/* Animación de pulso para el h3 */
.animated-pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
    color: #9d174d;
    /* Color base */
  }

  50% {
    transform: scale(1.1);
    color: #be185d;
    /* Color más brillante */
  }
}

/*Animacion Boton registro */
button:hover,
a:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

/* Estilo base */
.insignia-gestor {
  display: flex;
  flex-direction: row;
  /* Imagen y texto en fila */
  align-items: center;
  gap: 1.5rem;
}

.insignia-img {
  width: 10rem;
  /* Tamaño inicial de la imagen */
  height: 10rem;
}

/* Media query para pantallas pequeñas */
@media (max-width: 568px) {
  .insignia-gestor {
    flex-direction: column;
    /* Imagen y texto en columna */
    text-align: center;
    /* Centrar contenido */
  }

  .insignia-img {
    width: 8rem;
    /* Reducir tamaño de la imagen */
    height: 8rem;
    margin-bottom: 0.1rem;
  }

  .insignia-gestor h2 {
    margin-top: 0.1rem;
  }

  .vsc {
    padding: 0.5rem;
  }

  .vsc h1 {
    font-size: 1rem;
  }
}
</style>