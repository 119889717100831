<template>
  <!-- Sección Parallax Inicial -->
  <div class="parallax">
    <div class="flex flex-col items-center justify-center text-center px-4">
      <img src="@/assets/img/loi-master/masterofgame.png" alt="publicidad"
        class="mt-8 w-80 h-20 mx-auto animate__animated animate__zoomInUp">
      <h1
        class="neon-text font-serif text-5xl text-yellow-500 font-extrabold animate__animated animate__zoomInLeft animate__slow">
        ¡El Futuro del Gaming está Aquí!
      </h1>
      <p class="neon-black text-white mt-10 text-lg animate__animated animate__zoomInRight animate__slower">
        No solo juegues, <b>¡posee, conquista y gana!</b> Sumérgete en una experiencia que difumina los límites entre la
        realidad y el mundo digital.

      </p>
      <a :href="`https://wa.me/52${participante.telefono}`" target="_blank" @click="$emit('set-click')"
        class="mt-12 mb-12 inline-block px-9 py-3 text-white font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.7)]">
        ¡Quiero ser Master Of Games!
      </a>
    </div>
  </div>

  <!-- Sección: Beneficios y Oportunidades -->
  <div class=" mt-8">
    <div class=" py-8">
      <div class="max-w-7xl mx-auto px-6 lg:px-8">
        <section class="p-6 px-8 flex flex-col md:flex-row items-center gap-6 mb-20">
          <!-- Imagen -->
          <img src="@/assets/img/loi-master/logo.jpg" alt="Insignia Gestor" class="w-45 h-26">
          <!-- Texto -->
          <div class="text-center md:text-left">
            <h2 class="font-serif text-2xl font-bold text-gray-700 mb-2">🎮 ¿Estás Listo para el Cambio?</h2>
            <p class="text-gray-700 text-justify">
              Legends of Interactions no es solo un juego, es un nuevo mundo lleno de posibilidades y descubrimientos.
              Aquí, podrás interactuar con otros jugadores y objetos virtuales en el espacio físico real, colocándote en
              la intersección de dos dimensiones: <b>¡el umbral de la singularidad digital!</b>
            </p>
          </div>
        </section>


        <!-- Explora -->
        <h2 class="font-serif text-3xl font-extrabold text-center text-gray-700 mb-8">
          🌍 Explora. Interactúa. Conquista. ¡Gana!
        </h2>
        <section class="flex flex-wrap justify-center gap-6 mt-10 mb-10">
          <!-- Card 1 -->
          <div class="bg-black p-6 rounded-lg shadow-lg w-72 text-center text-white  bg-cover bg-center"
            :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/land.jpg')})` }">
            <h3 class="neon-black text-xl font-bold text-white">⚔️ <b>Captura territorios</b> y expande tu dominio.</h3>
          </div>

          <!-- Card 2 -->
          <div class="bg-black p-6 rounded-lg shadow-lg w-72 text-center text-white  bg-cover bg-center"
            :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/land.jpg')})` }">
            <h3 class="neon-black text-xl font-bold text-white">🌾 <b>Farming</b> y recolección de recursos
              estratégicos.</h3>
          </div>

          <!-- Card 3 -->
          <div class="bg-black p-6 rounded-lg shadow-lg w-72 text-center text-white border  bg-cover bg-center"
            :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/land.jpg')})` }">
            <h3 class="neon-black text-xl font-bold text-white">💬 <b>Comunicación en tiempo real</b> con jugadores de
              todo el mundo.
            </h3>
          </div>
          <!-- Card 4 -->
          <div class="bg-black p-6 rounded-lg shadow-lg w-72 text-center text-white bg-cover bg-center"
            :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/land.jpg')})` }">
            <h3 class="neon-black text-xl font-bold text-white">💎 <b>Intercambio y comercio</b> de recursos digitales.
            </h3>
          </div>

          <!-- Card 5 -->
          <div class="bg-black p-6 rounded-lg shadow-lg w-72 text-center text-white bg-cover bg-center"
            :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/land.jpg')})` }">
            <h3 class="neon-black text-xl font-bold text-white">🏆 <b>Compite y alcanza el éxito</b> con estrategias
              únicas.</h3>
          </div>
        </section>
      </div>
    </div>
  </div>

  <!-- master of game-->
  <section class="flex justify-center items-center px-4 mt-16 mb-16">
    <div class="p-8 rounded-lg shadow-lg w-full max-w-full md:max-w-5xl text-center bg-cover bg-center"
      :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/fondoAzul.jpg')})` }">
      <h3 class="text-3xl text-white font-bold mb-6">
        Sé más que un jugador: <span class="text-4xl text-yellow-400">¡Conviértete en Master Of Game!</span>
      </h3>
      <ul class="space-y-3 text-white text-left neon-black">
        <li class="flex items-center">
          <span class="text-lg mr-2">✅</span> Compra parcelas virtuales únicas y obtén beneficios reales.
        </li>
        <li class="flex items-center">
          <span class="text-lg mr-2">✅</span> Gana por las interaccione de los jugadores dentro de tu
          territorio.
        </li>
        <li class="flex items-center">
          <span class="text-lg mr-2">✅</span> Economía descentralizada integrada al blockchain NETSBO.
        </li>
      </ul>
      <p class="mt-6 neon-black text-white">
        Las parcelas no son solo espacios dentro del juego, son activos que generan ingresos. Conecta con
        jugadores,
        organiza eventos, crea mercados y haz que tu territorio cobre vida.
      </p>
    </div>
  </section>

  <section
    class="w-full max-w-full md:max-w-5xl px2 mx-auto py-12 px-6 bg-black text-white rounded-lg border border-yellow-500 relative shadow-lg mb-16">
    <div class="absolute top-0 left-0 transform -translate-x-2 -translate-y-2 w-3 h-3 bg-pink-500 rounded-full">
    </div>
    <div class="flex flex-col md:flex-row items-center gap-6">
      <div class="md:w-3/5">
        <h3 class="text-3xl font-bold text-yellow-400 mb-4">POSEAN</h3>
        <p class="text-lg">
          <strong>¡Legends of Interactions</strong> une el mundo virtual y el real! Háganse propietarios
          de prestigiosos
          terrenos en los lugares más atractivos del planeta, desde Singapur hasta México. Aprovechen
          estas oportunidades
          no solo para poseer, sino también para ganar dinero en un universo de juego en rápido
          desarrollo. ¡Aquí su éxito
          no tiene límites!
        </p>
      </div>
      <div class="md:w-2/5">
        <img src="@/assets/img/loi-master/own.jpg" alt="Mapa del juego"
          class="rounded-lg shadow-lg border border-yellow-400">
      </div>
    </div>
  </section>

  <!-- creador de mercado-->
  <section class="flex justify-center items-center px-4 mt-16 mb-16">
    <div class="p-8 rounded-lg shadow-lg w-full max-w-full md:max-w-5xl text-center bg-cover bg-center"
      :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/fondoAzul.jpg')})` }">
      <h3 class="text-3xl text-white font-bold mb-6">
        Infinitas posibilidades de ganancias al ser: <span class="text-4xl text-yellow-400">¡Market
          Makers!</span>
      </h3>
      <ul class="space-y-3 text-white text-left neon-black">
        <li class="flex items-center">
          <span class="text-lg mr-2">✅</span> Crea Orber y objetos y obtén beneficios reales.
        </li>
        <li class="flex items-center">
          <span class="text-lg mr-2">✅</span> Gana por la venta de los orbes y objetos en el marketplace
          Antallagi.io.
        </li>
        <li class="flex items-center">
          <span class="text-lg mr-2">✅</span> Economía descentralizada integrada al blockchain NETSBO.
        </li>
        <li class="flex items-center">
          <span class="text-lg mr-2">✅</span> Multiples formas de generar ganancias con los programas 7Pin
          Token
          y con el programa H - Map.
        </li>
      </ul>
      <p class="mt-6 neon-black text-white">
        Infinitas formas de farmear y comercializar en el juego. crea objetos únicos, vende en el
        marketplace y gana.
      </p>
    </div>
  </section>

  <!-- informacion -->
  <div class="bg-gray-600 py-14 relative z-20 bg-cover bg-center"
    :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/bannerLoi2.png')})` }">
    <!-- Economy Section -->
    <section class=" py-12 px-6 bg-cover bg-center">
      <div class="max-w-5xl mx-auto text-center">
        <h2 class="neon-text font-serif text-4xl font-bold text-indigo-500"> No Solo Juegues, ¡Invierte y Gana!</h2>
        <p class="neon-black mt-4 text-lg text-white">Legends of Interactions ofrece una oportunidad única: ser dueño de
          parcelas virtuales
          dentro del juego. Cada parcela genera ingresos pasivos basados en la actividad e interacciones que ocurran en
          ella. Mientras otros juegan, tú ganas.</p>
      </div>
      <div class="mt-8 flex justify-center gap-6">

        <img src="@/assets/img/loi-master/mog-logo.jpg" alt="Antallagi Logo"
          class="w-40 h-10 transform hover:scale-110 transition">
      </div>
    </section>


    <!-- Marketplace -->
    <section class=" py-12 px-6 bg-cover bg-center">
      <div class="max-w-5xl mx-auto text-center">
        <h2 class="neon-text font-serif text-4xl font-bold text-indigo-500"> Su Negocio en el Marketplace</h2>
        <p class="neon-black mt-4 text-lg text-white">
          Utiliza <span class="text-yellow-400 font-semibold">Antallagí.io</span> para realizar intercambios de forma
          segura y construir tu
          imperio digital.<br><br> <span class="text-yellow-400 font-semibold">Antallagí.io</span> es su herramienta
          para el comercio de recursos y tierras de juego.
          Gracias a la red blockchain <span class="text-yellow-400 font-semibold">NETSBO</span> , pueden gestionar con
          confianza sus activos digitales y llegar a
          un público enorme, escalando su negocio.</p>
      </div>
      <div class="mt-8 flex justify-center gap-6">

        <img src="@/assets/img/loi-master/antallagi-logo.png" alt="Antallagi Logo"
          class="w-40 h-auto transform hover:scale-110 transition">
        <img src="@/assets/img/loi-master/netsbo-logo.jpg" alt="Antallagi Logo"
          class="w-40 h-10 transform hover:scale-110 transition">
      </div>
    </section>
  </div>

  <!-- Llamada a la acción -->
  <div class="parallax bg-gray-600 py-16 relative z-20 mt-16">
    <!-- CTA -->
    <section class="max-w-3xl mx-auto text-center py-12 px-6">
      <h2 class="neon-text font-serif text-4xl font-bold text-yellow-500"> ¡Sé de los Primeros en un Mundo Nuevo!</h2>
      <p class="neon-black mt-4 text-lg text-white">Reserva tu parcela hoy y sé parte de la élite de
        jugadores-inversores.</p>
      <a :href="`https://wa.me/52${participante.telefono}`" target="_blank" @click="$emit('set-click')"
        class="mt-18 mb-12 inline-block px-9 py-3 text-white font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.7)]">
        💬 Comunicate con un asesor ahora
      </a>

      <p class="neon-text font-serif mt-4 text-lg text-blue-900 font-bold">Legends of Interactions — La Frontera Entre
        la Realidad y lo Digital.</p>
    </section>
  </div>
</template>


<script>
export default {
  name: 'loi-master2',
  props: {
    participante: {
      type: Object,
      default() {
        return {
          nombre: "[VSC]",
          telefono: "4271615141",
          dato_identificacion: "00000000",
          web_url: "https://virtualshoppingclub.com",
          registro_url: "https://virtualshoppingclub.com/registro/1904121"
        }
      }
    },
    sinParticipantes: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.neon-text {
  text-shadow: 0 0 10px #ffffff, 0 0 10px #ffffff, 0 0 30px #ffffff;
}

.neon-black {
  text-shadow: 0 0 10px #000000, 0 0 20px #000000, 0 0 30px #000000;
}



/* Efecto Parallax */
.parallax {
  background-image: url('@/assets/img/loi-master/bannerAzul.jpg');
  height: 500px;
  background-attachment: fixed;
  /* Aquí ocurre la magia */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@keyframes gradientChange {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.gradient-bg {
  background: linear-gradient(45deg, #3a065a, #1443ef, #3a065a, #1443ef);
  background-size: 400% 400%;
  animation: gradientChange 4s ease infinite;
}
</style>