<template>
  <div class=" bg-gradient-to-b from-blue-900 via-blue-800 to-indigo-900 text-white">
    <!-- Encabezado -->
    <header class="text-center py-10">
      <h1 class="text-4xl font-bold text-white animate__animated animate__fadeInBottomLeft">Aprovecha Esta Oportunidad
        Para ser <br>
        <span class="text-5xl font- font-serif text-cyan-300">Patrocinador de Tecnológia</span> 🌐
      </h1>
      <p
        class="mt-6 mb-12 text-lg text-justify text-blue-200 px-8 animate__animated animate__fadeInBottomLeft animate__slower">
        ¡Impulsa tu crecimiento como socio estratégico!
        Accede a ventajas
        únicas, amplía tus
        oportunidades financieras y sé parte de una comunidad que transforma el futuro. ¡Conviértete en
        patrocinador hoy y deja tu huella!</p>
    </header>
    <div class="bg-gradient-to-r from-blue-900 via-blue-400 to-blue-900 pt-1 pb-1">
    </div>

    <!-- Sección de patrocinadores -->
    <h2 class="mt-14 mb-6 text-4xl font-bold text-white text-center animate__animated animate__rotateInDownLeft">
      Formas de ser Patrocinador
    </h2>
    <section class="max-w-6xl mx-auto px-6 grid grid-cols-1 md:grid-cols-3 gap-6">
      <!-- Patrocinador 1 -->
      <div
        class="bg-gradient-to-r from-indigo-700 to-blue-500 p-6 rounded-lg shadow-lg hover:scale-105 transition transform duration-300 flex flex-col h-full">
        <h2 class="text-xl font-semibold mb-3">Gemas Publicitarias</h2>
        <p class="text-blue-100 mb-4 text-justify flex-grow">
          Imagina generar ingresos mes tras mes solo por patrocinar nuestra tecnología. Con tu gema publicitaria es
          posible. Obtén desde pesos hasta
          medio millón al año por gema, todo depende de la categoría que elijas y su demanda por los miembros del
          clu.<br>
          ✅ Pago único – No necesitas renovaciones ni cuotas extra.<br>
          ✅ Ganas de por vida – La gema es tuya para siempre.<br>
          ✅ Tantas como quieras – No hay límite, participa en las categorías que desees
        </p>
        <div class="mt-auto">
          <router-link :to="registroRoute"
            class="bg-green-400 text-blue-900 px-4 py-2 rounded hover:bg-green-300 transition">
            Descubre más
          </router-link>
        </div>

      </div>

      <!-- Patrocinador 2 -->
      <div
        class="bg-gradient-to-r from-purple-600 to-blue-500 p-6 rounded-lg shadow-lg hover:scale-105 transition transform duration-300 flex flex-col h-full">
        <h2 class="text-xl font-semibold mb-3">Paquete Vitalicio</h2>
        <p class="text-blue-100 mb-4 text-justify flex-grow">
          Este es el estatus más alto dentro de la empresa, diseñado para quienes buscan ingresos sólidos y permanentes.
          🚀 <br>
          💰 Ganancias ilimitadas - No hay limite de ganancias.<br>
          💎 1 Gema Génesis – Forma parte del bono anual de miembros Génesis del 5% de las utilidades de la empresa.<br>
          📈 1 Gema de Proyecto – Gana hasta $500,000 al año con las ventas en una de las 100 categorías
          disponibles.<br>
          💲Y más beneficios.
        </p>
        <div class="mt-auto">
          <router-link :to="registroRoute"
            class="bg-green-400 text-blue-900 px-4 py-2 rounded hover:bg-green-300 transition">
            Descubre más
          </router-link>
        </div>
      </div>

      <!-- Patrocinador 3 -->
      <div
        class="bg-gradient-to-r from-blue-800 to-cyan-600 p-6 rounded-lg shadow-lg hover:scale-105 transition transform duration-300 flex flex-col h-full">
        <h2 class="text-xl font-semibold mb-3">Programa Especial F3</h2>
        <p class="text-blue-100 mb-4 flex-grow">
          Con el paquete de Patrocinio F3, te conviertes en patrocinador de tecnología y obtienes 2
          participaciones permanentes.
          Estas te permiten recibir un bono mensual basado en el número de transacciones de ventas en
          establecimientos por zona
          ¡Apoya nuestra aplicación y disfruta de beneficios cada mes sin complicaciones!
        </p>
        <div class="mt-auto">
          <router-link :to="registroRoute"
            class="bg-green-400 text-blue-900 px-4 py-2 rounded hover:bg-green-300 transition">
            Descubre más
          </router-link>
        </div>
      </div>

    </section>

    <!-- por que ser patrocinador -->
    <section class="max-w-7xl mx-auto px-4 py-10">
      <h2 class="text-3xl font-bold text-blue-200 text-center">¿Por qué
        ser patrocinador?
      </h2>
      <p class="mt-4 text-center text-justify text-lg text-blue-200">
        Ser patrocinador de nuestra tecnología significa formar parte del crecimiento del Virtual Shopping Club y
        acceder a
        herramientas avanzadas que te permitirán generar ingresos mientras maximizas tu impacto en la economía digital.
        Además,
        contribuirás a revolucionar la forma en que las personas compran y se conectan en el mundo virtual.
      </p>
    </section>

    <div class="mx-auto max-w-4xl px-6 lg:px-8 mb-12">
      <div class="aspect-w-16 aspect-h-9">
        <iframe class="absolute rounded-lg  top-0 left-0 w-full h-full" src="https://www.youtube.com/embed/f1SxM7npTNY"
          frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>

    <div class="bg-gradient-to-r from-blue-900 via-blue-400 to-blue-900 pt-1 pb-1">
    </div>


    <!-- Sección: ¿Qué reciben nuestros patrocinadores? -->
    <section class="bg-gradient-to-r from-blue-900 via-indigo-800 to-cyan-700 py-12 px-6">
      <div class="max-w-5xl mx-auto text-center">
        <!-- Título -->
        <h2 class="text-4xl font-extrabold text-cyan-300 mb-6">
          En Virtual Shopping Club
          <span class="block text-5xl text-green-400 mt-2">Nuestros Patrocinadores</span>
        </h2>

        <!-- Texto descriptivo -->
        <p class="text-lg text-blue-200 font-semibold mb-8">
          Reciben tecnología que les estará generando beneficios que pueden utilizar para:
        </p>

        <!-- Contenedor Principal -->
        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 items-center">

          <!-- Beneficios - Izquierda -->
          <ul class="space-y-4 text-left">
            <li
              class="flex items-center bg-indigo-700 bg-opacity-70 text-cyan-200 p-4 rounded-lg shadow-lg hover:bg-indigo-600 transition duration-300">
              <span class="text-green-300 text-3xl font-bold mr-2">•</span> Recargas telefónicas
            </li>
            <li
              class="flex items-center bg-indigo-700 bg-opacity-70 text-cyan-200 p-4 rounded-lg shadow-lg hover:bg-indigo-600 transition duration-300">
              <span class="text-green-300 text-3xl font-bold mr-2">•</span> Compras en el marketplace
            </li>

            <li
              class="flex items-center bg-indigo-700 bg-opacity-70 text-cyan-200 p-4 rounded-lg shadow-lg hover:bg-indigo-600 transition duration-300">
              <span class="text-green-300 text-3xl font-bold mr-2">•</span> Pagos de servicios
            </li>
            <li
              class="flex items-center bg-indigo-700 bg-opacity-70 text-cyan-200 p-4 rounded-lg shadow-lg hover:bg-indigo-600 transition duration-300">
              <span class="text-green-300 text-3xl font-bold mr-2">•</span> Compras en Negocios Afiliados
            </li>
          </ul>

          <!-- Imagen en el centro -->
          <div class="flex justify-center">
            <img src="@/assets/img/vsc-cel.png" alt="Nuestros Patrocinadores">
          </div>

          <!-- Beneficios - Derecha -->
          <ul class="space-y-4 text-left">
            <li
              class="flex items-center bg-indigo-700 bg-opacity-70 text-cyan-200 p-4 rounded-lg shadow-lg hover:bg-indigo-600 transition duration-300">
              <span class="text-green-300 text-3xl font-bold mr-2">•</span> Marketing digital
            </li>
            <li
              class="flex items-center bg-indigo-700 bg-opacity-70 text-cyan-200 p-4 rounded-lg shadow-lg hover:bg-indigo-600 transition duration-300">
              <span class="text-green-300 text-3xl font-bold mr-2">•</span> Patrocinios
            </li>
            <li
              class="flex items-center bg-indigo-700 bg-opacity-70 text-cyan-200 p-4 rounded-lg shadow-lg hover:bg-indigo-600 transition duration-300">
              <span class="text-green-300 text-3xl font-bold mr-2">•</span> Publicidad
            </li>
            <li
              class="flex items-center bg-indigo-700 bg-opacity-70 text-cyan-200 p-4 rounded-lg shadow-lg hover:bg-indigo-600 transition duration-300">
              <span class="text-green-300 text-3xl font-bold mr-2">•</span> Pagos de membresías
            </li>
            <li
              class="flex items-center bg-indigo-700 bg-opacity-70 text-cyan-200 p-4 rounded-lg shadow-lg hover:bg-indigo-600 transition duration-300">
              <span class="text-green-300 text-3xl font-bold mr-2">•</span> Generación de ingresos recurrentes
            </li>
          </ul>

        </div>
      </div>
    </section>

    <div class="bg-gradient-to-r from-blue-900 via-blue-400 to-blue-900 pt-1 pb-1">
    </div>


    <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mt-16 px-4">
      <!-- Card: Gemas -->
      <div
        class="max-w-sm mx-auto bg-gradient-to-r from-gray-500 via-black to-gray-600 text-white p-6 rounded-lg shadow-lg transform hover:-translate-y-2 transition duration-300">
        <h2 class="text-xl font-extrabold mb-2"> Gemas Publicitarias</h2>
        <p class="text-white text-sm mb-4">Gana de las ventas de una de las 100 categorías disponibles.</p>
        <div class="mb-8">
          <h3 class="text-5xl font-bold text-cyan-400">$20,000 <span class="text-2xl">MXN</span></h3>
          <p class="text-cyan-400 text-sm mt-2">Recárgalas y úsalas a tu conveniencia.</p>
        </div>
        <router-link :to="registroRoute" class="block w-full bg-cyan-400 hover:bg-cyan-600 text-center text-white font-bold py-3 rounded-lg
                              transition duration-300">
          Comprar Gemas
        </router-link>
        <ul class="mt-6 space-y-2 text-sm">
          <li class="flex items-center"><span class="text-orange-600 mr-2">✅</span>Genera ingresos hasta 500,000 al año!
          </li>
          <li class="flex items-center"><span class="text-orange-600 mr-2">✅</span>Un solo pago, no anualidad, ni
            comisiones extra</li>
          <li class="flex items-center"><span class="text-orange-600 mr-2">✅</span>Tecnología permanente</li>
        </ul>
      </div>

      <!-- Card: Paquete Vitalicio -->
      <div
        class="max-w-sm mx-auto bg-gradient-to-r from-gray-500 via-black to-gray-600 text-white p-6 rounded-lg shadow-lg transform hover:-translate-y-2 transition duration-300">
        <h2 class="text-xl font-extrabold mb-2"> Paquete Vitalicio</h2>
        <p class="text-white text-sm mb-4">Acceso ilimitado a todos los beneficios para siempre.</p>
        <div class="mb-8">
          <h3 class="text-5xl font-bold text-cyan-400">$39,500 <span class="text-2xl">MXN</span></h3>
          <p class="text-cyan-400 text-sm mt-2">Paga una vez y disfruta para siempre.</p>
        </div>
        <router-link :to="registroRoute" class="block w-full bg-cyan-400 hover:bg-cyan-600 text-center text-white font-bold py-3 rounded-lg
                              transition duration-300">
          Comprar Paquete
        </router-link>
        <ul class="mt-6 space-y-2 text-sm">
          <li class="flex items-center"><span class="text-orange-600 mr-2">✅</span>¡GANANCIAS ILIMITADAS!</li>
          <li class="flex items-center"><span class="text-orange-600 mr-2">✅</span>1 Gema Genesis (Bono anual)</li>
          <li class="flex items-center"><span class="text-orange-600 mr-2">✅</span>1 Gema de Proyecto (Ganancias hasta
            $500,000 al año por gema)</li>
        </ul>
      </div>

      <!-- Card: Programa F3 -->
      <div
        class="max-w-sm mx-auto bg-gradient-to-r from-green-400 via-green-600 to-green-400 text-white p-6 rounded-lg shadow-lg transform hover:-translate-y-2 transition duration-300">
        <h2 class="text-xl font-extrabold mb-2"> Programa F3</h2>
        <p class="text-white text-sm mb-4">Forma parte del equipo de crecimiento y obtén ingresos.</p>
        <div class="mb-8">
          <h3 class="text-5xl font-bold text-yellow-400">$10,000 <span class="text-2xl">MXN</span></h3>
          <p class="text-white text-sm mt-2">Inversión única para unirte al programa.</p>
        </div>
        <router-link :to="registroRoute" class="block w-full bg-yellow-400 hover:bg-orange-500 text-center
                              text-white font-bold py-3 rounded-lg transition duration-300">
          Unirse al Programa
        </router-link>
        <ul class="mt-6 space-y-2 text-sm">
          <li class="flex items-center"><span class="text-orange-600 mr-2">✅</span>Gana más de $70,000 al mes</li>
          <li class="flex items-center"><span class="text-orange-600 mr-2">✅</span>Un solo pago</li>
          <li class="flex items-center"><span class="text-orange-600 mr-2">✅</span>Tecnología permanente</li>
        </ul>
      </div>
    </div>

    <div class="bg-gradient-to-r from-blue-900 via-blue-400 to-blue-900 pt-1 pb-1 mt-16">
    </div>

    <section class="w-full py-16 px-6 flex flex-col md:flex-row items-center justify-center pt-2 pb-2"
      :style="{ backgroundImage: `url(${require('@/assets/img/members/fondoPatrocinador.png')})` }">
      <!-- Imagen a la izquierda -->
      <div class="w-full md:w-1/2 flex justify-center">
        <img src="@/assets/img/members/robotPatrocinador.png" alt="Imagen Descriptiva" class="w-50 md:w-96">
      </div>
      <!-- Contenedor de Texto -->
      <div class="w-full md:w-1/2 text-center md:text-left px-8">
        <h2 class="animated-gradient text-4xl font-bold text-blue-200">Virtual Shopping Club</h2>
        <h1 class="text-3xl font-bold text-blue-200">¿Quiénes Somos?</h1>
        <p class="mt-4 text-justify text-lg text-blue-200">
          Somos la primera aplicación que transforma tus pagos diarios en ganancias. Desde recargas, luz, internet,
          compras del súper y mucho más, cada gasto te genera una nueva forma de ingresos, de manera fácil, segura y
          100%
          digital.
          <br> <br>
          ¡Y lo mejor! También ganas por las compras de quienes recomiendas.
        </p>
      </div>
    </section>

    <div class="bg-gradient-to-r from-blue-900 via-blue-400 to-blue-900 pt-1 pb-1">
    </div>

    <section class="w-full py-16 px-6 flex flex-col md:flex-row-reverse items-center justify-center pt-2 pb-2"
      :style="{ backgroundImage: `url(${require('@/assets/img/members/fondoPatrocinador.png')})` }">
      <!-- Imagen a la derecha en pantallas grandes -->
      <div class="w-full md:w-1/2 flex justify-center">
        <img src="@/assets/img/members/robotPatrocinador2.png" alt="Imagen Descriptiva" class="w-50 md:w-100">
      </div>
      <!-- Contenedor de Texto -->
      <div class="w-full md:w-1/2 text-center md:text-left px-8">
        <h2 class="animated-gradient text-4xl font-bold text-blue-200">Virtual Shopping Club</h2>
        <h1 class="text-3xl font-bold text-blue-200">¿Qué te ofrecemos?</h1>
        <p class="mt-4 text-justify text-lg text-blue-200">
          Una comunidad que transforma los gastos diarios en oportunidades de ingreso. Con nuestra app, haces lo mismo
          de siempre, pero con beneficios que impulsan tu bienestar financiero.
          <br>
          <span class="font-bold">Recargas móviles. </span><br>
          <span class="font-bold">Pagos de servicios. </span><br>
          <span class="font-bold">Compras en nuestro marketplace. </span> <br>
          <span class="font-bold">Compras en negocios afiliados. </span><br>
        </p>
      </div>
    </section>


    <div class="bg-gradient-to-r from-blue-900 via-blue-400 to-blue-900 pt-1 pb-1">
    </div>

    <section class="w-full py-16 px-6 flex flex-col md:flex-row items-center justify-center pt-4 pb-2"
      :style="{ backgroundImage: `url(${require('@/assets/img/members/fondoPatrocinador.png')})` }">
      <!-- Imagen a la izquierda -->
      <div class="w-full md:w-1/2 flex justify-center">
        <img src="@/assets/img/members/dolarPatrocinador.png" alt="Imagen Descriptiva" class="w-45 md:w-80">
      </div>
      <!-- Contenedor de Texto -->
      <div class="w-full md:w-1/2 text-center md:text-left px-8">
        <h2 class="animated-gradient text-4xl font-bold text-blue-200">Virtual Shopping Club</h2>
        <h1 class="text-3xl font-bold text-blue-200 ">¿Cómo puedes beneficiarte aún más?</h1>
        <p class="mt-4 text-justify text-lg text-blue-200">
          Además de usar la aplicación gratis, puedes llevar tus ingresos al siguiente nivel con tres opciones:
          Patrocinador de tecnología: Genera ingresos con nuestra plataforma.
          Miembro del club de negocios: Recibe beneficios exclusivos y más ganancias.
          Gestor: Apoya a negocios y obtén ingresos extra con nuestra tecnología.<br>
          ¡Tú decides cómo ganar, nosotros te damos las herramientas!
        </p>
      </div>
    </section>

    <div class="bg-gradient-to-r from-blue-900 via-blue-400 to-blue-900 pt-1 pb-1">
    </div>

    <!-- Sección de llamada a la acción -->
    <div class="text-center mt-10 pb-16">
      <router-link :to="registroRoute"
        class="bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-green-400 transition text-xl">
        ¡Conviértete en Patrocinador!
      </router-link>
    </div>

    <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>


  </div>
</template>

<script>

import patrocinadoresComp from "@/components/campanias/patrocinadores";

export default {
  name: 'patrocinadorTecnologia2',
  components: {
    patrocinadoresComp
  },
  props: {
    patrocinadores: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    fbq('init', '1351295932570313')
    fbq('track', 'PageView')
  },
  computed: {
    registroRoute() {
      return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
        '/registro'
    }
  }
}

</script>

<style scoped>
/* Estilo adicional si es necesario */

.animated-gradient {
  font-size: 1.3rem;
  /* Ajusta el tamaño */
  font-weight: 800;
  /* Fuente extrabold */
  text-align: center;
  /* Centrar texto */
  background: linear-gradient(90deg, #ff7eb3, #6a5acd, #4facfe);
  /* Gradiente de 3 colores */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  /* El gradiente aparecerá en lugar del color sólido */
}

.bg-gradient {
  background: linear-gradient(45deg, #16606b, #2c9aa6, #103138, #000000);
  background-size: 400% 400%;
  animation: gradientChange 4s ease infinite;
}

.text-blue-600 {
  color: #16606b;
  /* Azul intenso para los encabezados */
}

.text-blue-600 {
  color: #103138;
  /* Azul intenso para los encabezados */
}

.text-blue-600 {
  color: #2c9aa6;
  /* Azul intenso para los encabezados */
}

.text-blue-600 {
  color: #000000;
  /* Azul intenso para los encabezados */
}
</style>