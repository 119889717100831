<template>
  <div class="bg-gradient-to-b from-blue-500 to-purple-200 min-h-screen">
    <!-- Sección de Bienvenida -->
    <header class="relative py-16 px-6 text-center    bg-cover bg-left md:bg-center pt-32 pb-32"
      :style="{ backgroundImage: `url(${require('@/assets/img/fondo.png')})` }">
      <div class="max-w-4xl mx-auto">
        <h1 class="text-5xl font-bold text-blue-800 mb-6 leading-tight animate__animated animate__rollIn">
          ¡Únete a <span class="text-purple-700">Miembros Expansión</span> y Transforma tu Futuro!
        </h1>
        <p class="text-lg text-gray-600 animate__animated animate__rollIn animate__delay-1s">
          Descubre cómo generar ingresos con nuestra aplicacion <span class="font-bold">Virtual Shopping Club</span>,
          conecta con emprendedores y desarrolla tu red.
          Accede a oportunidades únicas y lleva tu potencial al siguiente nivel. 🚀
        </p>
        <router-link :to="registroRoute"
          class="mt-8 inline-block px-6 py-3 bg-gradient-to-r from-purple-600 to-blue-600 text-white font-bold rounded-full 
                  shadow-md hover:scale-105 transition-all duration-300 animate__animated animate__rollIn animate__delay-2s">
          ¡Regístrate Gratis!
        </router-link>
      </div>
    </header>
    <div class="bg-gradient-to-r from-blue-400 via-purple-700 to-blue-400 pt-1"></div>



    <!-- SECCIÓN: Beneficios y Oportunidades -->
    <section class="py-20 px-6">
      <div class="max-w-7xl mx-auto">
        <div class="text-center mb-16">
          <h2 class="text-4xl font-bold text-white mb-4">Explora Nuestras Oportunidades</h2>
          <p class="text-lg text-gray-700">Descubre roles que impulsan tu éxito y crecimiento profesional.</p>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
          <!-- Card Publicista -->
          <div class="bg-gray-100 shadow-lg rounded-lg p-8 hover:shadow-2xl transform hover:scale-105 transition">
            <div class="flex items-center gap-4">
              <img src="@/assets/img/members/publicista.png" alt="Publicista" class="w-24 h-24 rounded-full">
              <h3 class="text-2xl font-bold text-blue-600">Publicista</h3>
            </div>
            <p class="text-gray-600 mt-4 text-justify">
              Los publicistas son el motor detrás de la promoción de nuestras marcas y servicios. Conéctate con un
              público más amplio y gana recompensas exclusivas.
            </p>
          </div>
          <!-- Card Desarrollador de Red -->
          <div class="bg-gray-100 shadow-lg rounded-lg p-8 hover:shadow-2xl transform hover:scale-105 transition">
            <div class="flex items-center gap-4">
              <img src="@/assets/img/members/desarrolladorRed.png" alt="Desarrollador de Red"
                class="w-24 h-24 rounded-full">
              <h3 class="text-2xl font-bold text-blue-600">Desarrollador de Red</h3>
            </div>
            <p class="text-gray-600 mt-4 text-justify">
              Construye y lidera tu propio equipo de trabajo. Como desarrollador de red, llevarás el control de tu
              gestoría y alcanzarás metas más rápido.
            </p>
          </div>
        </div>
      </div>


      <!-- Insignia Gestor -->
      <div
        class="insignia-gestor mt-16 bg-white shadow-lg rounded-lg p-6 flex flex-col md:flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
        <!-- Imagen -->
        <img src="@/assets/img/members/InsigniaGestor.png" alt="Insignia Gestor"
          class="insignia-img w-40 h-40 rounded-full object-cover">
        <!-- Texto -->
        <div class="text-center md:text-left">
          <h2 class="text-2xl font-bold text-blue-600 mb-2">Insignia Gestor</h2>
          <p class="text-gray-600 text-justify">
            ¡Sé el líder de tu propia red de emprendedores! Conviértete en el gerente de tu célula de marketing y activa
            tu insignia de Gestor. Genera ingresos increíbles
            con la venta de paquetes como la <span class="font-bold">Tarjeta Alianza VSC</span>, el <span
              class="font-bold">Paquete Expansión</span> y la <span class="font-bold">Membresía de Negocios</span>.
            ¿Lo mejor? Puedes ganar desde pequeñas recompensas hasta miles de pesos al expandir tu red. <span
              class="font-bold">¡Conecta, lidera y multiplica tus ingresos hoy mismo!</span>
          </p>
        </div>
      </div>
    </section>

    <!-- Programa R -->
    <section class="py-16 px-6 bg-gradient-to-b from-blue-50 to-purple-100">
      <h2 class="text-3xl font-bold text-blue-800 mb-8 text-center">Beneficios del Programa Especial R</h2>
      <h2 class="text-2xl font-bold text-blue-700 mb-8 text-center">Obtén bonos del fondo de las utilidades de VSC, de
        un 10%-30%</h2>

      <div class="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        <div
          class="bg-white border-l-4 border-r-4 border-blue-700 shadow-lg rounded-lg p-6 text-center hover:shadow-2xl transform hover:scale-105 transition">
          <img src="@/assets/img/members/r-1.png" alt="Ingreso Extra" class="w-20 mx-auto mb-4">
          <p class="text-gray-600">Aplica $1,000 o más en compras de recargas y marketplace
            al mes para recibir tu bono en esta categorias.</p>
        </div>
        <div
          class="bg-white border-l-4 border-r-4 border-blue-700 shadow-lg rounded-lg p-6 text-center hover:shadow-2xl transform hover:scale-105 transition">
          <img src="@/assets/img/members/r-2.png" alt="Crecimiento Profesional" class="w-20 mx-auto mb-4">
          <p class="text-gray-600">Aplica $1,000 o más en compras en establecimientos o
            4 pagos de servicios digitales al mes y obtén tu bono enestas categorías.</p>
        </div>
        <div
          class="bg-white border-l-4 border-r-4 border-blue-700 shadow-lg rounded-lg p-6 text-center hover:shadow-2xl transform hover:scale-105 transition">
          <img src="@/assets/img/members/r-3.png" alt="Flexibilidad" class="w-20 mx-auto mb-4">
          <p class="text-gray-600">Para recibir tu bono invita a tus amigos o conocidosa que multipliquen sus ganancias
            con uno de nuestros
            paquetes o herrameintas.</p>
        </div>
      </div>
    </section>


    <section class="py-12 px-4">
      <div class="max-w-6xl mx-auto">
        <!-- Título principal -->
        <h2 class="text-2xl md:text-3xl text-center text-white font-bold mb-8">Motivos para unirte</h2>
        <!-- Contenedor de tarjetas -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">

          <!-- Tarjeta 1 -->
          <div class="relative bg-white flex flex-col items-center text-center p-4 rounded-lg overflow-hidden">
            <!-- Bordes laterales con gradiente -->
            <div class="absolute inset-y-0 left-0 w-2 bg-gradient-to-b from-blue-400 via-purple-400 to-blue-400"></div>
            <div class="absolute inset-y-0 right-0 w-2 bg-gradient-to-b from-blue-400 via-purple-400 to-blue-400"></div>
            <!-- Contenido -->
            <h3 class="text-lg font-semibold mb-2">Oportunidades de Generar Ingresos</h3>
            <p class="text-sm leading-relaxed">
              Ve en smart TV, PlayStation, Xbox, Chromecast, Apple TV, reproductores de Blu-ray y más.
            </p>
          </div>

          <!-- Tarjeta 2 -->
          <div class="relative bg-white flex flex-col items-center text-center p-4 rounded-lg overflow-hidden">
            <!-- Bordes laterales con gradiente -->
            <div class="absolute inset-y-0 left-0 w-2 bg-gradient-to-b from-blue-400 via-purple-400 to-blue-400"></div>
            <div class="absolute inset-y-0 right-0 w-2 bg-gradient-to-b from-blue-400 via-purple-400 to-blue-400"></div>
            <!-- Contenido -->
            <h3 class="text-lg font-semibold mb-2">Roles y Oportunidades de Crecimiento</h3>
            <p class="text-sm leading-relaxed">
              Ve en smart TV, PlayStation, Xbox, Chromecast, Apple TV, reproductores de Blu-ray y más.
            </p>
          </div>

          <!-- Tarjeta 3 -->
          <div class="relative bg-white flex flex-col items-center text-center p-4 rounded-lg overflow-hidden">
            <!-- Bordes laterales con gradiente -->
            <div class="absolute inset-y-0 left-0 w-2 bg-gradient-to-b from-blue-400 via-purple-400 to-blue-400"></div>
            <div class="absolute inset-y-0 right-0 w-2 bg-gradient-to-b from-blue-400 via-purple-400 to-blue-400"></div>
            <!-- Contenido -->
            <h3 class="text-lg font-semibold mb-2">Beneficios Exclusivos del Programa Especial R</h3>
            <p class="text-sm leading-relaxed">
              Ve en smart TV, PlayStation, Xbox, Chromecast, Apple TV, reproductores de Blu-ray y más.
            </p>
          </div>

          <!-- Tarjeta 4 -->
          <div class="relative bg-white flex flex-col items-center text-center p-4 rounded-lg overflow-hidden">
            <!-- Bordes laterales con gradiente -->
            <div class="absolute inset-y-0 left-0 w-2 bg-gradient-to-b from-blue-400 via-purple-400 to-blue-400"></div>
            <div class="absolute inset-y-0 right-0 w-2 bg-gradient-to-b from-blue-400 via-purple-400 to-blue-400"></div>
            <!-- Contenido -->
            <h3 class="text-lg font-semibold mb-2">Comunidad y Herramientas Innovadoras</h3>
            <p class="text-sm leading-relaxed">
              Ve en smart TV, PlayStation, Xbox, Chromecast, Apple TV, reproductores de Blu-ray y más.
            </p>
          </div>
        </div>
      </div>

    </section>

    <div
      class="flex flex-col md:flex-row gap-6 justify-center items-stretch mt-12 mb-12 px-4 md:px-0 max-w-4xl mx-auto">
      <!-- Card 1 -->
      <div class="bg-gray-200 text-white p-6 rounded-lg shadow-lg md:w-1/2 h-full flex flex-col justify-between">
        <h2 class="text-green-500 text-2xl font-bold mb-4 text-center md:text-left">
          Quien puede ser miembro Expansión:
        </h2>
        <ul class="space-y-2 flex-1 text-gray-600">
          <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Aquellos que quieren
              desarrollar red.</span></li>
          <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Interesados en mejorar su
              economía.</span></li>
          <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Aquellos que quieren ganar
              dinero.</span></li>
          <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Los que saben que no hay
              dinero fácil.</span></li>
        </ul>
      </div>

      <!-- Card 2 -->
      <div class="bg-gray-200 text-white p-6 rounded-lg shadow-lg md:w-1/2 h-full flex flex-col justify-between">
        <h2 class="text-red-500 text-2xl font-bold mb-4 text-center md:text-left">
          Quien no puede ser miembro Expansión:
        </h2>
        <ul class="space-y-2 flex-1 text-gray-600">
          <li class="flex items-center "><span class="text-xl">❌</span> <span class="ml-2">
              Los que no les gusta desarrollar red.</span></li>
          <li class="flex items-center"><span class="text-xl">❌</span> <span class="ml-2">
              Aquellos creyentes en dinero fácil.</span></li>
          <li class="flex items-center"><span class="text-xl">❌</span> <span class="ml-2">
              Los que quieren poco esfuerzo.</span></li>
          <li class="flex items-center"><span class="text-xl">❌</span> <span class="ml-2">
              Los que no tiene compromiso.</span></li>
        </ul>
      </div>
    </div>

    <div class="bg-gradient-to-r from-purple-700 via-blue-400 to-purple-500 pb-1"></div>
    <section class="insignia-gestor bg-gray-200 shadow-lg p-6 px-8 flex flex-col md:flex-row items-center gap-6">
      <!-- Imagen -->
      <img src="@/assets/img/vsc.png" alt="Insignia Gestor" class="insignia-img w-40 h-40 rounded-full object-cover">
      <!-- Texto -->
      <div class="text-center md:text-left">
        <h2 class="text-2xl font-bold text-blue-500 mb-2">¿Quienes somos?</h2>
        <p class="text-gray-600 text-justify">
          Somos una comunidad que transforma lo cotidiano en oportunidades. Virtual Shopping Club es una aplicación
          innovadora que convierte tus gastos comunes y los de tus amigos en ingresos. <br><br>

          Cada vez que pagas servicios, haces una recarga o compras en nuestros comercios afiliados, generas
          recompensas . Además, al recomendar la aplicación, multiplicas tus beneficios.

          Gasta lo mismo, pero gana más.
        </p>
      </div>
    </section>
    <div class="bg-gradient-to-r from-purple-700 via-blue-400 to-purple-500 pt-1"></div>




    <!-- CTA Final -->
    <section class="text-center py-20 px-6">
      <h2 class="text-4xl font-bold text-purple-700 mb-6 animate-bounce">¡Únete Ahora y Comienza a Crecer!</h2>
      <p class="text-gray-600 mb-6">Registrate y lleva tus habilidades de liderazgo al siguiente nivel.</p>
      <router-link :to="registroRoute" class="mt-6 inline-block px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white font-bold rounded-full
                shadow-lg hover:scale-105 transition-all duration-300">
        ¡Quiero Ser Patrocinador!
      </router-link>
    </section>

    <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>
  </div>
</template>

<script>
import patrocinadoresComp from "@/components/campanias/patrocinadores"

export default {
  name: 'MiembroExpansion2',
  components: {
    patrocinadoresComp
  },
  props: {
    patrocinadores: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    fbq('init', '1351295932570313')
    fbq('track', 'PageView')
  },
  computed: {
    registroRoute() {
      return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
        '/registro'
    }
  }
}
</script>

<style scoped>
/* Animaciones */
.animate__fadeInDown {
  animation: fadeInDown 1s ease-out;
}

.animate__fadeInUp {
  animation: fadeInUp 1s ease-out;
}

.animate-bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

/* Hover efectos */
.shadow-md:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Ajuste de imagenes */
img {
  max-width: 100%;
  height: auto;
}

/* Responsive */
@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.8rem;
  }
}
</style>