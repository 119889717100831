<template>
  <!-- Fondo y Hero Section -->
  <div class="relative bg-linear-to-r from-yellow-900 via-yellow-700 to-yellow-900 pt-16 pb-20 overflow-hidden">
    <div class=" max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8 relative z-10">
      <h1
        class="text-5xl font-extrabold text-white sm:text-6xl drop-shadow-md animate__animated animate__fadeInTopRight">
        ¡Cliente con Beneficios en VSC!
      </h1>
      <p class="mt-6 mb-6 text-justify text-lg text-white max-w-3xl mx-auto animate__animated animate__fadeInTopLeft">
        Convierte tus compras diarias en una fuente de ingresos: desde recargas móviles y pagos de servicios
        hasta adquisicione en nuestro Marketplace
        y comercios aliados.<br><br> Tú y tus amigos pueden aprovechar cada gasto como una oportunidad para
        ganar dinero de manera sencilla e inteligente.
        <span class="text-white font-extrabold">Disfruta, ahorra y gana al mismo tiempo</span> con Virtual
        Shopping Club.
      </p>
      <router-link :to="registroRoute" class="mt-4 inline-block px-3 py-2 bg-white text-yellow-900 font-bold rounded-lg shadow-lg
                                hover:bg-green-500 hover:text-white hover:shadow-xl transition-all duration-300">
        Registrate gratis
      </router-link>
    </div>
  </div>

  <div class="vsc bg-linear-to-r from-gray-500 via-yellow-700 to-gray-500 mt-8 mb-4 pt-6 pb-6">
    <h1 class="text-white text-3xl font-serif font-extrabold text-center">
      "No hay inversión inicial obligatoria, solo con tus compras o gastos puedes iniciar"
    </h1>
  </div>



  <div class="max-w-7xl mx-auto px-6 lg:px-8 pt-14">
    <!-- Nuevo texto agregado -->
    <h2 class="text-3xl font-extrabold text-center text-yellow-900 mb-8">
      Recibe Puntos y Tecnología por tus compras como:
    </h2>

    <section class="grid grid-cols-1 md:grid-cols-2 items-stretch gap-8 items-center mb-16 mt-8">
      <!-- Lugares de produccion -->
      <div
        class="bg-gray-300 shadow-lg rounded-lg p-6 items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
        <div>
          <h2 class="text-2xl font-bold text-yellow-900 mb-2 text-center">Lugares de Producción:</h2>
          <img src="@/assets/img/lugarProduccion.png" alt="Beneficio 1" class="w-20 h-20 object-cover block mx-auto mb-4 mt-4">
          <p class="text-gray-700 text-justify">
            Cada vez que gastas $1,000 pesos en VSC (recargas, pagos o compras), obtienes un <span
              class="font-bold">"Lugar de Producción"</span>, una
            tecnología que te genera ingresos cada vez que otros compran el mismo producto o servicio dentro de la
            comunidad.<br><br>

            Si estás entre los primeros 100 compradores, recibirás una versión digital del producto, servicio o negocio,
            aumentando tus beneficios. Con el tiempo, estas pequeñas ganancias pueden convertirse en ingresos constantes
            e incluso en grandes sumas de dinero.
          </p>
        </div>
      </div>

      <!-- Nodos solidaridad -->
      <div
        class="bg-gray-300 shadow-lg rounded-lg p-6 items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">

        <div>
          <h2 class="text-2xl font-bold text-yellow-900 mb-2 text-center">Nodos de Solidaridad:</h2>
          <img src="@/assets/img/nodos.png" alt="Beneficio 2" class="w-15 h-20 object-cover block mx-auto  mb-4 mt-4">
          <p class="text-gray-700 text-justify">
            Aumenta tus ganancias al comprar repetidamente un producto. Los Nodos de Solidaridad funcionan como
            "bolsitas de dinero" que se activan según tus compras: <br>
            <span class="font-bold">Nodo 1:</span> Con tu primera compra (si estás entre los primeros 100), ya
            participas.<br>
            <span class="font-bold">Nodo 2:</span> Al comprar 5 veces (si estás entre los primeros 50), duplicas tus
            ganancias.<br>
            <span class="font-bold">Nodo 3:</span> Con 25 compras (entre los primeros 50), sigues escalando.<br>
            <span class="font-bold">Nodo 4:</span> Con 50 compras (entre los primeros 20), tus ganancias aumentan
            significativamente.<br>
            <span class="font-bold">Nodo 5:</span> Al llegar a 100 compras (entre los primeros 10), alcanzas el máximo
            nivel de ingresos..<br>
          </p>
        </div>
      </div>
    </section>
  </div>

  <!-- Video de YouTube -->
  <div class="mx-auto max-w-4xl px-6 lg:px-8">
    <div class="aspect-w-16 aspect-h-9">
      <iframe class="absolute rounded-lg  top-0 left-0 w-full h-full" src="https://www.youtube.com/embed/Jqf82di40iI"
        frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
  </div>


  <!-- Beneficios -->
  <div class="grid md:grid-cols-3 gap-6 px-8 py-12">
    <div class="p-6 bg-gray-200 rounded-lg">
      <h2 class="text-2xl font-semibold">💰 Por tus Compras</h2>
      <p class="mt-2">Recibe recompensas desde pesitos hasta $500,000 MXN al año.</p>
    </div>

    <div class="p-6 bg-gray-200 rounded-lg">
      <h2 class="text-2xl font-semibold">💳 Tarjeta Alianza</h2>
      <p class="mt-2">Consíguela en promoción por solo $2,000 MXN en tu primer mes de registro.</p>
    </div>

    <div class="p-6 bg-gray-200 rounded-lg">
      <h2 class="text-2xl font-semibold">📈 Gana hasta $16,000,000 MXN</h2>
      <p class="mt-2">Por referencias efectivas, ya sean amigos o familiares.</p>
    </div>
  </div>

  <!-- Virtual Shopping Club -->
  <div class="vsc bg-linear-to-r from-gray-500 via-yellow-700 to-gray-500 mt-8 mb-4 pt-6 pb-6">
    <h1 class="text-white text-3xl font-serif font-extrabold text-center">
      VSC es una aplicacion que convierte los gastos comunes propios o de conocidos en formas de generar ingresos.
    </h1>
  </div>
  <div class="px-6 mb-4">
    <img src="@/assets/img/servicios-vsc.png" alt="publicidad" class="mx-auto">
  </div>

  <div class="vsc flex flex-col items-center mb-4">
    <h1 class="text-3xl font-extrabold text-center text-gray-500">
      ¡Tu momento es ahora! Únete a Virtual Shopping Club y empieza a <br> vivir la experiencia que todos están
      hablando.
    </h1>
    <router-link :to="registroRoute" class="text-center mt-8 mb-12 inline-block px-6 py-3 bg-yellow-900 text-white font-bold rounded-lg
                            shadow-lg custom-shadow hover:bg-green-500 hover:text-white hover:shadow-xl transition-all
                            duration-300">
      Saber Más
    </router-link>
  </div>

  <div class="vsc bg-linear-to-r from-gray-500 via-yellow-700 to-gray-500 mt-8 mb-4 pt-2 pb-2">
  </div>

  
  <div class="mt-16 px-4 mb-12">
        <h1 class="text-3xl text-center font-extrabold text-yellow-900 mb-8">
            Supermercado Tradicional vs Virtual Shopping Club
        </h1>
        <!-- Cambiamos items-start por items-stretch para forzar que ambos hijos tengan la misma altura -->
        <div class="flex flex-col md:flex-row justify-around items-stretch gap-8">
            <!-- Columna: Supermercado Tradicional -->
            <div class="flex-1 text-center p-6 bg-orange-100 rounded-lg shadow-md">
                <h2 class="text-2xl font-bold text-gray-700 mb-4">Supermercado Tradicional</h2>
                <ul class="list-disc list-inside text-gray-700 text-lg text-left">
                    <li class="flex items-center"><span class="text-xl">❌</span> <span class="ml-2">Con tu
                            dinero.</span></li>
                    <li class="flex items-center"><span class="text-xl">❌</span> <span class="ml-2">Compras.</span></li>
                    <li class="flex items-center"><span class="text-xl">❌</span> <span class="ml-2">Gastas.</span></li>
                </ul>
            </div>
            <!-- Columna: Virtual Shopping Club -->
            <div class="flex-1 text-center p-6 bg-green-100 rounded-lg shadow-md">
                <h2 class="text-2xl font-bold text-green-700 mb-4">Virtual Shopping Club</h2>
                <ul class="list-disc list-inside text-gray-700 text-lg text-left">
                    <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Fondeas tu
                            cuenta.</span></li>
                    <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Compras en la
                            app.</span></li>
                    <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Obtienes puntos y
                            tecnología.</span></li>
                    <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Usas tus puntos para
                            tus compras o gastos.</span></li>
                    <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Obtienes más
                            puntos.</span></li>
                </ul>
            </div>
        </div>
    </div>

  <!-- Sección de Testimonios -->
  <div class="py-16 px-6 bg-gray-200">
    <h3 class="text-center text-4xl font-bold text-yellow-900">📢 Lo Que Dicen Nuestros Miembros Participantes</h3>
    <div class="mt-10 grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">

      <div class="bg-white p-6 rounded-lg shadow-lg flex flex-col">
        <p class="italic text-lg text-gray-800">"Siempre quise ganar dinero extra sin descuidar mi trabajo ni mi
          familia, pero todo requería demasiado tiempo o inversión… hasta que probé Virtual Shopping Club.

          Hice mis compras habituales en la app y vi cómo me generaban beneficios. Luego, mis amigos y familia se
          sumaron, y mis ingresos crecieron sin esfuerzo. Ahora, mis compras me generan dinero cada mes, y lo mejor es
          que ellos también están ganando."</p>
        <p class="mt-2 font-semibold text-right text-yellow-900">— Berenice Peña</p>
      </div>

      <div class="bg-white p-6 rounded-lg shadow-lg flex flex-col">
        <p class="italic text-lg text-gray-800">"En lo personal me encanta esta app, por que ahora gano dinero diario
          por mis compras que hago, y lo mejor que puedo cambiar mis puntos ganados al momento y asi seguir comprando."
        </p>
        <p class="mt-2 font-semibold text-right text-yellow-900">— Martha Balderas</p>
      </div>
    </div>
  </div>


  <!-- Llamada a la acción -->
  <section class="bg-yellow-900 text-white py-16 mt-12 mb-12">
    <div class="max-w-7xl mx-auto px-4 text-center">
      <h2 class="text-5xl text-white  pb-14 font-serif font-bold ">
        ¡No Esperes Más para Ser Parte de Virtual Shopping Club!
      </h2>
      <img src="@/assets/img/vsc.png" alt="publicidad" class="w-40 h-30 mx-auto mb-4">
      <p class="mt-4 text-lg">

      </p>
      <router-link :to="registroRoute" class="mt-8 inline-block px-6 py-3 bg-white text-yellow-900 font-bold text-lg rounded-lg
                     hover:bg-green-600 hover:text-white animated-call-to-action">
        Regístrate Ahora
      </router-link>
    </div>
  </section>



  <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>
</template>


<script>
import patrocinadoresComp from "@/components/campanias/patrocinadores";

export default {
  name: 'Clientes4',
  components: {
    patrocinadoresComp
  },
  props: {
    patrocinadores: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    fbq('init', '1351295932570313')
    fbq('track', 'PageView')
  },
  computed: {
    registroRoute() {
      return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
        '/registro'
    }
  }
}
</script>

<style scoped>
/* Estilo adicional para pantallas pequeñas */
@media (max-width: 568px) {

  .vsc {
    padding: 0.5rem;
  }

  .vsc h1 {
    font-size: 1rem;
  }
}
</style>