<template>
    <div class="min-h-screen ">
        <!-- Hero con efecto neón -->
        <div class="relative bg-linear-to-r from-gray-500 via-yellow-400 to-gray-500 pt-2 pb-6 overflow-hidden">
            <div class="relative flex flex-col items-center text-center py-20">
                <h1 class="text-4xl font-extrabold text-white neon-text animate__animated animate__jackInTheBox">Forma
                    Parte de los <span class="text-6xl">Clientes con Beneficios</span> </h1>
                <p class="mt-9 text-xl px-2 text-gray-800 animate__animated animate__jackInTheBox">Tu futuro financiero
                    empieza aquí. Compra, gana y multiplica tus ingresos con</p>
                <img src="@/assets/img/vsc.png" alt="publicidad"
                    class="w-40 h-30 mx-auto mb-4 animate__animated animate__rotateIn">
                <router-link :to="registroRoute"
                    class="mt-6 px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-green-600">
                    Únete Ahora
                </router-link>
            </div>
        </div>

        <!-- Virtual Shopping Club -->
        <div class="vsc bg-linear-to-r from-gray-400 via-purple-200 to-gray-400 mt-8 mb-4 pt-6 pb-6">
            <h1 class="text-gray-800 text-3xl font-serif font-extrabold text-center">
                VSC es una aplicacion que convierte los gastos comunes propios o de conocidos en formas de generar
                ingresos.
            </h1>
        </div>
        <div class="px-6 mb-12">
            <img src="@/assets/img/servicios-vsc.png" alt="publicidad" class="mx-auto">
        </div>

        <!-- Video de YouTube -->
        <div class="mx-auto max-w-4xl px-6 lg:px-8">
            <div class="aspect-w-16 aspect-h-9">
                <iframe class="absolute rounded-lg  top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/Jqf82di40iI" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>

        <div class="text-3xl text-center font-extrabold text-yellow-500 mt-16">Recibe estos beneficios:</div>
        <section class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 items-start mb-16 mt-6 px-2">
            <!-- Beneficio 1: Gana dinero con cada compra que hagas -->
            <div
                class="bg-gray-200 shadow-lg rounded-lg p-6 flex flex-col items-center gap-4 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
                <img src="https://img.icons8.com/color/96/money-bag.png" alt="Gana dinero"
                    class="w-20 h-20 rounded-full object-cover">
                <h2 class="text-xl font-bold text-yellow-500 text-center">Gana dinero con cada compra que hagas</h2>
                <p class="text-gray-700 text-center">Cada compra te acerca a mayores beneficios y recompensas.</p>
            </div>

            <!-- Beneficio 2: Recibe ingresos por compras de otros miembros -->
            <div
                class="bg-gray-200 shadow-lg rounded-lg p-6 flex flex-col items-center gap-4 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
                <img src="https://img.icons8.com/color/96/coins.png" alt="Recibe ingresos"
                    class="w-20 h-20 rounded-full object-cover">
                <h2 class="text-xl font-bold text-yellow-500 text-center">Recibe ingresos por compras de otros miembros
                </h2>
                <p class="text-gray-700 text-center">Obtén ingresos adicionales cuando otros miembros compren a través
                    de ti.</p>
            </div>

            <!-- Beneficio 3: Accede a descuentos exclusivos -->
            <div
                class="bg-gray-200 shadow-lg rounded-lg p-6 flex flex-col items-center gap-4 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
                <img src="https://img.icons8.com/color/96/discount.png" alt="Descuentos exclusivos"
                    class="w-20 h-20 rounded-full object-cover">
                <h2 class="text-xl font-bold text-yellow-500 text-center">Accede a descuentos exclusivos</h2>
                <p class="text-gray-700 text-center">Disfruta de promociones y precios especiales solo para miembros.
                </p>
            </div>

            <!-- Beneficio 4: Haz crecer tu red y multiplica tus ganancias -->
            <div
                class="bg-gray-200 shadow-lg rounded-lg p-6 flex flex-col items-center gap-4 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
                <img src="https://img.icons8.com/color/96/network.png" alt="Haz crecer tu red"
                    class="w-20 h-20 rounded-full object-cover">
                <h2 class="text-xl font-bold text-yellow-500 text-center">Haz crecer tu red y multiplica tus ganancias
                </h2>
                <p class="text-gray-700 text-center">Expande tu red de contactos y transforma conexiones en ingresos.
                </p>
            </div>
        </section>



        <div class="bono mt-16">
            <h2 class="text-3xl text-center font-extrabold text-yellow-500 mb-6">
                Más Formas de Beneficiarte con:
            </h2>
            <!-- Tarjeta VSC -->
            <div
                class="tarjetavsc max-w-4xl mx-auto text-center mb-14 bg-gray-200 shadow-lg rounded-lg p-6 flex flex-col sm:flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
                <!-- Imagen -->
                <img src="@/assets/img/tarjetavsc.png" alt="Tarjeta VSC"
                    class="tarjetavsc-img max-h-40 object-cover mx-auto sm:mx-0">

                <!-- Contenido -->
                <div class="text-center sm:text-left">
                    <h2 class="text-2xl font-extrabold text-gray-700 mb-4 sm:mb-2">
                        Tarjeta Alianza VSC
                    </h2>

                    <p class="text-gray-700 text-justify">
                        Multiplica tus ganancias y obtén 3 beneficios adicionales con las insignias:<br>
                        <span class="font-bold">V:</span> Bono por la venta de la Tarjeta Alianza<br>
                        <span class="font-bold">S:</span> Bono por compras en Negocios Gestionados<br>
                        <span class="font-bold">C:</span> Bono por ventas de Negocios Patrocinadores
                    </p>

                    <!-- Sección de precios con figura geométrica y animación -->
                    <div class="mt-6 flex justify-center">
                        <div class="w-60 h-36 bg-gradient-to-r from-green-400 to-green-600 rounded-lg relative animate-pulse"
                            style="clip-path: polygon(0 20%, 50% 0, 100% 20%, 100% 80%, 50% 100%, 0 80%);">
                            <div class="absolute inset-0 flex flex-col items-center justify-center">
                                <p class="text-lg text-white">
                                    Precio Real: <span class="line-through text-red-500">$6,000</span>
                                </p>
                                <p class="text-2xl font-bold text-white">
                                    Promoción: $2,000
                                </p>
                                <p class="text-lg font-bold text-white">
                                    + $1,000 en saldo
                                </p>
                                <p class="text-sm text-white">primer mes de registro</p>
                            </div>
                        </div>
                    </div>
                    <!-- Fin de la sección de precios -->

                </div>
            </div>
        </div>

        <div class="mt-16 px-4 mb-12">
            <h1 class="text-3xl text-center font-extrabold text-gray-800 mb-8">
                Beneficios Con:
            </h1>
            <!-- Cambiamos items-start por items-stretch para forzar que ambos hijos tengan la misma altura -->
            <div class="flex flex-col md:flex-row justify-around items-stretch gap-8">
                <!-- Columna: Supermercado Tradicional -->
                <div class="flex-1 text-center p-6 bg-orange-100 rounded-lg shadow-md">
                    <h2 class="text-2xl font-bold text-gray-700 mb-4">Supermercado Tradicional</h2>
                    <ul class="list-disc list-inside text-gray-700 text-lg text-left">
                        <li class="flex items-center"><span class="text-xl">❌</span> <span class="ml-2">Producto /
                                Servicio.</span></li>
                        <li class="flex items-center"><span class="text-xl">❌</span> <span class="ml-2">Puntos.</span>
                        </li>
                    </ul>
                </div>
                <!-- Columna: Virtual Shopping Club -->
                <div class="flex-1 text-center p-6 bg-green-100 rounded-lg shadow-md">
                    <h2 class="text-2xl font-bold text-green-700 mb-4">Virtual Shopping Club</h2>
                    <ul class="list-disc list-inside text-gray-700 text-lg text-left">
                        <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Productos /
                                Servicios.</span></li>
                        <li class="flex items-center"><span class="text-xl">✅</span> <span
                                class="ml-2">Tecnologia.</span></li>
                        <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Puntos por
                                compras.</span></li>
                        <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Formas parte de
                                una comunidad</span></li>
                    </ul>
                </div>
            </div>
        </div>


        <div class="bono bg-linear-to-r from-gray-500 via-blue-200 to-gray-500  mb-14 pt-6 pb-6">
            <h1 class="text-gray-800 text-3xl font-serif font-extrabold text-center">
                Bono de referencias efectivas de $1.82 a más de $16,000,000
            </h1>
        </div>

        <!-- Sección de Testimonios -->
  <div class="py-16 px-6 bg-gray-200">
    <h3 class="text-center text-4xl font-bold text-yellow-500">📢 Es 100% confiable y sin trucos, ¿no nos crees? mira lo que dicen nuestro miembros</h3>
    <div class="mt-10 grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">

      <div class="bg-white p-6 rounded-lg shadow-lg flex flex-col">
        <p class="italic text-lg text-gray-800">"Muy buena aplicación, me gusta es muy practica y lo mejor es que tan solo
            comprando lo que necesito genero puntos y son canjeables."</p>
        <p class="mt-2 font-semibold text-right text-yellow-900">— Elia Narciso</p>
      </div>

      <div class="bg-white p-6 rounded-lg shadow-lg flex flex-col">
        <p class="italic text-lg text-gray-800">"En lo personal me encanta esta app, por que ahora gano dinero diario
          por mis compras que hago, y lo mejor que puedo cambiar mis puntos ganados al momento y asi seguir comprando."
        </p>
        <p class="mt-2 font-semibold text-right text-yellow-900">— Martha Balderas</p>
      </div>
    </div>
  </div>

        <!-- Sección: Preguntas Frecuentes -->
        <div class="px-8">
            <section class="bg-gray-100 rounded-lg p-8 shadow-lg mt-14 mb-16">
                <h2 class="text-3xl text-gray-700 font-semibold text-center">Preguntas Frecuentes</h2>
                <div class="mt-8">
                    <details class="mb-4 px-8">
                        <summary class="text-gray-700 font-semibold cursor-pointer bg-gray-200 px-4 py-2 rounded-lg">
                            ¿Cómo me registro en Virtual Shopping Club?
                        </summary>
                        <p class="mt-2 px-4 text-gray-700 text-justify">
                            El registro es completamente gratuito y sencillo. Solo debes hacer clic en los botones
                            “Regístrate gratis”
                            o “Saber Más” que se encuentran a lo largo de la página, y seguir el proceso indicado.
                        </p>
                    </details>
                    <details class="mb-4 px-8">
                        <summary class="text-gray-700 font-semibold cursor-pointer bg-gray-200 px-4 py-2 rounded-lg">
                            ¿Cuáles son los beneficios de unirme?
                        </summary>
                        <p class="mt-2 px-4 text-gray-700 text-justify">
                            Al ser parte de la comunidad, podrás recibir recompensas que van desde puntos acumulables y
                            tecnología gratuita
                            hasta bonos especiales con montos que pueden ir desde pequeños beneficios hasta recompensas
                            de
                            hasta $500,000. Además,
                            si adquieres la Tarjeta Alianza VSC, multiplicarás tus ganancias con beneficios adicionales
                            por
                            ventas, compras y referidos.
                        </p>
                    </details>
                    <details class="mb-4 px-8">
                        <summary class="text-gray-700 font-semibold cursor-pointer bg-gray-200 px-4 py-2 rounded-lg">
                            ¿Qué es la aplicación VSC y cómo me beneficia?
                        </summary>
                        <p class="mt-2 px-4 text-gray-700 text-justify">
                            VSC es una herramienta innovadora que transforma tus gastos cotidianos en oportunidades de
                            ingresos. Con
                            esta aplicación, podrás convertir compras
                            y gastos en formas de generar ganancias, haciendo que cada gasto sea una inversión en tu
                            futuro.
                        </p>
                    </details>
                    <details class="mb-4 px-8">
                        <summary class="text-gray-700 font-semibold cursor-pointer bg-gray-200 px-4 py-2 rounded-lg">
                            ¿Cómo puedo obtener más información o contactar al equipo de soporte?
                        </summary>
                        <p class="mt-2 px-4 text-gray-700 text-justify">
                            Si tienes dudas o necesitas asistencia, puedes comunicarte con nosotros a través de nuestro
                            formulario de
                            contacto o por nuestros canales
                            oficiales de atención. ¡Estamos aquí para ayudarte en cada paso!
                        </p>
                    </details>
                </div>
            </section>
        </div>

        <!-- Llamada a la acción -->
        <div class="bg-yellow-500 py-16 relative z-20 mb-6">
            <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                <h2 class="text-4xl font-extrabold text-white ">
                    ¡No Esperes Más para Ser Parte de Virtual Shopping Club!
                </h2>
                <p class="mt-4 text-lg text-purple-100 max-w-2xl mx-auto">
                    Regístrate ahora y comienza a disfrutar de todos los beneficios exclusivos que tenemos para ti.
                </p>
                <router-link :to="registroRoute" class="mt-8 inline-block px-6 py-3 bg-white text-gray-800 font-bold rounded-lg shadow-lg
                                    custom-shadow hover:bg-green-500 hover:text-white hover:shadow-xl transition-all
                                    duration-300 animate-bounce">
                    Regístrate Ahora
                </router-link>
            </div>
        </div>

        <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>
    </div>
</template>

<script>
import patrocinadoresComp from "@/components/campanias/patrocinadores"

export default {
    name: 'Clientes3',
    components: {
        patrocinadoresComp
    },
    props: {
        patrocinadores: {
            type: Array,
            default: () => []
        }
    },
    mounted() {
      fbq('init', '1351295932570313')
      fbq('track', 'PageView')
    },
    computed: {
        registroRoute() {
            return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
                '/registro'
        }
    }
}
</script>

<style scoped>
.neon-text {
    text-shadow: 0 0 10px #00c3ff, 0 0 20px #00c3ff, 0 0 30px #00c3ff;
}


/* Estilo adicional para pantallas pequeñas */
@media (max-width: 568px) {

    .tarjetavsc {
        flex-direction: column;
        /* Cambiar el diseño a columna */
    }

    .tarjetavsc-img {
        width: 10rem;
        /* Reducir tamaño de la imagen */
        height: 6rem;
        margin-bottom: 0.1rem;
        /* Añadir espacio inferior entre la imagen y el contenido */
    }

    .tarjetavsc h2 {
        margin-bottom: 0.5rem;
        /* Reducir margen inferior del título */
    }

    .vsc {
        padding: 0.5rem;
    }

    .vsc h1 {
        font-size: 1rem;
    }

    .bono {
        padding: 0.5rem
    }

    .bono h1 {
        font-size: 1.2rem;
        ;
    }
}
</style>