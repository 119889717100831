<template>
  <div class="bg-blue-100 min-h-screen text-gray-800">
    <!-- Encabezado -->
    <header class="bg-gradient-to-r from-blue-500 via-cyan-400 to-blue-600 text-white py-6">
      <div class="pt-16 pb-16 text-center">
        <h1
          class="text-5xl text-gray-800 font-serif font-extrabold animated-header animate__animated animate__fadeInUp">
          ¡Patrocinador de Tecnologia <span class="text-white text-4xl">en Virtual Shopping Club!</span>
        </h1>
        <p class="mt-6 text-lg animate__animated animate__fadeInUp animate__slower">
          ¡Impulsa tus ingresos como patrocinador! 💼✨ Accede a beneficios exclusivos y amplía tus oportunidades
          financieras. 🚀 Sé parte de nuestra comunidad de patrocinadores y deja tu huella. ¡Únete hoy!
        </p>

        <router-link :to="registroRoute"
          class="mt-12 inline-block px-9 py-3 text-gray-800 font-bold rounded-lg transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.8)]">
          ¡Únete Ahora!
        </router-link>
      </div>
    </header>


    <!-- Beneficios -->
    <section class="max-w-7xl mx-auto px-4 py-10">
      <h2 class="text-3xl font-serif font-bold text-blue-700 text-center">¿Por qué ser patrocinador?</h2>
      <p class="mt-4 text-center text-justify text-lg text-blue-600">
        Como patrocinador de nuestra tecnología, accedes a herramientas exclusivas que potenciarán tus
        ingresos y expandirán tu impacto en la economía digital. Serás parte de la transformación del
        comercio y la conexión en el mundo virtual, liderando el futuro del Virtual Shopping Club.
      </p>
    </section>

    <!-- Video de YouTube -->
    <div class="mx-auto max-w-4xl px-6 lg:px-8">
      <div class="aspect-w-16 aspect-h-9">
        <iframe class="absolute rounded-lg  top-0 left-0 w-full h-full" src="https://www.youtube.com/embed/f1SxM7npTNY"
          frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>

    <!-- Beneficios para patrocinadores -->
    <section class="bg-gradient-to-r from-blue-600 via-blue-500 to-blue-700 mt-12 py-12 px-2">
      <div class="max-w-4xl mx-auto text-center">
        <h2 class="text-4xl font-serif font-extrabold text-white">Beneficios para nuestros Patrocinadores</h2>
        <p class="text-lg text-white mb-8">Reciben tecnología que les estará generando beneficios que pueden utilizar
          para:</p>
        <ul class="grid grid-cols-1 md:grid-cols-2 gap-6 text-left text-white">
          <li class="bg-blue-800 p-4 rounded-lg shadow-lg hover:-translate-y-1 transition duration-300">Recargas
            Telefonicas</li>
          <li class="bg-blue-800 p-4 rounded-lg shadow-lg hover:-translate-y-1 transition duration-300">Pagos de
            Servicios</li>
          <li class="bg-blue-800 p-4 rounded-lg shadow-lg hover:-translate-y-1 transition duration-300">Compras en el
            Marketplace</li>
          <li class="bg-blue-800 p-4 rounded-lg shadow-lg hover:-translate-y-1 transition duration-300">Compras en
            Negocios Afiliados</li>
          <li class="bg-blue-800 p-4 rounded-lg shadow-lg hover:-translate-y-1 transition duration-300">Marketing
            Digital</li>
          <li class="bg-blue-800 p-4 rounded-lg shadow-lg hover:-translate-y-1 transition duration-300">Publicidad</li>
          <li class="bg-blue-800 p-4 rounded-lg shadow-lg hover:-translate-y-1 transition duration-300">Pagos de
            Membresia</li>
          <li class="bg-blue-800 p-4 rounded-lg shadow-lg hover:-translate-y-1 transition duration-300">Patrocinios</li>
          <li class="bg-blue-800 p-4 rounded-lg shadow-lg hover:-translate-y-1 transition duration-300">Recursos para
            mas Beneficios</li>
        </ul>
      </div>
    </section>

    <section class="mt-16">
      <h2 class="text-4xl font-serif font-bold text-blue-700 text-center">
        Formas de ser Patrocinador
      </h2>
    </section>
    <!-- Tres formas de patrocinio -->
    <section class="max-w-7xl mx-auto px-4 py-10 grid grid-cols-1 md:grid-cols-3 gap-8">
      <!-- Gemas Publicitarias -->
      <div
        class="bg-white border-4 border-blue-400 rounded-lg shadow-lg hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
        <div class="p-6">

          <h3 class="text-center font-serif text-2xl font-bold text-blue-600">Gemas Publicitarias</h3>
          <img src="@/assets/img/members/Insignia_Gestor1.png" alt="publicidad" class="w-24 h-24 mx-auto ">

          <p class="text-blue-900 text-justify">
            Con tu gema publicitaria, al asignarla a una de las 100 categorías de tu elección, ganarás automáticamente
            un porcentaje de las ventas generadas
            por todos los productos de esa categoría. Puedes elegir entre categorías de alta demanda como tecnología,
            moda, deportes, hogar y más.
          </p>
        </div>
      </div>

      <!-- Paquete Vitalicio -->
      <div
        class="bg-white border-4 border-orange-400 rounded-lg shadow-lg hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
        <div class="p-6">

          <h3 class="text-center font-serif text-2xl font-bold text-orange-600">Paquete Vitalicio</h3>
          <img src="@/assets/img/members/Insignia_Gestor.png" alt="publicidad" class="w-20 h-20 mx-auto mb-4">

          <p class="mt-4 text-orange-600 text-justify">
            Convertirse en un Miembro Vitalicio, te da acceso a una amplia gama de beneficios diseñados para maximizar
            tus ganancias y tu impacto en nuestra
            comunidad tecnológica. Al ser parte de este selecto grupo, disfrutarás de: 1 Gema Génesis: Participa en el
            bono anual de miembros Génesis, que otorga
            el 5% de las utilidades de la empresa entre sus portadores. 1 Gema de Proyecto: Gana desde $500,000 al año
            con las ventas de una de las 100 categorías
            disponibles al elegir dónde colocar tu gema y lo mejor es que tendrás ganancias ilimitadas.
          </p>
        </div>
      </div>

      <!-- Programa Especial F -->
      <div
        class="bg-white border-4 border-green-500 rounded-lg shadow-lg hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
        <div class="p-6">

          <h3 class="text-center font-serif text-2xl font-bold text-green-600">Programa Especial F3</h3>
          <img src="@/assets/img/F3.png" alt="publicidad" class="w-14 h-14 mx-auto mt-4 mb-4">

          <p class="mt-4 text-green-700 text-justify">
            Con el paquete de Patrocinio F3, te conviertes en patrocinador de tecnología y obtienes 2 participaciones
            permanentes.
            Estas te permiten recibir un bono mensual basado en el número de transacciones de ventas en establecimientos
            por zona
            ¡Apoya nuestra aplicación y disfruta de beneficios cada mes sin
            complicaciones!
          </p>
        </div>
      </div>
    </section>

    <div class="mt-12 mb-6">
      <h2 class="text-3xl font-extrabold text-center animated-gradient">
        "Recompensas desde Pesos a bonos Ilimitados"
      </h2>
    </div>

    <!-- Llamada a la acción -->
    <section class="bg-blue-500 text-white py-16 mt-12">
      <div class="max-w-7xl mx-auto text-center">
        <h2 class="text-5xl font-serif font-bold">¡Regístrate Ahora y Transforma tu Futuro!</h2>
        <p class="mt-4 text-lg">No te quedes atrás, participa en el crecimiento de nuestra tecnología y lleva tu negocio
          al siguiente nivel.</p>

        <router-link :to="registroRoute"
          class="text-center mt-8 mb-8 inline-block px-7 py-3 bg-white text-blue-700 font-bold rounded-lg shadow-lg custom-shadow hover:bg-green-500 hover:text-white hover:shadow-xl transition-all duration-300">
          ¡Quiero ser patrocinador!
        </router-link>

      </div>
    </section>

    <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>

  </div>
</template>



<script>
import patrocinadoresComp from "@/components/campanias/patrocinadores";


export default {
  name: 'patrocinadorTecnologia2',
  components: {
    patrocinadoresComp
  },
  props: {
    patrocinadores: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    fbq('init', '1351295932570313')
    fbq('track', 'PageView')
  },
  computed: {
    registroRoute() {
      return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
        '/registro'
    }
  }
}

</script>

<style scoped>
.animated-gradient {
  animation: pulse 2.5s infinite;
  font-size: 2rem;
  /* Ajusta el tamaño */
  font-weight: 800;
  /* Fuente extrabold */
  text-align: center;
  /* Centrar texto */
  background: linear-gradient(90deg, #16f1c2, #1726d5, #16f1c2);
  /* Gradiente de 3 colores */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  /* El gradiente aparecerá en lugar del color sólido */
  animation: gradient-animation 3s infinite;
  /* Añade la animación */
}

@keyframes gradient-animation {

  0%,
  100% {
    background-position: 0% 50%;
    transform: scale(0.8);
  }

  50% {
    background-position: 100% 50%;
    transform: scale(0.9);
  }
}

@keyframes gradientChange {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.gradient-bg {
  background: linear-gradient(45deg, #0073ff, #d9d9d9, #2174da, #ffffff);
  background-size: 400% 400%;
  animation: gradientChange 4s ease infinite;
}
</style>