<template>
  <div class="bg-gray-900 min-h-screen pt-8 pb-12 px-6">
    <!-- Título principal -->
    <header class="relative py-16 px-6 text-center rounded-lg bg-cover bg-center md:bg-center "
      :style="{ backgroundImage: `url(${require('@/assets/img/members/bannerExpansion.png')})` }">
      <div class="text-center mx-auto px-4">
        <img src="@/assets/img/vsc.png" alt="publicidad" class="w-40 h-30 mx-auto">
        <h1 class="text-white text-4xl pb-16 pt-12 font-extrabold animate__animated animate__zoomInDown">
          Unete a la comunidad de <span class="text-5xl text-yellow-400">¡Miembros Expansión!</span>
        </h1>
        <p class="text-lg text-justify text-white leading-relaxed animate__animated animate__zoomInDown animate__slow">
          ¿Te apasionan las ventas y el desarrollo de redes? 🌟 ¡Esta es tu oportunidad! Únete a nuestro exclusivo
          equipo de <span class="font-bold">Miembros Expansión</span>, donde tendrás acceso a <span class="font-bold">7
            formas diferentes de generar ingresos</span>. Desde pequeñas ganancias hasta ingresos que pueden transformar
          tu vida. 💼✨
          Forma parte de una comunidad que impulsa tu éxito mientras construyes tu red, amplías tus habilidades y
          alcanzas tus metas financieras. 🚀 ¡El límite lo pones tú!
        </p>
        <router-link :to="registroRoute"
          class="mt-12 font-serif  inline-block px-9 py-3 text-gray-800 font-bold rounded-lg transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.8)]">
          ¡Postúlate ahora!
        </router-link>

      </div>
    </header>

    <!-- Sección de roles -->
    <section class="grid grid-cols-1 md:grid-cols-2 gap-8 items-center mb-16 mt-32">
      <!-- Publicista -->
      <div
        class="bg-gray-800 shadow-lg rounded-lg p-6 flex flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
        <img src="@/assets/img/members/publicista.png" alt="Publicista" class="w-20 h-20 rounded-full object-cover">
        <div>
          <h2 class="text-2xl font-bold text-yellow-400 mb-2">Publicista</h2>
          <p class="text-gray-300 text-justify">
            Los publicistas son el motor detrás de la promoción de nuestras marcas y servicios. Conéctate con un público
            amplio y gana recompensas exclusivas.
          </p>
        </div>
      </div>

      <!-- Desarrollador de Red -->
      <div
        class="bg-gray-800 shadow-lg rounded-lg p-6 flex flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
        <img src="@/assets/img/members/desarrolladorRed.png" alt="Desarrollador de Red"
          class="w-20 h-20 rounded-full object-cover">
        <div>
          <h2 class="text-2xl font-bold text-yellow-400 mb-2">Desarrollador de Red</h2>
          <p class="text-gray-300 text-justify">
            Construye y lidera tu propio equipo. Como desarrollador de red, tendrás el control de tu gestoría y
            alcanzarás metas rápidamente.
          </p>
        </div>
      </div>
    </section>

    <!-- Insignia Gestor -->
    <div
      class="insignia-gestor bg-gray-800 shadow-lg rounded-lg p-6 flex flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
      <img src="@/assets/img/members/InsigniaGestor.png" alt="Insignia Gestor"
        class="insignia-img w-40 h-40 rounded-full object-cover">
      <div>
        <h2 class="text-2xl font-bold text-yellow-400 mb-2">Insignia Gestor</h2>
        <p class="text-gray-300 text-justify">
          Sé el líder de tu propia red de emprendedores. Activa tu insignia de Gestor vendiendo paquetes como la <span
            class="font-bold">Tarjeta Alianza VSC</span>, el <span class="font-bold">Paquete Expansión</span> y la <span
            class="font-bold">Membresía de Negocios</span>. ¡Conecta, lidera y multiplica tus ingresos!
        </p>
      </div>
    </div>

    <!-- Beneficios del Programa Especial R -->
    <section class="bg-gray-800 rounded-lg p-8 shadow-lg mt-14 mb-16">
      <h2 class="text-3xl font-bold text-yellow-400 mb-8 text-center">Beneficios del Programa Especial R</h2>
      <h3 class="text-2xl font-bold text-yellow-300 mb-8 text-center">Obtén bonos del fondo de utilidades de VSC, de un
        10%-30%</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
        <!-- R1 -->
        <div
          class="bg-gray-700 rounded-lg p-6 text-center shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300">
          <img src="@/assets/img/members/r-1.png" alt="Beneficio R1" class="w-16 h-16 mx-auto mb-4">
          <p class="text-gray-300 text-justify">
            Aplica $1,000 o más en compras de recargas y marketplace al mes para recibir tu bono.
          </p>
        </div>
        <!-- R2 -->
        <div
          class="bg-gray-700 rounded-lg p-6 text-center shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300">
          <img src="@/assets/img/members/r-2.png" alt="Beneficio R2" class="w-16 h-16 mx-auto mb-4">
          <p class="text-gray-300 text-justify">
            Aplica $1,000 o más en compras en establecimientos o 4 pagos de servicios digitales al mes y obtén tu bono.
          </p>
        </div>
        <!-- R3 -->
        <div
          class="bg-gray-700 rounded-lg p-6 text-center shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300">
          <img src="@/assets/img/members/r-3.png" alt="Beneficio R3" class="w-16 h-16 mx-auto mb-4">
          <p class="text-gray-300 text-justify">
            Para recibir tu bono, invita a amigos o conocidos a multiplicar sus ganancias con nuestros paquetes.
          </p>
        </div>
      </div>
    </section>

    <!-- Sección: Testimonios -->
    <section class="bg-gray-800 rounded-lg p-8 shadow-lg mt-14 mb-16">
      <h2 class="text-3xl font-bold text-yellow-400 mb-8 text-center">Testimonios</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <!-- Testimonio 1 -->
        <div class="p-6 border-l-4 border-yellow-500 bg-gray-700 rounded-lg">
          <p class="text-gray-300 italic">
            "Desde que me uní a Miembros Expansión, mi vida cambió. ¡He duplicado mis ingresos y aprendido muchísimo!"
          </p>
          <p class="mt-4 text-yellow-400 font-bold">- Ana López</p>
        </div>
        <!-- Testimonio 2 -->
        <div class="p-6 border-l-4 border-yellow-500 bg-gray-700 rounded-lg">
          <p class="text-gray-300 italic">
            "La comunidad y el soporte son invaluables. Recomiendo este programa a todos mis conocidos."
          </p>
          <p class="mt-4 text-yellow-400 font-bold">- Carlos Méndez</p>
        </div>
      </div>
    </section>

    <!-- Sección: Preguntas Frecuentes -->
    <section class="bg-gray-800 rounded-lg p-8 shadow-lg mt-14 mb-16">
      <h2 class="text-3xl text-yellow-400 font-semibold text-center">Preguntas Frecuentes</h2>
      <div class="mt-8">
        <details class="mb-4 px-8">
          <summary class="text-gray-700 font-semibold cursor-pointer bg-gray-200 px-4 py-2 rounded-lg">
            ¿Qué es ser un Miembro Expansión?
          </summary>
          <p class="mt-2 px-4 text-gray-300 text-justify">
            Ser un Miembro Expansión significa formar parte de un equipo exclusivo donde podrás aprovechar 6 diferentes
            formas de
            generar ingresos. Es la oportunidad de potenciar tus habilidades en ventas y desarrollo de redes

          </p>
        </details>
        <details class="mb-4 px-8">
          <summary class="text-gray-700 font-semibold cursor-pointer bg-gray-200 px-4 py-2 rounded-lg">
            ¿Cuáles son los beneficios de unirme?
          </summary>
          <div class="mt-2 px-4 text-gray-300 text-justify">
            Al registrarte, obtendrás beneficios como:
            <ul class="list-disc list-inside text-gray-300">
              <li>Acceso a múltiples estrategias de ingresos (ventas directas, desarrollo de red, bonos por productividad,
                entre otros).</li>
              <li>La posibilidad de crecer profesionalmente y liderar equipos.</li>
              <li>Recompensas económicas que van desde pequeñas ganancias hasta ingresos que pueden transformar tu futuro
                financiero.</li>
            </ul>
          </div>
        </details>
        <details class="mb-4 px-8">
          <summary class="text-gray-700 font-semibold cursor-pointer bg-gray-200 px-4 py-2 rounded-lg">
            ¿Qué es la aplicación VSC y cómo me beneficia?
          </summary>
          <p class="mt-2 px-4 text-gray-300 text-justify">
            VSC es una herramienta innovadora que transforma tus gastos cotidianos en oportunidades de ingresos. Con
            esta aplicación, podrás convertir compras
            y gastos en formas de generar ganancias, haciendo que cada gasto sea una inversión en tu futuro.
          </p>
        </details>
        <details class="mb-4 px-8">
          <summary class="text-gray-700 font-semibold cursor-pointer bg-gray-200 px-4 py-2 rounded-lg">
            ¿Cómo puedo obtener más información o contactar al equipo de soporte?
          </summary>
          <p class="mt-2 px-4 text-gray-300 text-justify">
            Si tienes dudas o necesitas asistencia, puedes comunicarte con nosotros a través de nuestro formulario de
            contacto o por nuestros canales
            oficiales de atención. ¡Estamos aquí para ayudarte en cada paso!
          </p>
        </details>
      </div>
    </section>


    <!-- Llamado a la acción -->
    <section class="text-center">
      <h2 class="text-3xl font-bold text-yellow-400 mb-4 pt-10">
        ¡Únete a Nuestra Comunidad de Miembros Expansión Hoy!
      </h2>
      <img src="@/assets/img/vsc.png" alt="publicidad" class="w-40 h-30 mx-auto mb-4">
      <p class="text-gray-300 mb-6">
        Regístrate y lleva tus habilidades de liderazgo al siguiente nivel.
      </p>
      <router-link :to="registroRoute"
        class="mt-6 inline-block px-6 py-3 bg-yellow-500 text-gray-900 font-bold rounded-full hover:bg-yellow-600 transition-all duration-300">
        ¡Empieza hoy mismo!
      </router-link>
    </section>

    <!-- Virtual Shopping Club -->
    <div class="vsc rounded-lg bg-gradient-to-r from-gray-800 via-gray-600 to-gray-800 mt-12 mb-4 pt-6 pb-6">
      <h1 class="text-gray-100 text-3xl font-extrabold text-center">
        VSC es una aplicación que convierte gastos cotidianos en oportunidades de ingreso.
      </h1>
    </div>
    <div class="px-6 mb-4">
      <img src="@/assets/img/servicios-vsc.png" alt="publicidad" class="mx-auto">
    </div>

    <div class="vsc flex flex-col items-center mb-4">
      <h1 class="text-3xl font-extrabold text-gray-300 text-center">
        ¡No esperes más! Da el primer click hacia un futuro lleno de posibilidades.
      </h1>
      <router-link :to="registroRoute"
        class="mt-6 inline-block px-6 py-3 bg-yellow-500 text-gray-900 font-bold rounded-lg hover:bg-yellow-600 transition-all duration-300">
        ¡Regístrate Ahora!
      </router-link>
    </div>

    <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>
  </div>
</template>

<script>
import patrocinadoresComp from "@/components/campanias/patrocinadores"

export default {
  name: 'miembroExpansion',
  components: {
    patrocinadoresComp
  },
  props: {
    patrocinadores: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    fbq('init', '1351295932570313')
    fbq('track', 'PageView')
  },
  computed: {
    registroRoute() {
      return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
        '/registro'
    }
  }
}
</script>

<style scoped>
/* Animación de pulso para elementos */
.animated-pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
    color: #facc15;
    /* Amarillo */
  }

  50% {
    transform: scale(1.1);
    color: #fbbf24;
    /* Amarillo brillante */
  }
}

/* Animación para botones al hacer hover */
button:hover,
a:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

/* Estilo base para la sección Insignia */
.insignia-gestor {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
}

.insignia-img {
  width: 10rem;
  height: 10rem;
}

/* Media query para pantallas pequeñas */
@media (max-width: 568px) {
  .insignia-gestor {
    flex-direction: column;
    text-align: center;
  }

  .insignia-img {
    width: 8rem;
    height: 8rem;
    margin-bottom: 0.1rem;
  }

  .insignia-gestor h2 {
    margin-top: 0.1rem;
  }

  .vsc {
    padding: 0.5rem;
  }

  .vsc h1 {
    font-size: 1rem;
  }
}

@keyframes gradientChange {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.gradient-bg {
  background: linear-gradient(45deg, #ffffff, #333443, #ffffff);
  background-size: 400% 400%;
  animation: gradientChange 4s ease infinite;
}
</style>