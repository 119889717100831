import { createRouter, createWebHistory } from 'vue-router'

//clientes
import Clientes from '../views/pages/clientes/clientes.vue'
import Clientes2 from '../views/pages/clientes/clientes2.vue'
import Clientes3 from '../views/pages/clientes/clientes3.vue'
import Clientes4 from '../views/pages/clientes/clientes4.vue'

//club de negocios
import ClubNegocios from '../views/pages/negocios/clubnegocios.vue'
import ClubNegocios2 from '../views/pages/negocios/clubnegocios2.vue'
import ClubNegocios3 from '../views/pages/negocios/clubnegocios3.vue'
import ClubNegocios4 from '../views/pages/negocios/clubnegocios4.vue'

//patrocinador tecnologia
import patrocinadorTecnologia from '@/views/pages/patrocinadores/patrocinadorTecnologia.vue'
import patrocinadorTecnologia2 from '@/views/pages/patrocinadores/patrocinadorTecnologia2.vue'
import patrocinadorTecnologia3 from '@/views/pages/patrocinadores/patrocinadorTecnologia3.vue'
import patrocinadorTecnologia4 from '@/views/pages/patrocinadores/patrocinadorTecnologia4.vue'



//miembros expansion
import miembroExpansion from '@/views/pages/expansion/expansion.vue'
import miembroExpansion2 from '@/views/pages/expansion/expansion2.vue'
import miembroExpansion3 from '@/views/pages/expansion/expansion3.vue'
import miembroExpansion4 from '@/views/pages/expansion/expansion4.vue'

//Loi-Master
import loiMaster from '@/views/pages/loi-master/loi-master.vue'
import loiMaster2 from '@/views/pages/loi-master/loi-master2.vue'
import loiMaster3 from '@/views/pages/loi-master/loi-master3.vue'
import loiMaster4 from '@/views/pages/loi-master/loi-master4.vue'

//campañas
import consultorio from '@/views/pages/campanias/consultorio.vue'


const routes = [
  {
    path: '/:noPatrocinador(\\d+)?',
    name: 'home',
    component: () => import("../views/Index")
  },
  {
    path: '/registro/campania/:codigo',
    name: 'registroCampania',
    component: () => import("../views/Registro")
  },
  {
    path: '/registro/:noPatrocinador(\\d+)?',
    name: 'registro',
    component: () => import("../views/Registro")
  },
  {
    path: '/QR',
    name: 'QR',
    component: () => import("../views/QR")
  },
  {
    path: '/help/:slug',
    name: 'help',
    component: () => import("../views/Help")
  },

  // Nuevas rutas para los detalles de los miembros
  //clientes
  {
    path: '/clientes',
    name: 'clientes',
    component: Clientes, // Aquí carga el componente correspondiente
  },
  {
    path: '/clientes2',
    name: 'clientes2',
    component: Clientes2, // Aquí carga el componente correspondiente
  },
  {
    path: '/clientes3',
    name: 'clientes3',
    component: Clientes3, // Aquí carga el componente correspondiente
  },
  {
    path: '/clientes4',
    name: 'clientes4',
    component: Clientes4, // Aquí carga el componente correspondiente
  },
  

  //club de negocios
  {
    path:'/club-negocios',
    name: 'club-negocios',
    component: ClubNegocios,
  },
  {
    path:'/club-negocios2',
    name: 'club-negocios2',
    component: ClubNegocios2,
  },
  {
    path:'/club-negocios3',
    name: 'club-negocios3',
    component: ClubNegocios3,
  },
  {
    path:'/club-negocios4',
    name: 'club-negocios4',
    component: ClubNegocios4,
  },

  //patrocinadores de tecnologia
  {
    path:'/patrocinador-tecnologia',
    name: 'patrocinador-tecnologia',
    component: patrocinadorTecnologia,
  },
  {
    path:'/patrocinador-tecnologia2',
    name: 'patrocinador-tecnologia2',
    component: patrocinadorTecnologia2,
  },
  {
    path:'/patrocinador-tecnologia3',
    name: 'patrocinador-tecnologia3',
    component: patrocinadorTecnologia3,
  },
  {
    path:'/patrocinador-tecnologia4',
    name: 'patrocinador-tecnologia4',
    component: patrocinadorTecnologia4,
  },
 

  //miembros expansion 
  {
    path:'/miembro-expansion',
    name: 'miembro-expansion',
    component: miembroExpansion,
  },
  {
    path:'/miembro-expansion2',
    name: 'miembro-expansion2',
    component: miembroExpansion2,
  },
  {
    path:'/miembro-expansion3',
    name: 'miembro-expansion3',
    component: miembroExpansion3,
  },
  {
    path:'/miembro-expansion4',
    name: 'miembro-expansion4',
    component: miembroExpansion4,
  },

  //loi-master
  {
    path:'/loi-master',
    name: 'loi-master',
    component: loiMaster,
  },
  {
    path:'/loi-master2',
    name: 'loi-master2',
    component: loiMaster2,
  },
  {
    path:'/loi-master3',
    name: 'loi-master3',
    component: loiMaster3,
  },
  {
    path:'/loi-master4',
    name: 'loi-master4',
    component: loiMaster4,
  },

  //consultorio
  {
    path:'/vida-nueva-y-saludable',
    name:'/consultorio',
    component: consultorio
  },
 


  //Campañas
  {
    path: '/campania/:codigo',
    name: 'campania',
    component: () => import("../views/campanias/index")
  },
  
  /*{
    path: '/IA',
    name: 'IA',
    component: IA
  },*/
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition){
    if (savedPosition){
      // Si existe una posición guardada (como al navegar hacia atrás), usa esa posición
      return savedPosition
    } else {
      //Desplazate al inicio de la pagina
      return {top: 0}
    }
  },
})

export default router
