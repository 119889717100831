<template>
    <div class="relative bg-white pb-6 overflow-hidden">
        <!-- Fondo y Hero Section -->

        <div class="flex flex-row items-center bg-blue-900 pt-16 pb-24 w-full px-4 sm:px-6 lg:px-8 relative z-10">
            <!-- Contenedor para el título y el párrafo -->
            <div class="flex-1">
                <h1 class="text-4xl font-extrabold font-serif text-white sm:text-5xl animated-title">
                    Forma Parte del <span class="text-5xl text-yellow-500">Club de Negocios </span>
                </h1>
                <p class="mt-8 text-lg text-blue-300 ">
                    Únete a nuestra comunidad con la membresia de negocios y descubre cómo aumentar tus ventas, recibir
                    mayor exposición publicitaria
                    y generar ingresos adicionales de manera fácil e inteligente. 💼✨<br>
                    ¡Es momento de llevar tu negocio al siguiente nivel con <span class="font-extrabold">Virtual
                        Shopping Club!</span>
                </p>
                <router-link :to="registroRoute" class="mt-6 inline-block px-8 py-3 bg-blue-800 text-white font-bold rounded-full
                                      hover:bg-green-600 hover:text-white transition-all duration-300 animate__animated
                                      animate__zoomInDown animate__slower">
                    ¡Regístrate Gratis!
                </router-link>
            </div>
            <!-- Imagen al lado derecho -->
            <img src="@/assets/img/members/clubNegocios.png" alt="publicidad"
                class="w-40 h-40 animate__animated animate__zoomInLeft">
        </div>

        <div class="mt-14 mb-20">
            <h2 class="text-3xl font-serif font-extrabold text-center animated-gradient">
                "Lleva tu negocio al siguiente nivel con Virtual Shopping Club"
            </h2>
        </div>

        <!-- Video de YouTube -->
        <div class="mx-auto max-w-4xl px-6 lg:px-8">
            <div class="aspect-w-16 aspect-h-9">
                <iframe class="absolute rounded-lg  top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/M4o5Zc7c3fQ" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>


        <!-- Sección de Beneficios -->
        <div class="py-16 mt-12">
            <div class="max-w-7xl mx-auto px-6 lg:px-8">
                <h2 class="text-3xl font-serif font-extrabold text-center text-blue-800 mb-8">
                    En Virtual Shopping Club los negocios obtienen:
                </h2>

                <div class="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                    <div
                        class="flex flex-col items-center text-center bg-blue-100 p-6 rounded-lg shadow-lg border border-blue-300 transform hover:scale-105 transition-all duration-300 ease-in-out">
                        <h3 class="text-2xl font-serif font-semibold text-blue-600">PUBLICIDAD</h3>
                        <img src="@/assets/img/publicidad.png" alt="Icono de publicidad" class="h-16 mx-auto mb-4">
                        <p class="mt-1 text-gray-600" style="text-align: justify;">
                            Publicidad de su marca dentro de nuestra aplicación con renovación anual.
                        </p>
                    </div>
                    <div
                        class="flex flex-col items-center text-center bg-blue-100 p-6 rounded-lg shadow-lg border border-blue-300 transform hover:scale-105 transition-all duration-300 ease-in-out">
                        <h3 class="text-2xl font-serif font-semibold text-blue-600">VENTAS EFECTIVAS</h3>
                        <img src="@/assets/img/VentasEfect.png" alt="Icono depublicidad" class="h-16 mx-auto mb-4">
                        <p class="mt-1 text-gray-600" style="text-align: justify;">
                            Ventas efectivas con los miembros del club en su establecimiento.
                        </p>
                    </div>
                    <div
                        class="flex flex-col items-center text-center bg-blue-100 p-6 rounded-lg shadow-lg border border-blue-300 transform hover:scale-105 transition-all duration-300 ease-in-out">
                        <h3 class="text-2xl font-serif font-semibold text-blue-600">INGRESOS EXTRA</h3>
                        <img src="@/assets/img/ingresosExt.png" alt="Icono de publicidad"
                            class="w-16 h-16 mx-auto mb-4">
                        <p class="mt-1 text-gray-600" style="text-align: justify;">
                            Un sistema para generar ganancias adicionales, que pueden hacer crecer tu negocio.
                        </p>
                    </div>
                    <div
                        class="flex flex-col items-center text-center bg-blue-100 p-6 rounded-lg shadow-lg border border-blue-300 transform hover:scale-105 transition-all duration-300 ease-in-out">
                        <h3 class="text-2xl font-serif font-semibold text-blue-600">SER PARTE DE LA COMUNIDAD DE VSC
                        </h3>
                        <img src="@/assets/img/comunidad.png" alt="Icono de publicidad" class="h-16 mx-auto mb-4">
                    </div>
                    <div
                        class="flex flex-col items-center text-center bg-blue-100 p-6 rounded-lg shadow-lg border border-blue-300 transform hover:scale-105 transition-all duration-300 ease-in-out">
                        <h3 class="text-2xl font-serif font-semibold text-blue-600">PROVEEDOR DE LA COMUNIDAD EN
                            EXPANSIÓN VSC
                        </h3>
                        <img src="@/assets/img/proveedor.png" alt="Icono de publicidad" class="h-16 mx-auto mb-4">
                    </div>
                    <div
                        class="flex flex-col items-center text-center bg-blue-100 p-6 rounded-lg shadow-lg border border-blue-300 transform hover:scale-105 transition-all duration-300 ease-in-out">
                        <h3 class="text-2xl font-serif font-semibold text-blue-600">POSIBILIDAD DE SER PATROCINADOR F3
                        </h3>
                        <img src="@/assets/img/F3.png" alt="Icono de publicidad" class="w-16 h-16 mx-auto mb-4">
                    </div>
                </div>
            </div>
        </div>


        <div class="vsc bg-linear-to-r from-blue-300 via-green-300 to-yellow-300 mt-4 mb-10 pt-6 pb-6">
            <h1 class="text-gray-800 text-3xl font-serif font-extrabold text-center">
                Obtén ganancias residuales de $1 a mas de $15,000,000 con el Programa Fusión
            </h1>
        </div>

        <!-- Programa f3 -->
        <div class=" max-w-4xl mx-auto text-center bg-green-300 rounded-lg p-6">
            <div class="max-w-7xl mx-auto px-6 lg:px-8">
                <h2 class="text-3xl font-serif font-extrabold text-center text-gray-800 mb-5">
                    Programa F3
                </h2>
                <img src="@/assets/img/F3.png" alt="Icono de publicidad" class="w-16 h-16 mx-auto mb-5">
                <p class="text-gray-800 text-justify text-center text-lg leading-relaxed">
                    <span class="font-bold">¡Convierte las ventas de tu zona en ingresos mensuales para ti!</span>

                    Imagina ganar un bono mensual basado en las ventas de los negocios afiliados en tu zona elegida. Con
                    el programa F3, adquieres esa oportunidad, además de ser de los primeros pioneros en este
                    programa.<br><br>

                    ¡Cuantas más ventas haya en tu zona, más ganas tú! No te quedes fuera de esta gran oportunidad de
                    tener ingresos extras mes tras mes.
                </p>
            </div>
        </div>

        <!-- Llamada a la acción -->
        <div class="bg-linear-to-l from-blue-600 to-blue-500 py-16 relative z-20 mt-14">
            <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                <h2 class="text-4xl text-yellow-400 font-serif font-extrabold text-white animate-pulse">
                    ¡Únete Hoy y Lleva Tu Negocio Al Siguiente Nivel!
                </h2>
                <img src="@/assets/img/vsc.png" alt="publicidad" class="w-40 h-30 mx-auto mt-4">
                <p class="text-lg text-blue-100 mt-8 mb-8">
                    Regístrate y comienza a disfrutar de todos los beneficios exclusivos para miembros del Club de
                    Negocios.
                </p>
                <router-link :to="registroRoute"
                    class="inline-block px-8 py-4 bg-blue-900 text-white font-bold text-lg rounded-full
                                      shadow-lg hover:bg-green-600 transform hover:scale-105 transition-all duration-300">
                    ¡Empieza hoy mismo!
                </router-link>
            </div>
        </div>

        <!-- Virtual Shopping Club -->
        <div class="vsc bg-linear-to-r from-blue-400 via-purple-400 to-blue-400 mt-8 mb-4 pt-6 pb-6">
            <h1 class="text-gray-800 text-3xl font-serif font-extrabold text-center">
                VSC es una aplicacion que convierte los gastos comunes propios o de conocidos en formas de generar
                ingresos.
            </h1>
        </div>
        <div class="px-6 mb-4">
            <img src="@/assets/img/servicios-vsc.png" alt="publicidad" class="mx-auto">
        </div>

        <div class="vsc flex flex-col items-center mb-4">
            <h1 class="text-3xl font-extrabold text-center  text-gray-500">
                ¡Haz crecer tu negocio como nunca antes! Únete a Virtual Shopping Club <br> y multiplica tus
                oportunidades.
            </h1>
            <router-link :to="registroRoute" class="text-center mt-8 mb-8 inline-block px-6 py-2 bg-blue-500
                                text-white font-bold rounded-lg shadow-lg custom-shadow hover:bg-green-500
                                hover:text-white hover:shadow-xl transition-all duration-300">
                Unirme
            </router-link>
        </div>

        <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>
    </div>
</template>

<script>
import patrocinadoresComp from "@/components/campanias/patrocinadores";

export default {
    name: 'ClubNegocios',
    components: {
        patrocinadoresComp
    },
    props: {
        patrocinadores: {
            type: Array,
            default: () => []
        }
    },
    mounted() {
      fbq('init', '1351295932570313')
      fbq('track', 'PageView')
    },
    computed: {
        registroRoute() {
            return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
                '/registro'
        }
    }
}
</script>

<style scoped>
/* Estilos personalizados */

/*Animacion Titulo*/
.animated-title {
    animation: slideIn 1.5s ease-in-out;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.animated-gradient {
    animation: pulse 2s infinite;
    font-size: 2rem;
    /* Ajusta el tamaño */
    font-weight: 800;
    /* Fuente extrabold */
    text-align: center;
    /* Centrar texto */
    background: linear-gradient(90deg, #2f899f, #2327dc, #2f899f);
    /* Gradiente de 3 colores */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    /* El gradiente aparecerá en lugar del color sólido */
    animation: gradient-animation 3s infinite;
    /* Añade la animación */
}

@keyframes gradient-animation {

    0%,
    100% {
        background-position: 0% 50%;
        transform: scale(0.8);
    }

    50% {
        background-position: 100% 50%;
        transform: scale(0.9);
    }
}

.bg-blue-900 {
    background-color: #1c3d5a;
    /* Azul oscuro para el fondo del hero */
}

.bg-blue-100 {
    background-color: #ebf8ff;
    /* Azul claro para tarjetas */
}

.text-blue-600 {
    color: #3182ce;
    /* Azul intenso para los encabezados */
}

.text-blue-800 {
    color: #2b6cb0;
    /* Azul oscuro para texto destacado */
}

.text-gray-600 {
    color: #718096;
    /* Gris suave para texto de párrafos */
}

.shadow-lg {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    /* Sombra suave */
}

.shadow-xl {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Sombra más fuerte */
}



.transition-all {
    transition: all 0.3s ease;
    /* Transición suave */
}

.hover\:scale-105:hover {
    transform: scale(1.05);
    /* Escala en hover para las tarjetas */
}

.bg-linear-to-l {
    background: linear-gradient(to right, #2b6cb0, #4c51bf);
    /* Gradiente atractivo para fondo */
}

.text-lg {
    font-size: 1.125rem;
    /* Tamaño de fuente más grande */
}

.text-4xl {
    font-size: 2.25rem;
    /* Fuente más grande para encabezados */
}

.font-extrabold {
    font-weight: 800;
    /* Peso de fuente más fuerte */
}

button:hover,
a:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}

/* Estilo adicional para pantallas pequeñas */
@media (max-width: 568px) {
    .vsc {
        padding: 0.5rem;
    }

    .vsc h1 {
        font-size: 1rem;
    }
}
</style>