<template>
  <div class=" pb-6 bg-yellow-100 min-h-screen text-gray-900">
    <!-- Encabezado -->
    <header class="bg-linear-to-r from-yellow-500 via-orange-400 to-yellow-500 text-white py-6">
      <div class="pt-16 pb-16 max-w-7xl mx-auto px-4 flex flex-col md:flex-row items-center md:space-x-8">
        <!-- Contenedor para el título y el párrafo -->
        <div class="flex-1 text-center md:text-left">
          <h1
            class="text-4xl font-serif font-extrabold animated-header transform hover:-translate-y-1 transition duration-300">
            Conviértete en un <span class="text-5xl text-orange-900">¡Patrocinador de Tecnología!</span>
          </h1>
          <p class="mt-6 text-lg">
            ¡Haz crecer tus ingresos como patrocinador! 💼✨ Gana beneficios exclusivos y potencia tus
            oportunidades financieras.
            🚀 ¡Únete ahora a la comunidad de patrocinadores y marca la diferencia!
          </p>
        </div>
        <!-- Imagen -->
        <img src="@/assets/img/members/patrocinador.jpg" alt="publicidad"
          class="w-40 h-40 rounded-lg animate__animated animate__fadeInBottomLeft mt-6 md:mt-0">
      </div>
    </header>

    <div class="mt-12 mb-6">
      <h2 class="text-3xl font-extrabold text-center animated-gradient">
        "Recompensas desde Pesos a bonos Ilimitados"
      </h2>
    </div>

    <!-- Sección de beneficios -->
    <section class="max-w-7xl mx-auto px-4 py-10">
      <h2 class="text-3xl font-bold text-orange-700 text-center animate__animated animate__rotateInDownLeft">¿Por qué
        ser patrocinador?
      </h2>
      <p class="mt-4 text-center text-justify text-lg text-orange-600">
        Ser patrocinador de nuestra tecnología significa formar parte del crecimiento del Virtual Shopping Club y
        acceder a
        herramientas avanzadas que te permitirán generar ingresos mientras maximizas tu impacto en la economía digital.
        Además,
        contribuirás a revolucionar la forma en que las personas compran y se conectan en el mundo virtual.
      </p>
    </section>

    <!-- Video de YouTube -->
    <div class="mx-auto max-w-4xl px-6 lg:px-8">
      <div class="aspect-w-16 aspect-h-9">
        <iframe class="absolute rounded-lg  top-0 left-0 w-full h-full" src="https://www.youtube.com/embed/f1SxM7npTNY"
          frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>

    <!-- Que seciben nuestros patrocinadores -->
    <section class="bg-linear-to-r from-yellow-500 via-yellow-400 to-yellow-500 mt-12 py-12 px-6">
      <div class="max-w-4xl mx-auto text-center">
        <!-- Título -->
        <h2 class="text-4xl font-extrabold text-blue-800 mb-6 text-center">
          En Virtual Shopping Club
          <span class="block text-5xl text-orange-600 mt-2">Nuestros Patrocinadores</span>
        </h2>

        <!-- Texto descriptivo -->
        <p class="text-lg text-orange-700 font-semibold mb-8">
          Reciben tecnología que les estará generando beneficios que pueden utilizar para:
        </p>

        <!-- Lista de beneficios -->
        <ul class="grid grid-cols-1 md:grid-cols-2 gap-6 text-left">
          <li
            class="flex items-start bg-yellow-200 font-extrabold text-orange-600 p-4 rounded-lg shadow-lg transform hover:-translate-y-1 transition duration-300">
            <div class="text-orange-600 text-2xl font-bold mr-3">•</div>
            <span>Recargas telefónicas</span>
          </li>
          <li
            class="flex items-start bg-yellow-200 font-extrabold text-orange-600 p-4 rounded-lg shadow-lg transform hover:-translate-y-1 transition duration-300">
            <div class="text-orange-600 text-2xl font-bold mr-3">•</div>
            <span>Pagos de servicios</span>
          </li>
          <li
            class="flex items-start bg-yellow-200 font-extrabold text-orange-600 p-4 rounded-lg shadow-lg transform hover:-translate-y-1 transition duration-300">
            <div class="text-orange-600 text-2xl font-bold mr-3">•</div>
            <span>Compras en el marketplace</span>
          </li>
          <li
            class="flex items-start bg-yellow-200 font-extrabold text-orange-600 p-4 rounded-lg shadow-lg transform hover:-translate-y-1 transition duration-300">
            <div class="text-orange-600 text-2xl font-bold mr-3">•</div>
            <span>Compras en Negocios Afiliados</span>
          </li>
          <li
            class="flex items-start bg-yellow-200 font-extrabold text-orange-600 p-4 rounded-lg shadow-lg transform hover:-translate-y-1 transition duration-300">
            <div class="text-orange-600 text-2xl font-bold mr-3">•</div>
            <span>Marketing digital</span>
          </li>
          <li
            class="flex items-start bg-yellow-200 font-extrabold text-orange-600 p-4 rounded-lg shadow-lg transform hover:-translate-y-1 transition duration-300">
            <div class="text-orange-600 text-2xl font-bold mr-3">•</div>
            <span>Publicidad</span>
          </li>
          <li
            class="flex items-start bg-yellow-200 font-extrabold text-orange-600 p-4 rounded-lg shadow-lg transform hover:-translate-y-1 transition duration-300">
            <div class="text-orange-600 text-2xl font-bold mr-3">•</div>
            <span>Pagos de membresías</span>
          </li>
          <li
            class="flex items-start bg-yellow-200 font-extrabold text-orange-600 p-4 rounded-lg shadow-lg transform hover:-translate-y-1 transition duration-300">
            <div class="text-orange-600 text-2xl font-bold mr-3">•</div>
            <span>Patrocinios</span>
          </li>
          <li
            class="flex items-start bg-yellow-200 text-stra font-extrabold text-orange-600 p-4 rounded-lg shadow-lg transform hover:-translate-y-1 transition duration-300">
            <div class="text-orange-600 text-2xl font-bold mr-3">•</div>
            <span>Recursos para más beneficios...</span>
          </li>
        </ul>
      </div>
    </section>


    <section class="mt-16">
      <h2 class="text-4xl font-bold text-orange-700 text-center">
        Formas de ser Patrocinador
      </h2>
    </section>
    <!-- Tres formas de patrocinio -->
    <section class="max-w-7xl mx-auto px-4 py-10 grid grid-cols-1 md:grid-cols-3 gap-8">
      <!-- Gemas Publicitarias -->
      <div
        class="bg-white border-4 border-blue-400 rounded-lg shadow-lg hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
        <div class="p-6">

          <h3 class="text-center text-2xl font-bold text-blue-600">Gemas Publicitarias</h3>
          <img src="@/assets/img/members/Insignia_Gestor1.png" alt="publicidad" class="w-24 h-24 mx-auto ">

          <p class="text-blue-900 text-justify">
            Con tu gema publicitaria, al asignarla a una de las 100 categorías de tu elección, ganarás automáticamente
            un porcentaje de las ventas generadas
            por todos los productos de esa categoría. Puedes elegir entre categorías de alta demanda como tecnología,
            moda, deportes, hogar y más.
          </p>
        </div>
      </div>

      <!-- Paquete Vitalicio -->
      <div
        class="bg-white border-4 border-orange-400 rounded-lg shadow-lg hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
        <div class="p-6">

          <h3 class="text-center text-2xl font-bold text-orange-600">Paquete Vitalicio</h3>
          <img src="@/assets/img/members/Insignia_Gestor.png" alt="publicidad" class="w-20 h-20 mx-auto mb-4">

          <p class="mt-4 text-orange-600 text-justify">
            Convertirse en un Miembro Vitalicio, te da acceso a una amplia gama de beneficios diseñados para maximizar
            tus ganancias y tu impacto en nuestra
            comunidad tecnológica. Al ser parte de este selecto grupo, disfrutarás de: 1 Gema Génesis: Participa en el
            bono anual de miembros Génesis, que otorga
            el 5% de las utilidades de la empresa entre sus portadores. 1 Gema de Proyecto: Gana desde $500,000 al año
            con las ventas de una de las 100 categorías
            disponibles al elegir dónde colocar tu gema y lo mejor es que tendrás ganancias ilimitadas.
          </p>
        </div>
      </div>

      <!-- Programa Especial F -->
      <div
        class="bg-white border-4 border-green-500 rounded-lg shadow-lg hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
        <div class="p-6">

          <h3 class="text-center text-2xl font-bold text-green-600">Programa Especial F3</h3>
          <img src="@/assets/img/F3.png" alt="publicidad" class="w-14 h-14 mx-auto mt-4 mb-4">

          <p class="mt-4 text-green-700 text-justify">
            Con el paquete de Patrocinio F3, te conviertes en patrocinador de tecnología y obtienes 2 participaciones
            permanentes.
            Estas te permiten recibir un bono mensual basado en el número de transacciones de ventas en establecimientos
            por zona
            ¡Apoya nuestra aplicación y disfruta de beneficios cada mes sin
            complicaciones!
          </p>
        </div>
      </div>
    </section>

    <!-- Llamada a la acción -->
    <section class="bg-yellow-500 text-white py-16 mt-12">
      <div class="max-w-7xl mx-auto px-4 text-center">
        <h2 class="text-5xl text-orange-700  pb-14 font-serif font-bold ">¡Conviértete en
          Patrocinador de Tecnología Hoy!
        </h2>
        <img src="@/assets/img/vsc.png" alt="publicidad" class="w-40 h-30 mx-auto mb-4">
        <p class="mt-4 text-lg">
          No solo apoyarás la innovación tecnológica, sino que también obtendrás beneficios exclusivos que te ayudarán a
          destacar en el mercado.
        </p>
        <router-link :to="registroRoute" class="mt-8 inline-block px-6 py-3 bg-white text-orange-700 font-bold text-lg rounded-lg
                              hover:bg-green-600 hover:text-white animated-call-to-action">
          Regístrate Ahora
        </router-link>
      </div>
    </section>

    <!-- Virtual Shopping Club -->
    <div class="vsc bg-linear-to-r from-blue-400 via-purple-400 to-orange-400 mt-8 mb-4 pt-6 pb-6">
      <h1 class="text-gray-800 text-3xl font-serif font-extrabold text-center">
        VSC es una aplicacion que convierte los gastos comunes propios o de conocidos en formas de generar
        ingresos.
      </h1>
    </div>
    <div class="px-6 mb-4">
      <img src="@/assets/img/servicios-vsc.png" alt="publicidad" class="mx-auto">
    </div>

    <div class="vsc flex flex-col items-center mb-4">
      <h1 class="text-3xl font-extrabold text-center  text-gray-500">
        ¡Es tu turno de brillar! Virtual Shopping Club tiene un lugar esperándote con beneficios increíbles.
      </h1>
      <router-link :to="registroRoute" class="text-center mt-8 mb-8 inline-block px-7 py-3 border border-orange-400 bg-white text-orange-800
                            font-bold rounded-lg shadow-lg custom-shadow hover:bg-green-500 hover:text-white
                            hover:shadow-xl transition-all duration-300">
        Unirme
      </router-link>
    </div>

    <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>
  </div>
</template>

<script>
import patrocinadoresComp from "@/components/campanias/patrocinadores";

export default {
  name: 'patrocinadorTecnologia',
  components: {
    patrocinadoresComp
  },
  props: {
    patrocinadores: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    fbq('init', '1351295932570313')
    fbq('track', 'PageView')
  },
  computed: {
    registroRoute() {
      return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
        '/registro'
    }
  }
}

</script>

<style scoped>
/*Animacion Titulo*/
.animated-header {
  animation: slideInLeft 1.5s ease-out;
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated-gradient {
  animation: pulse 2.5s infinite;
  font-size: 2rem;
  /* Ajusta el tamaño */
  font-weight: 800;
  /* Fuente extrabold */
  text-align: center;
  /* Centrar texto */
  background: linear-gradient(90deg, #f1db16, #ff9305, #453615);
  /* Gradiente de 3 colores */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  /* El gradiente aparecerá en lugar del color sólido */
  animation: gradient-animation 3s infinite;
  /* Añade la animación */
}

@keyframes gradient-animation {

  0%,
  100% {
    background-position: 0% 50%;
    transform: scale(0.8);
  }

  50% {
    background-position: 100% 50%;
    transform: scale(0.9);
  }
}

/* Animación del encabezado con bucle infinito */
.animated-call-to-action {
  animation: infiniteZoom 2s ease-in-out infinite;
}

@keyframes infiniteZoom {
  0% {
    opacity: 0.8;
    transform: scale(0.9);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.8;
    transform: scale(0.9);
  }
}

.rounded-lg {
  border-radius: 1rem;
  /* Bordes redondeados boton registro */
}

/*animacion boton registro*/
button:hover,
a:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

/* Estilo adicional para pantallas pequeñas */
@media (max-width: 568px) {
  .vsc {
    padding: 0.5rem;
  }

  .vsc h1 {
    font-size: 1rem;
  }
}
</style>