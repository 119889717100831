<template>
    <div class="bg-gray-100 text-gray-900">
        <!-- Hero Section -->
        <section class="relative py-16 px-6 text-center    bg-cover bg-center md:bg-center pt-32 pb-32"
            :style="{ backgroundImage: `url(${require('@/assets/img/consultorio/bannerConsultorio.jpg')})` }">

            <h2 class=" text-6xl font-extrabold text-green-500 animate__animated animate__zoomInDown ">
                Vida Nueva y Nutrición</h2>
            <p class="mt-12 text-lg font-semibold text-gray-800">"Transforma tu vida con un plan personalizado para
                alcanzar tu peso
                ideal de manera saludable."</p>
            <button
                class="mt-12 inline-block px-9 py-3 text-white font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(0,255,0,0.7)]">
                Agendar cita ahora
            </button>
        </section>
        <div class="bg-gradient-to-r from-green-400 via-green-600 to-green-400 pt-1 pb-1">
        </div>

        <section class="mt-10 text-center ">
            <h2 class="text-4xl font-extrabold text-gray-800">Pierde de 4 a 8 kilos al mes</h2>
            <p class="text-lg text-gray-600 mt-4">Con nuestro tratamiento homeopático seguro y efectivo.</p>
            <p class="text-lg text-gray-600 mt-2">¡Agenda tu cita hoy con un 50% de descuento!</p>
            <button class="mt-6 px-6 py-3 bg-green-500 text-white font-semibold text-lg rounded-lg hover:bg-green-700">
                Agendar cita ahora
            </button>
        </section>

        <!-- Especialidades -->
        <section class="mt-16 bg-white p-8 rounded-lg shadow-lg text-center">
            <h3 class="text-3xl font-semibold text-gray-800">Nuestras Especialidades</h3>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                <div
                    class="border-l-4 border-green-500 p-6 bg-green-200 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                    <h4 class="text-xl font-bold text-gray-700">Tratamiento Homeopático</h4>
                    <p class="text-gray-600 mt-2">Soluciones naturales para una pérdida de peso efectiva.</p>
                    <img src="@/assets/img/consultorio/homeopatico.png" alt="Medicamento Homeopatico"
                        class="w-45 h-26 mx-auto">
                </div>
                <div
                    class="border-l-4 border-green-500 p-6 bg-green-200 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                    <h4 class="text-xl font-bold text-gray-700">Planes Nutricionales</h4>
                    <p class="text-gray-600 mt-2">Dietas personalizadas para maximizar tus resultados.</p>
                    <img src="@/assets/img/consultorio/nutricion.png" alt="Planes niutricionales"
                        class="w-45 h-26 mx-auto">
                </div>
                <div
                    class="border-l-4 border-green-500 p-6 bg-green-200 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                    <h4 class="text-xl font-bold text-gray-700">Seguimiento Médico</h4>
                    <p class="text-gray-600 mt-2">Consultas regulares para asegurar tu bienestar.</p>
                    <img src="@/assets/img/consultorio/seguimiento.png" alt="Seguimiento Medicor"
                        class="w-30 h-26 mx-auto">
                </div>
            </div>
        </section>

        <div class="mt-12 mb-12">
            <h2 class="text-3xl font-extrabold text-center animated-gradient">
                "Tú puedes lograrlo. Nosotros te ayudamos a hacerlo realidad"
            </h2>
        </div>

        <!-- Cómo funciona -->
        <div class="w-full mt-16 bg-cover bg-center pt-8 pb-12">
            <h2 class=" text-4xl font-extrabold text-center text-green-500 mb-12">Cómo Funciona</h2>
            <div class="flex flex-col md:flex-row justify-center gap-10 px-4">
                <div class="w-full md:w-1/3 bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-cover bg-center"
                    :style="{ backgroundImage: `url(${require('@/assets/img/consultorio/cardFondo.png')})` }">
                    <div class="flex justify-center items-center mb-4">
                        <div class="w-16 h-16 bg-green-500 text-white rounded-full flex justify-center items-center">
                            <span class="text-xl font-bold">1</span>
                        </div>
                    </div>
                    <h3 class="neon-black text-xl font-semibold text-center text-white mb-4">Agenda tu consulta inicial
                    </h3>
                    <p class="neon-black text-gray-800 text-center text-white">Comienza tu proceso de transformación
                        reservando una consulta con el doctor.</p>
                </div>

                <div class="w-full md:w-1/3 bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-cover bg-center"
                    :style="{ backgroundImage: `url(${require('@/assets/img/consultorio/cardFondo.png')})` }">
                    <div class="flex justify-center items-center mb-4">
                        <div class="w-16 h-16 bg-green-500 text-white rounded-full flex justify-center items-center">
                            <span class="text-xl font-bold">2</span>
                        </div>
                    </div>
                    <h3 class="neon-black text-xl font-semibold text-center text-white mb-4">Recibe tu plan
                        personalizado y medicamento</h3>
                    <p class="neon-black text-white text-center">El doctor diseñará un plan que se adapte a tus
                        necesidades y objetivos de peso.</p>
                </div>

                <div class="w-full md:w-1/3 bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-cover bg-center"
                    :style="{ backgroundImage: `url(${require('@/assets/img/consultorio/cardFondo.png')})` }">
                    <div class="flex justify-center items-center mb-4">
                        <div class="w-16 h-16 bg-green-500 text-white rounded-full flex justify-center items-center">
                            <span class="text-xl font-bold">3</span>
                        </div>
                    </div>
                    <h3 class="neon-black text-xl font-semibold text-center text-white mb-4">Monitoreo y apoyo constante
                    </h3>
                    <p class="neon-black text-white text-center">Te ofrecemos seguimiento continuo para asegurar que
                        estés en el camino correcto hacia tu objetivo.</p>
                </div>
            </div>
        </div>

        <section
            class="bg-gradient-to-r from-green-400 via-green-600 to-green-400 mt-16 text-white p-8 rounded-lg shadow-lg text-center">
            <h3 class="text-3xl font-semibold">¡Nos preparamos para nuestro 25 aniversario!</h3>
            <p class="text-lg mt-4">Celebra con nosotros y disfruta de ofertas exclusivas para nuestros pacientes.</p>
        </section>

        <!-- testimonios -->
        <section class="py-16 px-4 bg-white text-center">
            <h2 class="text-3xl font-bold text-green-600">Lo que dicen nuestros pacientes</h2>
            <div class="mt-6 grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
                <div class="mt-6 max-w-xl mx-auto bg-gray-200 p-6 rounded-lg shadow flex flex-col">
                    <p class="italic text-lg text-gray-800">"En lo personal me encanta esta app, por que ahora gano
                        dinero
                        diario
                        por mis compras que hago, y lo mejor que puedo cambiar mis puntos ganados al momento y asi
                        seguir
                        comprando."
                    </p>
                    <p class="mt-2 font-semibold text-right text-green-700">— Martha Balderas</p>
                </div>
                <div class="mt-6 max-w-xl mx-auto bg-gray-200 p-6 rounded-lg shadow flex flex-col">
                    <p class="italic text-lg text-gray-800">"En lo personal me encanta esta app, por que ahora gano
                        dinero
                        diario
                        por mis compras que hago, y lo mejor que puedo cambiar mis puntos ganados al momento y asi
                        seguir
                        comprando."
                    </p>
                    <p class="mt-2 font-semibold text-right text-green-700">— Martha Balderas</p>
                </div>
            </div>
        </section>

        <div class="bg-gradient-to-r from-green-400 via-green-600 to-green-400 pt-1 pb-1">
        </div>

        <!-- FAQ -->
        <section class="py-16 px-4 bg-gray-100 text-center">
            <h2 class="text-3xl font-bold text-green-600">Preguntas Frequentes</h2>
            <details class="mt-4 bg-white p-4 rounded-lg shadow">
                <summary class="cursor-pointer font-semibold">Es seguro el medicamento homeopatico?</summary>
                <p class="mt-2">Si, el medicamento homeopatico es muy seguro.</p>
            </details>
            <details class="mt-4 bg-white p-4 rounded-lg shadow">
                <summary class="cursor-pointer font-semibold">Cuanto cuesta la consulta?</summary>
                <p class="mt-2">$300 pesos cuesta la consulta pero hay promocion del 50% de descuento. El medicamento
                    homeopatico tiene un costo adicional</p>
            </details>
        </section>

        <div class="bg-gray-100 py-12">
    <div class="container mx-auto text-center">
      <h2 class="text-3xl font-semibold text-green-600 mb-4">📍 Encuéntranos</h2>
      <p class="text-gray-600 text-lg mb-6">Visítanos en nuestro consultorio y comienza tu transformación.</p>

      <div class="bg-white shadow-lg rounded-lg p-6 inline-block">
        <div class="flex items-center justify-center mb-4">
          <div class="bg-green-500 text-white w-12 h-12 flex justify-center items-center rounded-full">
            📍
          </div>
        </div>
        <p class="text-xl font-medium text-gray-700">
          Calle Hidalgo #77, Col. Centro, San Juan del Rio, Queretaro
        </p>
        <p class="text-gray-500 mt-2">Lunes a Viernes: 9 AM - 7 PM | Sábados: 9 AM - 2 PM</p>

        <a 
          href="https://maps.app.goo.gl/2HZUpmxGqwphLf6C6" 
          target="_blank"
          class="mt-4 inline-block bg-green-500 text-white px-6 py-2 rounded-lg shadow hover:bg-green-600 transition"
        >
          Ver en Google Maps
        </a>
      </div>
    </div>
  </div>

        <!-- Llamada a la acción -->
        <div class=" bg-gray-600 py-16 relative z-20 mt-16">
            <!-- CTA -->
            <section class="max-w-3xl mx-auto text-center py-12 px-6">
                <h2 class="neon-text font-serif text-4xl font-bold text-yellow-500"> ¡Sé de los Primeros en un Mundo
                    Nuevo!</h2>
                <p class="neon-black mt-4 text-lg text-white">Reserva tu parcela hoy y sé parte de la élite de
                    jugadores-inversores.</p>


                <p class="neon-text font-serif mt-4 text-lg text-blue-900 font-bold">Legends of Interactions — La
                    Frontera Entre
                    la Realidad y lo Digital.</p>
            </section>
        </div>

    </div>
</template>

<script>
export default {
    name: "LandingPage",
};
</script>

<style scoped>
body {
    font-family: Arial, sans-serif;
}

.neon-text {
    text-shadow: 0 0 90px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff;
}

.neon-black {
    text-shadow: 0 0 20px #000000, 0 0 10px #000000, 0 0 20px #000000;
}

/* Animación para resaltar la palabra activa */
.neon-hover {
    animation: neonAnimation 1.5s infinite alternate;
}

/* Efecto Parallax */
.parallax {
    background-image: url('@/assets/img/consultorio/bannerFondo.jpg');
    height: 500px;
    background-attachment: fixed;
    /* Aquí ocurre la magia */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@keyframes gradientChange {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.gradient-bg {
    background: linear-gradient(45deg, #16f734, #086ce7, #10ef4b, #2575fc);
    background-size: 400% 400%;
    animation: gradientChange 4s ease infinite;
}

.animated-gradient {
    animation: pulse 2s infinite;
    font-size: 2rem;
    /* Ajusta el tamaño */
    font-weight: 800;
    /* Fuente extrabold */
    text-align: center;
    /* Centrar texto */
    background: linear-gradient(90deg, #35c54f, #06ff0b, #35c54f);
    /* Gradiente de 3 colores */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    /* El gradiente aparecerá en lugar del color sólido */
    animation: gradient-animation 3s infinite;
    /* Añade la animación */
}

@keyframes gradient-animation {

    0%,
    100% {
        background-position: 0% 50%;
        transform: scale(0.8);
    }

    50% {
        background-position: 100% 50%;
        transform: scale(0.9);
    }
}
</style>