<template>
  <div class="min-h-screen bg-white text-gray-800">
    <!-- Hero -->
    <div class="relative text-center py-16 px-6 text-white bg-cover bg-center"
      :style="{ backgroundImage: `url(${require('@/assets/img/negocios.png')})` }">
      <div class="absolute inset-90 bg-black bg-opacity-10"></div>
      <div class="relative z-10">
        <h1 class="text-4xl font-bold animate__animated animate__zoomInDown animate__slow">
          <span class="text-6xl text-yellow-400">Expande tu Negocio</span> con Virtual Shopping Club 🚀
        </h1>
        <p class="mt-4 text-lg animate__animated animate__zoomInLeft">Únete hoy y accede a publicidad, más
          ventas y un programa de ingresos
          adicionales.</p>
        <router-link :to="registroRoute"
                     class="mt-6 inline-block px-7 py-3 bg-yellow-400 text-black font-semibold rounded-lg
                              hover:bg-green-600 hover:text-white transition-all duration-300 animate__animated
                              animate__zoomInDown animate__slower">
          ¡Afíliate Ahora!
        </router-link>
      </div>
    </div>

    <!-- Video de YouTube -->
    <div class="mx-auto max-w-4xl px-6 lg:px-8 mt-16">
            <div class="aspect-w-16 aspect-h-9">
                <iframe class="absolute rounded-lg  top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/M4o5Zc7c3fQ" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>



    <!-- Beneficios -->
    <div class="grid md:grid-cols-3 gap-6 px-8 py-12">
      <div class="p-6 bg-gray-200 rounded-lg hover:scale-105 transition-all duration-300">
        <h2 class="text-2xl font-semibold">📢 Publicidad</h2>
        <p class="mt-2">Haz que más clientes conozcan tu negocio con publicidad.</p>
      </div>
      <div class="p-6 bg-gray-200 rounded-lg hover:scale-105 transition-all duration-300">
        <h2 class="text-2xl font-semibold">🛒 Más Ventas</h2>
        <p class="mt-2">Llega a clientes dispuestos a comprar con nuestra plataforma.</p>
      </div>
      <div class="p-6 bg-gray-200 rounded-lg hover:scale-105 transition-all duration-300">
        <h2 class="text-2xl font-semibold">💰 Ingresos Extras</h2>
        <p class="mt-2">Obtén comisiones adicionales y genera ingresos recurrentes.</p>
      </div>
    </div>

    <div class="vsc bg-linear-to-r from-gray-300 via-blue-200 to-gray-300 mb-12 pt-6 pb-6">
      <h1 class="text-gray-800 text-3xl font-serif font-extrabold text-center">
        Obtén ganancias residuales de $1 a mas de $15,000,000 con el Programa Fusión
      </h1>
    </div>

    <!-- Beneficio Exclusivo -->
    <div class="max-w-5xl mx-auto text-center py-10 px-6 bg-yellow-400 rounded-lg mb-12">
      <h3 class="text-3xl font-semibold">🔥 Solo Los Primeros 100 Negocios Recibirán el Programa de Patrocinio F3</h3>
      <p class="mt-4  text-justify">Valorado en <b>$10,000</b>, pero para los primero 100 negocios se les dara como
        cortesía (sujeto a
        disponibilidad). Imagina ganar un bono mensual basado en las ventas de los negocios afiliados en tu zona
        elegida.
        Con el programa F3, adquieres esa oportunidad, además de ser de los primeros pioneros en este programa.</p>
      <div class="mt-4 mb-6 text-2xl font-bold text-red-600">
        ⏳ <span>{{ countdown }}</span>
      </div>
      <router-link :to="registroRoute"
                   class="mt-4 px-6 py-3 bg-red-600 text-white font-semibold rounded-lg hover:bg-red-700">
        ¡Quiero ser uno de los 100!
      </router-link>
    </div>

    <section class="py-12">
      <div class="max-w-4xl mx-auto text-center">
        <h2 class="text-3xl font-bold text-gray-900 mb-6">🚨 ¿Tu negocio enfrenta estos desafíos?</h2>

        <div class="grid grid-cols-1 md:grid-cols-4 gap-6">
          <div class="bg-gray-100 p-6 rounded-lg shadow-lg text-center border border-gray-100 hover:border-gray-500">
            <h3 class="text-xl font-bold text-red-500">❌ ¿Gastas en publicidad sin ver resultados?</h3>
            <p class="text-gray-700 mt-2 text-justify">Pagar anuncios no garantiza ventas, y atraer nuevos clientes cada vez es más
              difícil..</p>
          </div>

          <div class="bg-gray-100 p-6 rounded-lg shadow-lg text-center border border-gray-100 hover:border-gray-500 ">
            <h3 class="text-xl font-bold text-red-500">❌ ¿Tienes clientes que compran una vez y no regresan?</h3>
            <p class="text-gray-700 mt-2 text-justify">Sin un sistema efectivo de fidelización, es complicado generar ingresos
              constantes.</p>
          </div>

          <div class="bg-gray-100 p-6 rounded-lg shadow-lg text-center border border-gray-100 hover:border-gray-500">
            <h3 class="text-xl font-bold text-red-500">❌ ¿Te cuesta destacar frente a la competencia?</h3>
            <p class="text-gray-700 mt-2 text-justify">Miles de negocios luchan por la atención de los clientes, y sin visibilidad
              digital, las ventas se reducen.</p>
          </div>

          <div class="bg-gray-100 p-6 rounded-lg shadow-lg text-center border border-gray-100 hover:border-gray-500">
            <h3 class="text-xl font-bold text-red-500">❌ ¿Te gustaría ganar más sin aumentar tu carga de trabajo?</h3>
            <p class="text-gray-700 mt-2 text-justify">Depender solo de la venta de productos o servicios puede limitar tu
              crecimiento.</p>
          </div>
        </div>
      </div>
    </section>

    <section class="py-12">
      <div class="max-w-4xl mx-auto text-center">
        <h2 class="text-3xl font-bold text-gray-900 mb-6">💡 Con Virtual Shopping Club, tu negocio tiene una ventaja
          real:</h2>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
          <!-- Carga Administrativa Pesada -->
          <div class="bg-blue-400 p-6 rounded-lg shadow-lg text-center border border-blue-400 hover:border-blue-700">
            <h3 class="text-xl font-bold text-white">✅ Publicidad sin gastar en anuncios.</h3>
            <p class="text-white mt-2 text-justify">Tu negocio se promociona sin que tengas que pagar por publicidad. Te conectamos
              con personas que buscan lo que ofreces.</p>
          </div>

          <!-- Falta de Personalización -->
          <div class="bg-blue-400 p-6 rounded-lg shadow-lg text-center border border-blue-400 hover:border-blue-700">
            <h3 class="text-xl font-bold text-white">✅ Clientes que vuelven cada mes.</h3>
            <p class="text-white mt-2 text-justify">Tus clientes no solo compran una vez, sino que regresan porque obtienen
              beneficios al hacerlo.</p>
          </div>

          <div class="bg-blue-400 p-6 rounded-lg shadow-lg text-center border border-blue-400 hover:border-blue-700">
            <h3 class="text-xl font-bold text-white">✅ Más visibilidad para tu negocio.</h3>
            <p class="text-white mt-2 text-justify">No dependas solo de redes sociales. Te ayudamos a llegar a más personas dentro de
              una comunidad de compradores activos.</p>
          </div>

          <div class="bg-blue-400 p-6 rounded-lg shadow-lg text-center border border-blue-400 hover:border-blue-700">
            <h3 class="text-xl font-bold text-white">✅ Gana dinero extra sin hacer más trabajo.</h3>
            <p class="text-white mt-2 text-justify">Además de vender, puedes generar ingresos adicionales con nuestro sistema de
              recompensas.</p>
          </div>

          <div class="bg-blue-600 p-6 rounded-lg shadow-lg text-center border border-blue-600 hover:border-gray-800">
            <h3 class="text-xl font-bold text-white">✅ Un experto que te asesora.</h3>
            <p class="text-white mt-2 text-justify">Tendrás el apoyo de un gestor que te ayuda con publicidad, ventas y a aprovechar
              todas las oportunidades de ingresos.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Preguntas Frecuentes (FAQ) -->
    <div class="bg-yellow-200 max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-6 mb-12">
      <div class=" p-6 rounded-lg text-center text-white">
        <h3 class="text-gray-800 text-3xl font-bold">Preguntas Frecuentes</h3>
      </div>
      <div id="faqApp" class="mt-6">
        <div v-for="(faq, index) in faqs" :key="index" class="border-b border-gray-500 py-4">
          <button @click="toggleFAQ(index)" class="w-full text-left font-semibold text-lg flex justify-between">
            {{ faq.question }}
            <span class="text-gray-800">{{ faq.open ? "−" : "+" }}</span>
          </button>
          <p v-if="faq.open" class="mt-2 text-gray-700">{{ faq.answer }}</p>
        </div>
      </div>
    </div>

    <!-- CTA Final -->
    <div class=" bg-gray-300 vsc text-center py-12">
      <h3 class="text-3xl font-bold"> ¡No Te Quedes Fuera y Lleva Tu Negocio Al Siguiente Nivel! </h3>
      <img src="@/assets/img/vsc.png" alt="publicidad" class="w-40 h-30 mx-auto mt-4">
      <router-link :to="registroRoute"
                   class="text-center mt-8 mb-8 inline-block px-8 py-3 bg-yellow-400 text-white font-bold rounded-lg
                            shadow-lg custom-shadow hover:bg-green-500 hover:text-white hover:shadow-xl transition-all
                            duration-300">
        Unirme Ahora
      </router-link>

      <div class="max-w-sm mx-auto bg-gray-900 text-white p-6 rounded-lg shadow-lg">
        <!-- Título del Plan -->
        <h2 class="text-xl text-green-400 font-semibold">Membresia <span class="text-white">(Anual)</span></h2>
        <p class="text-gray-400 text-sm mt-2">Para negocios en crecimiento</p>

        <!-- Precio -->
        <div class="mt-6 mb-6">
          <h3 class="text-5xl font-bold">$20,000 <span class="text-2xl text-gray-300">MXN</span>/<span
              class="text-3xl text-blue-400">anual</span></h3>
        </div>

        <!-- Botón principal -->
        <router-link to="/registro"
        class="mt-6 w-full bg-transparent border border-white hover:bg-green-500 text-white font-bold py-3 px-24 rounded-lg transition duration-300">
        Comprar Ahora
      </router-link>

        <!-- Lista de beneficios -->
        <div class="mt-6">
          <h4 class="text-gray-300 mb-4">¿Qué incluye?</h4>
          <ul class="space-y-3 text-sm">
            <li class="flex items-center"><span class="text-green-400 mr-2">✔️</span>Publicidad de tu marca</li>
            <li class="flex items-center"><span class="text-green-400 mr-2">✔️</span>Ventas efectivas</li>
            <li class="flex items-center"><span class="text-green-400 mr-2">✔️</span>Ingresos extras</li>
            <li class="flex items-center"><span class="text-green-400 mr-2">✔️</span>Asesoria de un experto</li>
          </ul>
        </div>
      </div>

    </div>

    <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>
  </div>
</template>

<script>
import patrocinadoresComp from "@/components/campanias/patrocinadores";

export default {
  name: 'ClubNegocios2',
  data() {
    return {
      countdown: "00:00:00",
      faqs: [
        { question: "¿Cómo funciona la afiliación?", answer: "Solo debes registrarte y pagar tu membresia de negocios.", open: false },
        { question: "¿Cuánto cuesta la membresía?", answer: "Es anual y tiene un costo accesible. Pregunta por nuestras promociones.", open: false },
        { question: "¿Cómo recibo mis ingresos extra?", answer: "Recibirás comisiones directas en tu cuenta dentro de la aplicación.", open: false }
      ]
    }
  },
  components: {
    patrocinadoresComp
  },
  props: {
    patrocinadores: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    registroRoute() {
      return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
          '/registro'
    }
  },
  mounted() {
    fbq('init', '1351295932570313')
    fbq('track', 'PageView')
    this.startCountdown()
  },
  methods: {
    startCountdown() {
      const targetDate = new Date()
      targetDate.setDate(targetDate.getDate() + 1)
      setInterval(() => {
        const now = new Date().getTime()
        const timeLeft = targetDate - now
        const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24)
        const minutes = Math.floor((timeLeft / (1000 * 60)) % 60)
        const seconds = Math.floor((timeLeft / 1000) % 60)
        this.countdown = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      }, 1000)
    },
    toggleFAQ(index) {
      this.faqs[index].open = !this.faqs[index].open
    }
  }
}
</script>

<style scoped>
/* Estilo adicional para pantallas pequeñas */
@media (max-width: 568px) {
  .vsc {
    padding: 0.5rem;
  }

  .vsc h1 {
    font-size: 1rem;
  }
}
</style>