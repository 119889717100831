<template>
    <!-- Sección Parallax Inicial -->
    <div class="parallax">
        <div class="flex flex-col items-center justify-center text-center px-4">
            <img src="@/assets/img/vsc.png" alt="publicidad"
                class="w-40 h-30 mx-auto animate__animated animate__zoomInUp">
            <h1 class="mt-8 text-5xl text-white font-extrabold animate__animated animate__zoomInLeft animate__slow">
                Gana Más con Cada Compra que Realices
            </h1>
            <p class="text-gray-800 mt-10 text-lg animate__animated animate__zoomInRight animate__slower">
                Sé un Cliente con Beneficios y Únete a <b>Virtual Shopping Club</b> y recibe puntos, tecnología y
                grandes bonos en efectivo por tus gastos comunes y diarios.
            </p>
            <router-link :to="registroRoute"
                class="mt-12 mb-8 inline-block px-9 py-3 text-white font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.7)]">
                ¡Registrate Gratis!
            </router-link>
        </div>
    </div>

    <div class="mt-12 mb-12">
        <h2 class="text-3xl font-extrabold text-center animated-gradient">
            "Recompensas desde Pesitos hasta $500,000 en bono especial"
        </h2>
    </div>

    <!-- Video de YouTube -->
    <div class="mx-auto max-w-4xl px-6 lg:px-8 mt-16">
        <div class="aspect-w-16 aspect-h-9">
            <iframe class="absolute rounded-lg  top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/Jqf82di40iI" frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </div>
    </div>


    <!-- Sección: Beneficios y Oportunidades -->
    <div class="parallax mt-16">
        <div class=" py-8">
            <div class="max-w-7xl mx-auto px-6 lg:px-8">
                <section class="p-6 px-8 flex flex-col md:flex-row items-center gap-6 mb-20">
                    <!-- Imagen -->
                    <img src="@/assets/img/vsc.png" alt="Insignia Gestor" class="w-40 h-30">
                    <!-- Texto -->
                    <div class="text-center md:text-left">
                        <h2 class="text-2xl font-bold text-gray-700 mb-2">¿Quienes somos?</h2>
                        <p class="text-gray-700 text-justify">
                            Somos una comunidad que transforma lo cotidiano en oportunidades. Virtual Shopping Club es
                            una
                            aplicación
                            innovadora que convierte tus gastos comunes y los de tus amigos en ingresos. <br><br>

                            Cada vez que pagas servicios, haces una recarga o compras en nuestros comercios afiliados,
                            generas
                            recompensas . Además, al recomendar la aplicación, multiplicas tus beneficios.

                            Gasta lo mismo, pero gana más.
                        </p>
                    </div>
                </section>


                <!-- Nuevo texto agregado -->
                <h2 class="text-3xl font-extrabold text-center text-gray-700 mb-8">
                    Recibe Puntos y Tecnología por tus compras, que te da beneficios por:
                </h2>
                <section class="grid grid-cols-1 md:grid-cols-2 gap-8 items-center mb-2 mt-8">
                    <!-- Lugares de produccion -->
                    <div
                        class="bg-orange-100 shadow-lg rounded-lg p-6 flex flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
                        <img src="https://img.icons8.com/color/96/shopping-cart--v1.png" alt="Beneficio 1"
                            class="w-20 h-20 rounded-full object-cover">
                        <div>
                            <h2 class="text-2xl font-bold text-gray-600 mb-2">Las compras de la comunidad</h2>
                            <p class="text-gray-700 text-justify">
                                ¡Deja que nuestros <span class="font-bold">"lugares de producción"</span> hagan el
                                trabajo por ti! Según
                                los productos que compres 🛒,
                                podrás recibir beneficios de las futuras compras de la comunidad. 🤝✨
                            </p>
                        </div>
                    </div>

                    <!-- Nodos solidaridad -->
                    <div
                        class="bg-orange-100 shadow-lg rounded-lg p-6 flex flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
                        <img src="https://img.icons8.com/color/96/coins.png" alt="Beneficio 2"
                            class="w-20 h-20 rounded-full object-cover">
                        <div>
                            <h2 class="text-2xl font-bold text-gray-600 mb-2">Tus compras acumuladas</h2>
                            <p class="text-gray-700 text-justify">
                                ¡Los <span class="font-bold">"nodos solidaridad"</span> son tecnología gratuita que
                                obtienes con tus
                                compras! Te ayudan a recibir
                                beneficios basados en el volumen de compras de los productos. 🚀🌟
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>

    <div class="bono mt-16">
        <h2 class="text-2xl text-center font-extrabold text-gray-700 mb-6">
            Más Formas de Beneficiarte con:
        </h2>
        <!-- Tarjeta VSC -->
        <div
            class="tarjetavsc max-w-4xl mx-auto text-center mb-14 bg-orange-100 shadow-lg rounded-lg p-6 flex flex-col sm:flex-row items-center gap-6 hover:shadow-2xl transform hover:-translate-y-1 transition duration-300">
            <!-- Imagen -->
            <img src="@/assets/img/tarjetavsc.png" alt="Tarjeta VSC"
                class="tarjetavsc-img max-h-40 object-cover mx-auto sm:mx-0">

            <!-- Contenido -->
            <div class="text-center sm:text-left">
                <h2 class="text-2xl font-extrabold text-gray-700 mb-4 sm:mb-2">
                    Tarjeta Alianza VSC
                </h2>

                <p class="text-gray-700 text-justify">
                    Multiplica tus ganancias y obtén 3 beneficios adicionales con las insignias:<br>
                    <span class="font-bold">V:</span> Bono por la venta de la Tarjeta Alianza<br>
                    <span class="font-bold">S:</span> Bono por compras en Negocios Gestionados<br>
                    <span class="font-bold">C:</span> Bono por ventas de Negocios Patrocinadores
                </p>

                <!-- Sección de precios con figura geométrica y animación -->
                <div class="mt-6 flex justify-center">
                    <div class="w-60 h-36 bg-gradient-to-r from-green-400 to-green-600 rounded-lg relative animate-pulse"
                        style="clip-path: polygon(0 20%, 50% 0, 100% 20%, 100% 80%, 50% 100%, 0 80%);">
                        <div class="absolute inset-0 flex flex-col items-center justify-center">
                            <p class="text-lg text-white">
                                Precio Real: <span class="line-through text-red-500">$6,000</span>
                            </p>
                            <p class="text-2xl font-bold text-white">
                                Promoción: $2,000
                            </p>
                            <p class="text-lg font-bold text-white">
                                + $1,000 en saldo
                            </p>
                            <p class="text-sm text-white">primer mes de registro</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="mt-16 px-4 mb-12">
        <h1 class="text-3xl text-center font-extrabold text-gray-800 mb-8">
            Supermercado Tradicional vs Virtual Shopping Club
        </h1>
        <!-- Cambiamos items-start por items-stretch para forzar que ambos hijos tengan la misma altura -->
        <div class="flex flex-col md:flex-row justify-around items-stretch gap-8">
            <!-- Columna: Supermercado Tradicional -->
            <div class="flex-1 text-center p-6 bg-orange-100 rounded-lg shadow-md">
                <h2 class="text-2xl font-bold text-gray-700 mb-4">Supermercado Tradicional</h2>
                <ul class="list-disc list-inside text-gray-700 text-lg text-left">
                    <li class="flex items-center"><span class="text-xl">❌</span> <span class="ml-2">Con tu
                            dinero.</span></li>
                    <li class="flex items-center"><span class="text-xl">❌</span> <span class="ml-2">Compras.</span></li>
                    <li class="flex items-center"><span class="text-xl">❌</span> <span class="ml-2">Gastas.</span></li>
                </ul>
            </div>
            <!-- Columna: Virtual Shopping Club -->
            <div class="flex-1 text-center p-6 bg-green-100 rounded-lg shadow-md">
                <h2 class="text-2xl font-bold text-green-700 mb-4">Virtual Shopping Club</h2>
                <ul class="list-disc list-inside text-gray-700 text-lg text-left">
                    <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Fondeas tu
                            cuenta.</span></li>
                    <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Compras en la
                            app.</span></li>
                    <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Obtienes puntos y
                            tecnología.</span></li>
                    <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Usas tus puntos para
                            tus compras o gastos.</span></li>
                    <li class="flex items-center"><span class="text-xl">✅</span> <span class="ml-2">Obtienes más
                            puntos.</span></li>
                </ul>
            </div>
        </div>
    </div>


    <div class="bono bg-linear-to-r from-green-300 via-orange-300 to-green-300  mb-14 pt-6 pb-6">
        <h1 class="text-gray-700 text-3xl font-serif font-extrabold text-center">
            Bono de referencias efectivas de $1.82 a más de $16,000,000
        </h1>
    </div>


    <!-- Sección: Preguntas Frecuentes -->
    <div class="px-8">
        <section class="bg-gray-100 rounded-lg p-8 shadow-lg mt-14 mb-16">
            <h2 class="text-3xl text-gray-700 font-semibold text-center">Preguntas Frecuentes</h2>
            <div class="mt-8">
                <details class="mb-4 px-8">
                    <summary class="text-gray-700 font-semibold cursor-pointer bg-gray-200 px-4 py-2 rounded-lg">
                        ¿Cómo me registro en Virtual Shopping Club?
                    </summary>
                    <p class="mt-2 px-4 text-gray-700 text-justify">
                        El registro es completamente gratuito y sencillo. Solo debes hacer clic en los botones
                        “Regístrate gratis”
                        o “Saber Más” que se encuentran a lo largo de la página, y seguir el proceso indicado.
                    </p>
                </details>
                <details class="mb-4 px-8">
                    <summary class="text-gray-700 font-semibold cursor-pointer bg-gray-200 px-4 py-2 rounded-lg">
                        ¿Cuáles son los beneficios de unirme?
                    </summary>
                    <p class="mt-2 px-4 text-gray-700 text-justify">
                        Al ser parte de la comunidad, podrás recibir recompensas que van desde puntos acumulables y
                        tecnología gratuita
                        hasta bonos especiales con montos que pueden ir desde pequeños beneficios hasta recompensas de
                        hasta $500,000. Además,
                        si adquieres la Tarjeta Alianza VSC, multiplicarás tus ganancias con beneficios adicionales por
                        ventas, compras y referidos.
                    </p>
                </details>
                <details class="mb-4 px-8">
                    <summary class="text-gray-700 font-semibold cursor-pointer bg-gray-200 px-4 py-2 rounded-lg">
                        ¿Qué es la aplicación VSC y cómo me beneficia?
                    </summary>
                    <p class="mt-2 px-4 text-gray-700 text-justify">
                        VSC es una herramienta innovadora que transforma tus gastos cotidianos en oportunidades de
                        ingresos. Con
                        esta aplicación, podrás convertir compras
                        y gastos en formas de generar ganancias, haciendo que cada gasto sea una inversión en tu futuro.
                    </p>
                </details>
                <details class="mb-4 px-8">
                    <summary class="text-gray-700 font-semibold cursor-pointer bg-gray-200 px-4 py-2 rounded-lg">
                        ¿Cómo puedo obtener más información o contactar al equipo de soporte?
                    </summary>
                    <p class="mt-2 px-4 text-gray-700 text-justify">
                        Si tienes dudas o necesitas asistencia, puedes comunicarte con nosotros a través de nuestro
                        formulario de
                        contacto o por nuestros canales
                        oficiales de atención. ¡Estamos aquí para ayudarte en cada paso!
                    </p>
                </details>
            </div>
        </section>
    </div>

    <!-- Llamada a la acción -->
    <div class="parallax bg-gray-600 py-16 relative z-20 mt-16">
        <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
            <h2 class="text-4xl font-extrabold text-white animate-bounce">
                ¡No Esperes Más para Ser Parte de Virtual Shopping Club!
            </h2>
            <img src="@/assets/img/vsc.png" alt="publicidad" class="w-40 h-30 mx-auto mb-4">
            <p class="mt-4 px-2 text-gray-700 text-lg max-w-2xl mx-auto">
                Regístrate ahora y comienza a disfrutar de todos los beneficios exclusivos que tenemos para ti.
            </p>
            <router-link :to="registroRoute"
                class="mt-12 mb-8 inline-block px-9 py-3 text-white font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.7)]">
                ¡Quiero ser Un Cliente con Beneficios!
            </router-link>
        </div>
    </div>


    <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>
</template>

<script>
import patrocinadoresComp from "@/components/campanias/patrocinadores"

export default {
    name: 'clientes2',
    components: {
        patrocinadoresComp
    },
    props: {
        patrocinadores: {
            type: Array,
            default: () => []
        }
    },
    mounted() {
      fbq('init', '1351295932570313')
      fbq('track', 'PageView')
    },
    computed: {
        registroRoute() {
            return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
                '/registro'
        }
    }
}
</script>

<style>
/* Efecto Parallax */
.parallax {
    background-image: url('@/assets/img/img.png');
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Contenido */
.content {
    height: 400px;
    background-color: #fff;
    text-align: center;
    padding: 50px;
}

@keyframes gradientChange {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.gradient-bg {
    background: linear-gradient(45deg, #588e60, #df9c30, #588e60, #df9c30);
    background-size: 400% 400%;
    animation: gradientChange 4s ease infinite;
}

.animated-gradient {
    animation: pulse 2s infinite;
    font-size: 2rem;
    /* Ajusta el tamaño */
    font-weight: 800;
    /* Fuente extrabold */
    text-align: center;
    /* Centrar texto */
    background: linear-gradient(90deg, #588e60, #df9c30, #588e60);
    /* Gradiente de 3 colores */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    /* El gradiente aparecerá en lugar del color sólido */
    animation: gradient-animation 3s infinite;
    /* Añade la animación */
}

@keyframes gradient-animation {

    0%,
    100% {
        background-position: 0% 50%;
        transform: scale(0.8);
    }

    50% {
        background-position: 100% 50%;
        transform: scale(0.9);
    }
}

/* Estilo adicional para pantallas pequeñas */
@media (max-width: 568px) {

    .tarjetavsc-img {
        width: 10rem;
        /* Reducir tamaño de la imagen */
        height: 6rem;
        margin-bottom: 0.1rem;
        /* Añadir espacio inferior entre la imagen y el contenido */
    }

    .tarjetavsc h2 {
        margin-bottom: 0.5rem;
        /* Reducir margen inferior del título */
    }

    .bono {
        padding: 0.5rem
    }

    .bono h1 {
        font-size: 1.2rem;
        ;
    }

    .bono h2 {
        font-size: 1.2rem;
        ;
    }
}
</style>