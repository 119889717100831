<template>
  <div class="min-h-screen bg-linear-to-r from-green-600 to-green-400 text-white">

    <!-- Hero -->
    <div class="flex flex-col items-center text-center py-30 px-6 bg-cover bg-center"
      :style="{ backgroundImage: `url(${require('@/assets/img/fondoNegocio.jpg')})` }">
      <!-- Overlay oscuro para legibilidad -->

      <h1 class="text-5xl font-extrabold animate__animated animate__bounceInLeft">
        Publicidad Efectiva = <span class="text-yellow-400">Más Clientes, Más Ventas</span>
      </h1>
      <p class="mt-8 text-lg max-w-3xl">
        El <span class=" text-yellow-400 font-extrabold">80%</span> de los negocios gastan en publicidad sin ver
        resultados.
        En <span class=" text-yellow-400 font-extrabold">Virtual Shopping Club</span> tu negocio llega directamente a
        compradores interesados.
      </p>
      <router-link :to="registroRoute" class="mt-6 px-8 py-4 bg-white text-green-700 font-bold rounded-full shadow-xl hover:bg-gray-200
                            transition transform hover:scale-110">
        ¡Únete y haz crecer tus ventas!
      </router-link>
    </div>

    <!-- Sección de Beneficios -->
    <div class="py-16 bg-white text-gray-800 px-6">
      <h2 class="text-center text-4xl font-bold text-green-700">💡 Beneficios Exclusivos</h2>
      <div class="mt-10 grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">

        <div
          class="flex flex-col items-center p-6 bg-gray-200 rounded-lg shadow-lg transition hover:shadow-[0_4px_15px_rgba(25,187,0,0.8)]">
          <img src="@/assets/img/publicidad.png" alt="Icono de publicidad" class="w-17 h-16 mx-auto mb-4">
          <h3 class="text-xl font-semibold mt-4">Publicidad Efectiva</h3>
          <p class="mt-2 text-center">Pon tu negocio frente a millas de clientes dentro de Virtual Shopping Club. ¡Que
            te vean y te elijan!.</p>
        </div>

        <div
          class="flex flex-col items-center p-6 bg-gray-200 rounded-lg shadow-lg transition hover:shadow-[0_4px_15px_rgba(25,187,0,0.8)]">
          <img src="@/assets/img/VentasEfect.png" alt="Icono depublicidad" class="w-17 h-16 mx-auto mb-4">
          <h3 class="text-xl font-semibold mt-4">Más clientes, más ventas </h3>
          <p class="mt-2 text-center">Conéctate con compradores listos para comprar y aumenta tus ingresos sin esfuerzo.
          </p>
        </div>
      </div>

      <div class="mt-10 grid grid-cols-1 md:grid-cols-4 gap-8 max-w-6xl mx-auto">
        <div
          class="flex flex-col items-center p-6 bg-gray-200 rounded-lg shadow-lg transition hover:shadow-[0_4px_15px_rgba(25,187,0,0.8)]">
          <img src="@/assets/img/ingresosExt.png" alt="Icono de publicidad" class="w-16 h-16 mx-auto mb-4">
          <h3 class="text-xl text-center font-semibold mt-4">Gana más sin vender más</h3>
          <p class="mt-2 text-center">Además de tus ventas, recibe bonos extra con una herramienta exclusiva para
            negocios.</p>
        </div>

        <div
          class="flex flex-col items-center p-6 bg-gray-200 rounded-lg shadow-lg transition hover:shadow-[0_4px_15px_rgba(25,187,0,0.8)]">
          <img src="@/assets/img/comunidad.png" alt="Icono de publicidad" class="w-17 h-16 mx-auto mb-4">
          <h3 class="text-xl text-center font-semibold mt-4">Únete al Club de Negocios Afiliados</h3>
          <p class="mt-2 text-center">Sé parte de una comunidad que impulsa el comercio local y premia a quienes confían
            en ella. </p>
        </div>

        <div
          class="flex flex-col items-center p-6 bg-gray-200 rounded-lg shadow-lg transition hover:shadow-[0_4px_15px_rgba(25,187,0,0.8)]">
          <img src="@/assets/img/proveedor.png" alt="Icono de publicidad" class="w-17 h-16 mx-auto mb-4">
          <h3 class="text-xl text-center font-semibold mt-4">Proveedor de la Comunidad VSC</h3>
          <p class="mt-2 text-center">Además de tus ventas, recibe bonos extra con una herramienta exclusiva para
            negocios.</p>
        </div>

        <div
          class="flex flex-col items-center p-6 bg-gray-200 rounded-lg shadow-lg transition hover:shadow-[0_4px_15px_rgba(25,187,0,0.8)]">
          <img src="@/assets/img/F3.png" alt="Icono de publicidad" class="w-16 h-16 mx-auto mb-4">
          <h3 class="text-xl text-center font-semibold mt-4">¡Oferta especial!</h3>
          <p class="mt-2 text-center">Los primeros 100 negocios en afiliarse en tu zona reciben una recompensa
            exclusiva.</p>
        </div>

      </div>
    </div>

    <!-- Sección de Oferta Especial f3 -->
    <div class="bg-gradient-to-r from-green-600 to-green-400 py-16 px-6">
      <div class="flex flex-col md:flex-row items-center max-w-6xl mx-auto">

        <!-- Imagen (Encima en móviles, a la derecha en pantallas grandes) -->
        <div class="w-full md:w-1/4 flex justify-center mb-6 md:mb-0">
          <img src="@/assets/img/cardVSC.png" alt="Icono de publicidad" class="w-75 h-54">
        </div>

        <!-- Contenido (75% en pantallas grandes) -->
        <div class="w-full md:w-3/4 text-white md:pr-6 text-center md:text-left">
          <h3 class="text-3xl font-bold">¡Promoción especial para los primeros 100 negocios de la zona!</h3>
          <p class="mt-2 text-lg text-justify">
            Si eres de los primeros 100 comercios en unirte al Club de Negocios Afiliados por medio de tu membresía, recibirás sin costo el acceso al
             <span class="font-bold text-yellow-400">Programa F3</span> (valorado en $10,000 MXN),
             una herramienta diseñada para multiplicar tus ingresos. Con ella, ganarás bonos mensuales basados ​​en las ventas de los negocios 
             afiliados en tu zona participante. <br><br>
             ¡Es como recibir una renta mensual sin esfuerzo!
        
          </p>
        </div>

      </div>

      <!-- Botón de llamada a la acción -->
      <div class="mt-6 text-center">
        <router-link :to="registroRoute"
          class="px-8 py-4 bg-white text-green-700 font-bold rounded-full hover:bg-yellow-400 hover:text-white">
          ¡Quiero ser uno de los 100!
        </router-link>
      </div>
    </div>

    <!-- Sección de Testimonios -->
    <div class="py-16 px-6 bg-gray-100">
      <h3 class="text-center text-4xl font-bold text-green-700">📢 Lo Que Dicen Nuestros Socios</h3>
      <div class="mt-10 grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">

        <div class="bg-white p-6 rounded-lg shadow-lg flex flex-col">
          <p class="italic text-lg text-gray-800">"Desde que me uní a Virtual Shopping Club, mis ventas han aumentado y
            mis ingresos pasivos han crecido mes a mes."</p>
          <p class="mt-2 font-semibold text-right text-green-700">— Dr. Ortega, Consultorio Vida Nueva y Nutrición</p>
        </div>

        <div class="bg-white p-6 rounded-lg shadow-lg flex flex-col">
          <p class="italic text-lg text-gray-800">"Ser parte de este club ha sido un cambio radical en mi negocio. ¡Muy
            recomendado!"</p>
          <p class="mt-2 font-semibold text-right text-green-700">— Comercializadora del ahorro OP</p>
        </div>

      </div>
    </div>

    <!-- Sección de Preguntas Frecuentes -->
    <div class="py-16 px-6 text-center bg-white text-gray-800">
      <h3 class="text-3xl font-bold text-green-700">❓ Preguntas Frecuentes</h3>
      <details class="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
        <summary class="font-semibold text-lg cursor-pointer"> ¿Qué necesito para unirme al club?</summary>
        <p class="mt-2">Solo necesitas tener un negocio físico, una actitud amable y de servicio, ¡y listo!
          obtén tu membresía y empieza a disfrutar de los beneficios.</p>
      </details>
      <details class="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
        <summary class="font-semibold text-lg cursor-pointer"> ¿Qué negocios pueden participar?</summary>
        <p class="mt-2">¡Cualquiera! No importa si vendes un producto o prestas un servicio, puedes unirte al
          club.
          Lo único que necesitas es que tu negocio sea legal y pueda emitir facturas.</p>
      </details>
      <details class="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
        <summary class="font-semibold text-lg cursor-pointer"> ¿Dónde se mostrará la publicidad de mi negocio?
        </summary>
        <p class="mt-2"> Si eres uno de los primeros 100 negocios de la zona en unirte, tendrás prioridad para
          aparecer
          en nuestra aplicación. Además, podrás tener la oportunidad de figurar en presentaciones virtuales,
          campañas publicitarias o material promocional.</p>
      </details>
      <details class="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
        <summary class="font-semibold text-lg cursor-pointer"> No sé mucho de marketing, ¿es necesario para
          unirme al club?</summary>
        <p class="mt-2"> ¡Para nada! No importa si eres experto en marketing o no. Aquí trabajarás con un
          experto que te ayudará con las ventas, publicidad y hacer crecer tus contactos.</p>
      </details>
    </div>

    <!-- CTA Final -->
    <div class="bg-gradient-to-r from-green-600 to-green-400 text-center py-12">
      <h2 class="text-3xl text-yellow-400 font-extrabold text-white">
        ¡Únete Hoy y Lleva Tu Negocio Al Siguiente Nivel!
      </h2>
      <img src="@/assets/img/vsc.png" alt="publicidad" class="w-40 h-30 mx-auto mt-4">
      <p class="text-lg text-blue-100 mt-6 mb-8">
        Regístrate y comienza a disfrutar de todos los beneficios exclusivos para miembros del Club de
        Negocios.
      </p>
      <router-link :to="registroRoute" class="mt-4 px-8 py-4 bg-white text-green-700 font-bold rounded-full hover:bg-yellow-400
                            hover:text-white transition ">
        Unirme Ahora
      </router-link>
    </div>

    <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>
  </div>
</template>

<script>
import patrocinadoresComp from "@/components/campanias/patrocinadores"

export default {
  name: 'ClubNegocios3',
  components: {
    patrocinadoresComp
  },
  props: {
    patrocinadores: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    registroRoute() {
      return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
        '/registro'
    }
  },
  mounted() {
    fbq('init', '1351295932570313')
    fbq('track', 'PageView')
  }
}
</script>