<template>
    <div class="relative bg-orange-100 pb-6 overflow-hidden">

        <!-- Hero Section -->
        <div
            class="bg-linear-to-r from-yellow-500 to-orange-500 flex flex-col items-center pt-12 pb-16 px-6 sm:px-12 lg:px-24 text-center">
            <h4 class="text-5xl font-serif font-bold text-white animate__animated animate__fadeInBottomRight">
                Clientes fieles, <span class="text-yellow-900">más ingresos</span> y cero estrés
            </h4>
            <img src="@/assets/img/vsc.png" alt="publicidad"
                class="w-40 h-30 mx-auto mt-4 animate__animated animate__fadeInUp">
            <p class="mt-6 text-lg text-white max-w-3xl animate__animated animate__fadeInBottomLeft">
                Únete a Virtual Shopping Club y recibe clientes listos para comprar en tu negocio. <br><b>¡El momento de
                    crecer es ahora!</b>
            </p>
            <router-link :to="registroRoute" class="mt-6 px-8 py-3 bg-red-500 text-white font-bold text-lg rounded-full
                                  hover:bg-green-500 hover:text-white transition-all">
                ¡Regístrate Ahora!
            </router-link>
        </div>

        <div class=" mx-auto text-center mt-12">
            <div class="bg-gray-100 p-6 rounded-lg shadow-lg text-center border border-gray-100">
                <h3 class="text-xl font-bold text-red-500">❌ ¿Pocas ventas y clientes que solo compran una vez?</h3>
                <p class="text-gray-700 mt-2 text-center">
                    Si los clientes llegan, compran una vez y no vuelven, necesitas una estrategia diferente.
                </p>
            </div>
        </div>

        <!-- Sección de Beneficios -->
        <div class="py-16 bg-orange-100">
            <h2 class="text-4xl font-serif font-bold text-center text-orange-800 mb-12">
                En Virtual Shopping Club te ayudamos a tener:
            </h2>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-7xl mx-auto px-6 lg:px-12">
                <div
                    class="bg-white border border-orange-200 p-6 rounded-lg shadow-lg text-center hover:shadow-xl transition">
                    <h3 class="text-2xl font-serif font-semibold text-orange-700">💰 Ventas Constantes</h3>
                    <p class="mt-2 text-gray-600"> Tu negocio será la primera opción de nuestra comunidad</p>
                </div>
                <div
                    class="bg-white border border-orange-200 p-6 rounded-lg shadow-lg text-center hover:shadow-xl transition">
                    <h3 class="text-2xl font-serif font-semibold text-orange-700">📢 Mayor Visibilidad</h3>
                    <p class="mt-2 text-gray-600">Nosotros te damos mayor visibilidad dentro de una comunidad de
                        compradores listos
                        para adquirir tus productos o servicios</p>
                </div>

                <div
                    class="bg-white border border-orange-200 p-6 rounded-lg shadow-lg text-center hover:shadow-xl transition">
                    <h3 class="text-2xl font-serif font-semibold text-orange-700">🏆 Clientes Leales</h3>
                    <p class="mt-2 text-gray-600">Con Virtual Shopping Club, cada compra en tu negocio les da puntos que
                        los motivan a volver una y otra vez</p>
                </div>
            </div>
        </div>


        <!-- Programa de Ingresos Extras -->
        <div class="py-16 bg-linear-to-r from-orange-500 to-yellow-500 text-white text-center">
            <h2 class="text-4xl font-serif font-bold">💸 Tus ventas no tienen que ser tu única <span
                    class="text-yellow-900">fuente de ingresos.</span></h2>
            <p class="mt-6 max-w-3xl mx-auto text-lg px-2">
                Con el Programa Fusión, tus recomendaciones y contactos pueden convertirse en ingresos sin que tengas
                que hacer mucho.
                Solo conectas negocios y personas a Virtual Shopping Club, y un Gestor se encarga de todo: explicar, dar
                seguimiento y cerrar ventas. Si tus referidos se registran, puedes ganar desde un 20% hasta un 60% de
                sus ganancias.
            </p>
            <img src="@/assets/img/ingresosExt.png" alt="Ingresos" class="w-40 mx-auto my-6">
            <router-link :to="registroRoute"
                class="px-8 py-4 bg-white text-orange-800 font-bold text-lg rounded-full hover:bg-green-500 hover:text-white transition">
                ¡Descubre cómo funciona!🔥
            </router-link>
        </div>

        <!-- Video Explicativo -->
        <div class="py-16 bg-orange-100 text-center">

            <!-- Video de YouTube -->
            <div class="mx-auto max-w-4xl px-6 lg:px-8">
                <div class="aspect-w-16 aspect-h-9">
                    <iframe class="absolute rounded-lg  top-0 left-0 w-full h-full"
                        src="https://www.youtube.com/embed/M4o5Zc7c3fQ" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <!-- Programa f3 -->
        <div class=" max-w-4xl mx-auto text-center bg-orange-500 rounded-lg p-6 mt-2 bg-cover bg-center"
            :style="{ backgroundImage: `url(${require('@/assets/img/fondoNaranja.jpg')})` }">
            <div class="max-w-7xl mx-auto px-6 lg:px-8">
                <img src="@/assets/img/cardVSC.png" alt="Icono de publicidad" class="w-75 h-54 mx-auto mb-5">
                <h2 class="neon-black text-3xl font-serif font-extrabold text-center text-white mb-5 ">
                    Únete al club y aumenta tus ventas
                </h2>
                <p class="neon-black text-white text-lg leading-relaxed mb-12">
                    Como miembro del Club de Negocios, tu marca tendrá más difusión, ventas constantes e ingresos extra
                    sin esfuerzo. Además, contarás con
                    el apoyo de un experto en ventas y marketing. <br><br>

                    <span class="font-bold">🎁 Accede a una herramienta exclusiva, ¡si eres de los primeros 100 negocios
                        de la zona en unirse! </span><br>
                    Obtén de cortesía una herramienta con la cual podrás recibir un bono mensual por todas las ventas
                    realizadas en los negocios afiliados de
                    las zonas en que estés participando en el programa. No tienes que vender nada, solo aprovechar el
                    movimiento de la red de negocios. ¡Así de fácil! 💰🚀
                </p>
                <div class="neon-black mb-10 pt-2 pb-2 text-5xl font-bold ">
                    ⏳ <span class="neon-text text-red-700 text-5xl">{{ countdown }}</span>
                </div>
                <router-link :to="registroRoute" class="inline-block px-8 py-3 bg-white text-orange-700 font-bold text-lg rounded-lg
                                      shadow-lg hover:bg-green-600 hover:text-white transform hover:scale-105
                                      transition-all duration-300 mb-6">
                    ¡Aprovecha esta oferta exclusiva!
                </router-link>
            </div>
        </div>

        <!--por que unirse-->
        <section class="py-12 mt-8">
            <div class="max-w-4xl mx-auto text-center">
                <h2 class="text-3xl font-bold text-orange-800 mb-6">💡 ¿Por qué unirte?</h2>

                <div class="grid grid-cols-1 md:grid-cols-4 gap-6">
                    <!-- Carga Administrativa Pesada -->
                    <div
                        class="bg-linear-to-b from-yellow-500 to-orange-500 p-6 rounded-lg shadow-lg text-center border border-blue-400 hover:border-blue-700">
                        <h3 class="text-xl font-bold text-white">✅ Ventas recurrentes</h3>
                        <p class="text-white mt-2 text-justify">Tus clientes ganan puntos en Virtual Shopping Club por
                            comprar contigo y regresan
                            por más</p>
                    </div>

                    <!-- Falta de Personalización -->
                    <div
                        class="bg-linear-to-b from-yellow-500 to-orange-500 p-6 rounded-lg shadow-lg text-center border border-blue-400 hover:border-blue-700">
                        <h3 class="text-xl font-bold text-white">✅ Más publicidad, sin complicaciones</h3>
                        <p class="text-white mt-2 text-justify">Te ayudamos a que más personas dentro de la comunidad de
                            Virtual Shopping Club
                            y en otros espacios te conozcan, haciendo que tu marca tenga más presencia en el mercado</p>
                    </div>

                    <div
                        class="bg-linear-to-b from-yellow-500 to-orange-500 p-6 rounded-lg shadow-lg text-center border border-blue-400 hover:border-blue-700">
                        <h3 class="text-xl font-bold text-white">✅ Clientes fieles</h3>
                        <p class="text-white mt-2 text-justify">Olvídate de los clientes que solo compran una vez. En
                            nuestro club conectarás con
                            clientes que regresan y recomiendan.</p>
                    </div>

                    <div
                        class="bg-linear-to-b from-yellow-500 to-orange-500 p-6 rounded-lg shadow-lg text-center border border-blue-400 hover:border-blue-700">
                        <h3 class="text-xl font-bold text-white">✅ Gana dinero extra sin hacer más trabajo.</h3>
                        <p class="text-white mt-2 text-justify">Además de vender, puedes generar ingresos adicionales
                            con una herramienta exclusiva para negocios</p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Sección de Preguntas Frecuentes -->
        <div class="py-16 px-6 text-center text-gray-800">
            <h3 class="text-3xl font-bold text-orange-700"> Preguntas Frecuentes❓</h3>
            <details class="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
                <summary class="font-semibold text-lg cursor-pointer"> ¿Qué necesito para unirme al club?</summary>
                <p class="mt-2">Solo necesitas tener un negocio físico, una actitud amable y de servicio, ¡y listo!
                    obtén tu membresía y empieza a disfrutar de los beneficios.</p>
            </details>
            <details class="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
                <summary class="font-semibold text-lg cursor-pointer"> ¿Qué negocios pueden participar?</summary>
                <p class="mt-2">¡Cualquiera! No importa si vendes un producto o prestas un servicio, puedes unirte al
                    club.
                    Lo único que necesitas es que tu negocio sea legal y pueda emitir facturas.</p>
            </details>
            <details class="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
                <summary class="font-semibold text-lg cursor-pointer"> ¿Dónde se mostrará la publicidad de mi negocio?
                </summary>
                <p class="mt-2"> Si eres uno de los primeros 100 negocios de la zona en unirte, tendrás prioridad para
                    aparecer
                    en nuestra aplicación. Además, podrás tener la oportunidad de figurar en presentaciones virtuales,
                    campañas publicitarias o material promocional.</p>
            </details>
            <details class="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
                <summary class="font-semibold text-lg cursor-pointer"> No sé mucho de marketing, ¿es necesario para
                    unirme al club?</summary>
                <p class="mt-2"> ¡Para nada! No importa si eres experto en marketing o no. Aquí trabajarás con un
                    experto que te ayudará con las ventas, publicidad y hacer crecer tus contactos.</p>
            </details>
        </div>

        <!-- Llamada a la acción -->
        <div class="bg-linear-to-r from-orange-500 to-yellow-500 py-16 relative z-20 mt-14">
            <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                <h2 class="text-4xl text-white font-serif font-extrabold text-white animate-pulse">
                    💸 ¡Ventas aseguradas para tu negocio! 💸
                </h2>
                <img src="@/assets/img/vsc.png" alt="publicidad" class="w-40 h-30 mx-auto mt-4 mb-4">
                <router-link :to="registroRoute" class="inline-block px-8 py-4 bg-white text-orange-800 font-bold text-lg rounded-full
                                      shadow-lg hover:bg-green-600 hover:text-white transform hover:scale-105
                                      transition-all duration-300">
                    ¡Si, quiero mas ventas!
                </router-link>
            </div>
        </div>

        <patrocinadores-comp :patrocinadores="patrocinadores" v-if="patrocinadores.length > 0"></patrocinadores-comp>
    </div>
</template>

<script>
import patrocinadoresComp from "@/components/campanias/patrocinadores";

export default {
    name: 'ClubNegocios4',
    data() {
        return {
            countdown: "00:00:00",
        }
    },
    components: {
        patrocinadoresComp
    },
    props: {
        patrocinadores: {
            type: Array,
            default: () => []
        }
    },
    mounted() {
      fbq('init', '1351295932570313')
      fbq('track', 'PageView')
    },
    computed: {
        registroRoute() {
            return typeof this.$route.params.codigo !== 'undefined' ? `/registro/campania/${this.$route.params.codigo}` :
                '/registro'
        }
    },
    mounted() {
        this.startCountdown();
    },
    methods: {
        startCountdown() {
            this.updateCountdown(); // Llamar una vez al inicio para evitar el retraso del setInterval
            setInterval(this.updateCountdown, 1000);
        },
        updateCountdown() {
            const now = new Date();
            const midnight = new Date();

            // Configurar la fecha para la siguiente medianoche
            midnight.setHours(24, 0, 0, 0);

            const timeLeft = midnight - now; // Diferencia en milisegundos

            const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
            const seconds = Math.floor((timeLeft / 1000) % 60);

            this.countdown = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
        }
    }
}
</script>

<style scoped>
/* Estilos personalizados */
.neon-text {
    text-shadow: 0 0 90px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff;
}

.neon-black {
    text-shadow: 0 0 10px #000000, 0 0 10px #000000, 0 0 20px #000000;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

h1,
h2,
h3 {
    animation: fadeIn 1s ease-in-out;
}

.animated-gradient {
    animation: pulse 2s infinite;
    font-size: 2rem;
    /* Ajusta el tamaño */
    font-weight: 800;
    /* Fuente extrabold */
    text-align: center;
    /* Centrar texto */
    background: linear-gradient(90deg, #ffa52f, #ff9100, #ffa52f);
    /* Gradiente de 3 colores */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    /* El gradiente aparecerá en lugar del color sólido */
    animation: gradient-animation 3s infinite;
    /* Añade la animación */
}

@keyframes gradient-animation {

    0%,
    100% {
        background-position: 0% 50%;
        transform: scale(0.8);
    }

    50% {
        background-position: 100% 50%;
        transform: scale(0.9);
    }
}
</style>